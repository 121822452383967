<mat-selection-list
  class="abonents-list bordered-list"
  (selectionChange)="onSelectConnection($event.option.value)"
>
  <div mat-subheader>
    {{ 'shared.connections.abonents.list.selected_abonents' | translate }}: {{ connections?.length }}
  </div>

  <ng-container>
    <mat-list-option
      [disabled]="loading"
      [value]="selectedAll"
      [selected]="selectedAll"
    >
      {{
        selectedAll
          ? ('shared.connections.abonents.list.hide_all' | translate)
          : ('shared.connections.abonents.list.select_all' | translate)
      }}
    </mat-list-option>
  </ng-container>
  <mat-list-option
    [disabled]="loading"
    [value]="connection"
    [selected]="!!connection.selected"
    *ngFor="let connection of connections"
  >
    <div class="abonents-list-option">
      <p class="abonents-list-option__phone">
        {{ connection.abonent.phone.toString() | phone }}
      </p>
      <p class="abonents-list-option__address">
        {{ connection.flat.address | address }}; {{ 'shared.connections.abonents.list.flat' | translate }} {{ connection.flat.address.flat }}
      </p>
    </div>
  </mat-list-option>
</mat-selection-list>
