import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable } from 'rxjs';

import { Address, ServicesTypes } from '@app/shared/models';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import {
  ServiceEntranceFlats,
  ServiceFlat
} from '@app/views/services/components';
import { ServiceApiService } from '@app/shared/entities/rd';
import { Dictionary } from '@app/shared/helpers';
import { FlatPhoneComponent } from '../flat-phone';

@Component({
  selector: 'app-flat-cards-table',
  templateUrl: './flat-cards-table.component.html',
  styleUrls: ['./flat-cards-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlatCardsTableComponent implements OnChanges {
  readonly numberDefaultSize: number = 13.91;

  public readonly ServicesTypes = ServicesTypes;
  public hardwareTariff: number;
  public selectedFlats: Dictionary<ServiceFlat | boolean> = {};
  public hiddenFlatsTables: number[] = [];
  public hideOutOfRangeFlats: boolean = false;
  public isSelected: boolean = false;

  @Input() services: ServicesTypes[];
  @Input() address: Address;
  @Input() set entranceFlats(entranceFlats: ServiceEntranceFlats) {
    this._entranceFlats = entranceFlats;

    if (entranceFlats?.flatRangesInfo) {
      this.numberFieldWidth = this.calcNumberWidth(
        entranceFlats.flatRangesInfo.map((rangeInfo) => rangeInfo.range)
      );
      return;
    }

    this.numberFieldWidth = this.numberDefaultSize * 3;
  }
  @Input() phonesTemplate: TemplateRef<any>;
  @Input() servicesTemplate: TemplateRef<any>;
  @Input() menuTemplate: TemplateRef<any>;
  @Input() statusTemplate: TemplateRef<any>;
  @Input() numberFieldWidth: number;
  @Input() serviceType: ServicesTypes;
  @Input() hardwareId: number;
  @Input() abonentsBillingEnabled: boolean;
  @Output() onHardwareTariffGet: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() flatChecked: EventEmitter<ServiceFlat[]> = new EventEmitter<
    ServiceFlat[]
  >();
  @Output() flatUnchecked: EventEmitter<ServiceFlat[]> = new EventEmitter<
    ServiceFlat[]
  >();
  @Output() handleFlatsToManage: EventEmitter<Dictionary<ServiceFlat[]>> =
    new EventEmitter<Dictionary<ServiceFlat[]>>();

  @Output() addPhone: EventEmitter<{
    address: Address;
    flat: Partial<ServiceFlat>;
  }> = new EventEmitter();

  @ViewChild(FlatPhoneComponent) flatPhone: FlatPhoneComponent;
  private _entranceFlats: ServiceEntranceFlats;

  public readonly mdWUpBreakpoint$: Observable<boolean>;

  constructor(
    private resolution: ResolutionService,
    private serviceApi: ServiceApiService
  ) {
    this.mdWUpBreakpoint$ = this.resolution.getBreakpoint(
      ResolutionBreakpoint.MD_W_UP
    );
  }

  async ngOnChanges() {
    if (this.hardwareId) {
      const hardwareInfo = await this.serviceApi
        .getInfo(this.hardwareId)
        .toPromise();
      this.hardwareTariff = hardwareInfo?.tariff;
      this.onHardwareTariffGet.emit(hardwareInfo?.tariff);
    }
  }

  get entranceFlats(): ServiceEntranceFlats {
    return this._entranceFlats;
  }

  public onAddPhone(address: Address, flat: Partial<ServiceFlat>) {
    this.addPhone.emit({ address, flat });
  }

  getFlatRangeArray(flatStart: number, flatEnd: number) {
    return Array.from(
      { length: flatEnd - flatStart + 1 },
      (v, k) => k + flatStart
    );
  }

  hitInFlatRanges(flatNumber: number, flatRanges: [number, number][]): boolean {
    return !flatRanges.reduce(
      (prev, cur) => prev && (flatNumber < cur[0] || flatNumber > cur[1]),
      true
    );
  }

  checkIfHasHardwareIntercom(flatData) {
    let flatServiceList = [];
    if (flatData && flatData.services) {
      flatServiceList = Object.keys(flatData.services);
    }
    return flatServiceList.includes(ServicesTypes.HARDWARE_INTERCOM);
  }

  private calcNumberWidth(flatRanges: [number, number][]): number {
    if (
      flatRanges[flatRanges.length - 1][1] === null ||
      flatRanges[flatRanges.length - 1][1] === undefined
    ) {
      return this.numberDefaultSize;
    }

    return (
      flatRanges[flatRanges.length - 1][1].toString().length *
      this.numberDefaultSize
    );
  }

  public onSelectFlat(flat: ServiceFlat) {
    const entranceId = this.address.entrance.id;
    const selectedFlatsWithEtranceId: Dictionary<ServiceFlat[]> = {};
    if (!!this.selectedFlats[flat.flatNumber]) {
      this.selectedFlats[flat.flatNumber] = false;
      this.flatUnchecked.emit([flat]);
    } else {
      this.selectedFlats[flat.flatNumber] = flat;
      this.flatChecked.emit([flat]);
    }
    selectedFlatsWithEtranceId[entranceId] = Object.values(
      this.selectedFlats
    ) as ServiceFlat[];
    this.handleFlatsToManage.emit(selectedFlatsWithEtranceId);
  }

  public onRangeSelect(event: MatCheckboxChange, flatNumberList: number[]) {
    let selectedFlats = [];
    const entranceId = this.address.entrance.id;
    const selectedFlatsWithEtranceId: Dictionary<ServiceFlat[]> = {};
    if (event.checked) {
      for (const flatNumber of flatNumberList) {
        this.selectedFlats[flatNumber] = (
          this.entranceFlats.flats[flatNumber]
            ? this.entranceFlats.flats[flatNumber]
            : this.entranceFlats.virtualFlats[flatNumber]
        ) as ServiceFlat;

        selectedFlats = [...selectedFlats, this.selectedFlats[flatNumber]];
      }
      this.flatChecked.emit(selectedFlats);
    } else {
      for (const flatNumber of flatNumberList) {
        selectedFlats = [...selectedFlats, this.selectedFlats[flatNumber]];
        this.selectedFlats[flatNumber] = false;
      }
      this.flatUnchecked.emit(selectedFlats);
    }
    selectedFlatsWithEtranceId[entranceId] = Object.values(
      this.selectedFlats
    ) as ServiceFlat[];
    this.handleFlatsToManage.emit(selectedFlatsWithEtranceId);
  }

  public isRangeSelected(flatNumberList: number[]): boolean {
    this.isSelected = false;
    for (const flatNumber of flatNumberList) {
      if (this.selectedFlats[flatNumber]) {
        this.isSelected = true;
        break;
      }
    }

    return this.isSelected;
  }

  public get isRangeOutFlatsSelected(): boolean {
    return this.isRangeSelected(
      Object.values(this.entranceFlats.virtualFlats).map(
        (flat) => flat.flatNumber
      )
    );
  }

  public onRangeOutFlatsSelect(event: MatCheckboxChange) {
    this.onRangeSelect(
      event,
      Object.values(this.entranceFlats.virtualFlats).map(
        (flat) => flat.flatNumber
      )
    );
  }

  public onToggleHideContainer(index: number): void {
    this.hiddenFlatsTables = this.hiddenFlatsTables.includes(index)
      ? this.hiddenFlatsTables.filter((i) => i !== index)
      : [...this.hiddenFlatsTables, index];
  }

  public onHideOutOfRangeFlats(): void {
    this.hideOutOfRangeFlats = !this.hideOutOfRangeFlats;
  }

  public canExpandAdditionalPhone(flat: ServiceFlat): boolean {
      return (this._entranceFlats.filters?.phone?.length)
        ? this.existsPhone(this._entranceFlats.filters?.phone, flat)
        : false;
  }

  private existsPhone(
    search: string,
    flat: Partial<ServiceFlat>
  ): boolean {
    const phone = search.replace('+', '');
    const isPhoneSimilar = flat.account?.owner?.phone
      .toString()
      .includes(phone);

    const isPhoneAdditional = flat
      .sharedAbonents?.filter(sa => sa.number.toString().includes(phone)).length > 0;

    if (!isPhoneSimilar && !isPhoneAdditional) {
      return false;
    }
  }
}
