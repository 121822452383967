<div class="request">
  <div class="request-header">
    <h2>
      <span class="request-header__pk">Заявки в поддержку</span>
      <span class="request-header__mobile">Поддержка</span>
    </h2>
    <app-button-link link="https://wiki.rosdomofon.com/ru/Поддержка/Возможности_Системы_РосДомофон/Как_создать_заявку_в_техподдержку_Росдомофон">Как это работает ?</app-button-link>
  </div>
  <div class="request-banner">
    <app-request-banner></app-request-banner>
  </div>

  <p-card *ngIf="loading" class="request-data__loader__card">
    <div class="request-data__loader__wrapper">
      <p-skeleton width="100%" height="4rem"></p-skeleton>
      <hr class="request-data__loader--devider">
      <p-skeleton width="100%" height="15rem"></p-skeleton>
      <div class="request-data__loader__pagination__wrapper">
        <p-skeleton width="12rem" height="4rem"></p-skeleton>
      </div>
    </div>
  </p-card>

  <div [hidden]="loading" class="request-data">
<!--    <app-component-loader *ngIf="loading" [background]="true" [sizePx]="50"></app-component-loader>-->
    <ng-container *ngIf="deviceEventService.appSizeState === AppSizeStates.PHONE">
      <div class="request-data-mobile">
        <div class="request-data-mobile-type">
          <p-dropdown
            [options]="options"
            [optionValue]="'value'"
            [optionLabel]="'name'"
            (onChange)="onOptionsChange($event, search)"
          ></p-dropdown>
        </div>
        <div class="request-data-mobile-filters">
          <div class="request-data-mobile-filters-search">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input
                #search
                type="text"
                [placeholder]="'Поиск'"
                (input)="search$.next(search.value)"
                pInputText
              />
            </span>
          </div>
          <div class="request-data-mobile-filters-action">
            <app-filter-button
              (action)="onAction()"
              [text]="''"
              [style]="'background-color: #0DA7E2;color: #FFFFFF;'"
              [badgeContent]="getBadgeValue(supportFormValue)"
            ></app-filter-button>
          </div>
        </div>
        <div class="request-data-mobile-cards" appInfiniteScroll (scrollPosition)="onScrollPosition($event)">
          <app-mobile-card
            *ngIf="!mobileLoading"
            [tickets]="tickets | ticketsFilter: { filters: supportFormValue, user: user, loader: showSkeleton$ }"
            [chunkSize]="chunkSize"
          ></app-mobile-card>
          <app-skeleton-card-view *ngIf="mobileLoading"></app-skeleton-card-view>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="deviceEventService.appSizeState !== AppSizeStates.PHONE">
      <app-prime-base-table
        *ngIf="!loading"
        class="request-data__table"
        [primeTableConfig]="tableConfig"
        [showSkeleton]="showSkeleton$ | async"
        [tableData]="tickets | ticketsFilter: { filters: supportFormValue, user: user, loader: showSkeleton$ }"
        (navigate)="onNavigate($event)">
        <ng-container addition-actions>
          <div class="request-data__table-options">
            <div class="request-data__table-options-filters">
              <p-selectButton
                [options]="options"
                [(ngModel)]="optionValue"
                [disabled]="showSkeleton$ | async"
                (ngModelChange)="onOptionsChange($event, search)"
                optionLabel="name"
              ></p-selectButton>
            </div>
            <div class="request-data__table-options-search">
              <div>
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input
                  #search
                  type="text"
                  [placeholder]="'Поиск'"
                  (input)="search$.next(search.value)"
                  pInputText />
              </span>
              </div>
              <div class="request-data__table-options-search-action">
                <app-filter-button
                  [text]="appSizeState === AppSizeStates.PHONE ? '' : 'Фильтры'"
                  (action)="onAction()"
                ></app-filter-button>
                <i *ngIf="appSizeState === AppSizeStates.PHONE && isFormEmpty === false" class="pi pi-refresh" (click)="onFiltersReset()"></i>
              </div>
            </div>
          </div>
          <div class='legal-block' *ngIf='isFiltersOpen' @openCloseH>
            <app-filters-block
              [filtersBlockType]='FiltersBlockType.SUPPORT'
              [disable]="showSkeleton$ | async"
              [employees]="usersFromCurrentCompany"
              (changeForm)="onChangeForm($event)"
            ></app-filters-block>
          </div>
        </ng-container>
      </app-prime-base-table>
    </ng-container>
  </div>
</div>

<p-sidebar
  [(visible)]="showSidebar"
  [appendTo]="'body'"
  [position]="'bottom'"
  [style]="{height:'500px'}"
  [transitionOptions]="'250ms'">
  <ng-template pTemplate="header"></ng-template>
  <ng-template pTemplate="content">
    <app-filters-block
      [filtersBlockType]='FiltersBlockType.SUPPORT'
      [disable]="showSkeleton$ | async"
      [employees]="usersFromCurrentCompany"
      (changeForm)="onChangeForm($event)"
    ></app-filters-block>
  </ng-template>
  <ng-template pTemplate="footer"></ng-template>
</p-sidebar>
