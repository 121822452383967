import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/shared/components/snackbar';
import { RdvaPlaylistItem } from '@app/shared/entities/integrations';
import { Constants } from '@app/shared/helpers';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData } from '@app/shared/ui/dialog-wrapper';
import { AuthFacade } from '@app/views/auth/store';
import Hls, { ErrorTypes, Fragment } from 'hls.js';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HlsFragParseHelper, HlsHelper, PlyrTimeHelper } from './helpers';
import { RdeaHls } from './helpers/rdea-hls';
import { VideoPlayerCustomClickEventType, VideoPlayerErrorTypes, VideoPlayerFragmentShortDate, VideoPlayerHlsError, VideoPlayerMode, VideoPlayerVideoshotRequest, VideoshotPopupState } from './models';
import { VideoPlayerHelperService } from './services';
import { GetVideoshotPopupPlaylists, VideoPlayerChangeVideoshotState, VideoPlayerFacade, VideoPlayerState } from './store';
import { VideoPlayerVideoshotPopupComponent } from './video-player-videoshot-popup';
import { MAX_FATALS_COUNT, TZ_OFFSET_IN_MILLISECONDS, TZ_OFFSET_IN_MINUTES } from './video-player.constants';
import { CameraApiService, RdaApiService } from '@app/shared/entities/rd';
import { catchError, delay, map, take, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { DecorateUntilDestroy, takeUntilDestroyed } from '@app/shared/rxjs/operator/take-until-destroyed';
import { RdVideoService } from '@app/shared/video';
import { WebRTCExperimentHelpers } from './helpers/webrtc-expriment-helpers';
import { Option } from '@app/shared/video/components/select-control/select-control';
import { DatePipe } from '@angular/common';

@DecorateUntilDestroy()
@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VideoPlayerHelperService, VideoPlayerFacade]
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('video') public videoElement: ElementRef<HTMLVideoElement>;
  private loadingSubject = new BehaviorSubject(false);
  loading$ = this.loadingSubject.asObservable();

  mdWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.MD_W_DOWN);

  public releId: number = null;
  public rdaUid: string = null;

  @Input() token: string;
  @Input() rdvaUri: string;
  @Input() set active(active: boolean) {
    this.mode.next(active ?
      WebRTCExperimentHelpers.isCompanyInExperiment() ? VideoPlayerMode.LIVE_WEBRTC : VideoPlayerMode.LIVE_HLS
      : VideoPlayerMode.ARCHIVE)
  }
  @Input() cameraId: number;
  @Input() depthInHours: number;

  private hls: RdeaHls;
  private archivePlaylist: string;
  private minDate: Date;
  private maxDate: Date;
  private recoverErrorTries = 0;
  private hlsHelper: HlsHelper;
  private unSubscribe$: Subject<boolean> = new Subject<boolean>();
  private delayCameraInter$: Subject<void> = new Subject<void>();

  private isPopupReadyToShow$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  private videoshotPopupData$: Observable<string[]> = this.videoPlayerFacade.videoshotPlaylistData$;
  private isErrorOccured$: Observable<boolean> = this.videoPlayerFacade.isErrorOccured$;

  public readonly VideoPlayerMode = VideoPlayerMode;
  private isDatesReceived$: Subject<boolean> = new Subject<boolean>();

  private mode = new BehaviorSubject<VideoPlayerMode>(WebRTCExperimentHelpers.isCompanyInExperiment() ? VideoPlayerMode.LIVE_WEBRTC : VideoPlayerMode.LIVE_HLS)
  mode$ = this.mode.asObservable();

  private playListItemsSubject = new BehaviorSubject<RdvaPlaylistItem[]>([])
  playListItems$ = this.playListItemsSubject.asObservable();

  private currentSelectPlaylist: Option<RdvaPlaylistItem>;
  private currentSelectEvent: Option<VideoPlayerFragmentShortDate>;

  private eventsOptions: Option<VideoPlayerFragmentShortDate>[];
  private dateArchiveOptions: Option[];

  public get isCanOpenDoor(): boolean {
    return this.releId !== null;
  }

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private authFacade: AuthFacade,
    private snackbar: SnackbarService,
    private resolution: ResolutionService,
    private videoPlayerFacade: VideoPlayerFacade,
    private videoPlayerHelper: VideoPlayerHelperService,
    private rdaApiService: RdaApiService,
    private translate: TranslateService,
    private cameraApiService: CameraApiService,
    private store: Store<VideoPlayerState>,
    private rdVideoService: RdVideoService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.cameraApiService.getCameraRDAConfigById(this.cameraId)
      .pipe(
        catchError((error: HttpErrorResponse) => of(error)),
        takeUntil(this.unSubscribe$),
      ).subscribe((res) => {
        if (res instanceof HttpErrorResponse) {
          console.warn('The door does not have a config');
        } else {
          if (res.releId !== null && res.uid !== null) {
            this.releId = res.releId;
            this.rdaUid = res.uid;
          }
        }
        this.initStoreListeners();
        this.initOnChangeModePlayer();
        this.delayCameraInter$.next();
      });

    this.addPlaylistLoadListeners();
  }

  ngOnDestroy() {
    if (this.hls) {
      this.hls.destroy();
      this.hlsHelper.destroy();
    }

    this.rdVideoService.destroy();

    this.videoPlayerFacade.clearState();

    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  getModeButtonName(): string {
    switch (this.mode.value) {
      case VideoPlayerMode.LIVE_WEBRTC:
        return 'LIVE old';
      case VideoPlayerMode.LIVE_HLS:
        return this.translate.instant('shared.video_player.plyr.template.button.archive');
      case VideoPlayerMode.ARCHIVE:
        return 'LIVE new';
    }
  }

  private initOnChangeModePlayer(): void {
    this.mode$
      .pipe(
        // delay so that the video element has time to render
        delay(1),
        takeUntilDestroyed(this)
      )
      .subscribe((mode) => {
        switch (mode) {
          case VideoPlayerMode.LIVE_WEBRTC:
            this.initLiveWebRTC();
            break;
          case VideoPlayerMode.LIVE_HLS:
            this.initLiveHls();
            break;
          case VideoPlayerMode.ARCHIVE:
            this.initArchive();
            break;
          default:
            break;
        }
      })
  }

  private initArchive(): void {
    this.initHls();

    this.videoPlayerFacade.getPlaylists(this.rdvaUri, this.cameraId, -TZ_OFFSET_IN_MINUTES);
  }

  private initLiveHls(): void {
    this.initHls();

    this.loadPlaylists();
  }

  private initLiveWebRTC(): void {
    this.rdVideoService.addReceiverWebRTC({
      id: this.cameraId
    });
  }

  /**
   * Init video player store listeners (playlists loaded, current source selected)
   */
  private initStoreListeners() {
    this.videoPlayerFacade.addPlaylistsLoadedListener((playlists: RdvaPlaylistItem[]) =>
      this.handlePlaylistsSuccessLoaded(playlists)
    );

    this.videoPlayerFacade.addSelectCurrentSourceListener((source: string) =>
      this.handleSourceSelection(source)
    );
  }

  /**
   * Load playlists for current mode
   */
  public loadPlaylists() {
    PlyrTimeHelper.clear();

    this.videoPlayerFacade.getPlaylistsSuccess([{
      date: new Date().getTime(),
      playlist: this.videoPlayerHelper.getLiveSource(this.rdvaUri, this.cameraId)
    }]);
  }

  /**
   * Create and configurate Hls instance
   */
  private initHls() {
    this.hls = this.rdVideoService.addReceiverHls({
      id: this.cameraId
    });

    this.hlsHelper = this.videoPlayerHelper.initHlsHelper(this.hls);
    this.addHlsHelperListeners();
  }

  /**
   * Add Hls helper event listeners for errors and manifest loaded
   */
  private addHlsHelperListeners() {
    this.hlsHelper.addErrorListeners((error: VideoPlayerHlsError) =>
      this.handleHlsError(error)
    );

    this.hlsHelper.addManifestLoadedListener((fragments: Fragment[]) =>
      this.handleManifestLoaded(fragments)
    );
  }

  /**
   * Change video player mode
   */
  private changeMode() {
    switch (this.mode.value) {
      case VideoPlayerMode.LIVE_WEBRTC:
        this.rdVideoService.destroyWebRTCStream(this.cameraId);
        this.mode.next(VideoPlayerMode.LIVE_HLS)
        break;
      case VideoPlayerMode.LIVE_HLS:
        this.rdVideoService.destroyHlsStream(this.cameraId);
        this.mode.next(VideoPlayerMode.ARCHIVE)
        break;
      case VideoPlayerMode.ARCHIVE:
        this.rdVideoService.destroyHlsStream(this.cameraId);
        if (WebRTCExperimentHelpers.isCompanyInExperiment()) {
          this.mode.next(VideoPlayerMode.LIVE_WEBRTC)
          break;
        }
        this.mode.next(VideoPlayerMode.LIVE_HLS)
        break;
    }
  }

  private onPrevEvent(): void {
    if (!this.eventsOptions || !this.eventsOptions.length) {
      return;
    }

    if (!this.currentSelectEvent) {
      this.currentSelectEvent = this.eventsOptions[0]
      this.rdVideoService.updateSelectedPlayerTime(this.currentSelectEvent.value.startPlayerTime);
      return;
    }

    const currentSelectIndex = this.eventsOptions
      .findIndex(event => event.value.startPlayerTime === this.currentSelectEvent.value.startPlayerTime);

    const prevEvent = this.eventsOptions[currentSelectIndex === 0 ? 0 : currentSelectIndex - 1]
    this.currentSelectEvent = prevEvent;

    this.rdVideoService.updateSelectedPlayerTime(prevEvent.value.startPlayerTime);
  }

  private onNextEvent(): void {
    if (!this.eventsOptions || !this.eventsOptions.length) {
      return;
    }

    if (!this.currentSelectEvent) {
      this.currentSelectEvent = this.eventsOptions[0]
      this.rdVideoService.updateSelectedPlayerTime(this.currentSelectEvent.value.startPlayerTime);
      return;
    }

    const currentSelectIndex = this.eventsOptions
      .findIndex(event => event.value.startPlayerTime === this.currentSelectEvent.value.startPlayerTime);

    const lastIndex = this.eventsOptions.length - 1;
    const nextEvent = this.eventsOptions[currentSelectIndex === lastIndex
      ? lastIndex
      : currentSelectIndex + 1]
    this.currentSelectEvent = nextEvent;

    this.rdVideoService.updateSelectedPlayerTime(nextEvent.value.startPlayerTime);
  }

  /**
   * Open popup with videoshot download form
   */
  openVideoShotPopup() {
    const data: DialogWrapperData<VideoPlayerVideoshotRequest, null> = {
      title: this.translate.instant('shared.video_player.video_shot.title'),
      componentName: 'GetVideoshot',
      body: {
        minDate: this.minDate,
        maxDate: this.maxDate,
        depthInHours: this.depthInHours,
        rdvaUri: this.rdvaUri,
        cameraId: this.cameraId,
        token: this.token,
        isMobile: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
      }
    };

    this.dialog.open(VideoPlayerVideoshotPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      autoFocus: false,
      data
    });

    if (!this.minDate || !this.maxDate) {
      this.isPopupReadyToShow$.pipe(takeUntil(this.unSubscribe$)).subscribe(isDataReceived => {
        if (isDataReceived) {
          data.body.minDate = this.minDate;
          data.body.maxDate = this.maxDate;
        }
      });
    }
  }

  /**
   * Handle playlists loaded event
   * @param playlists rdva playlist items
   */
  private handlePlaylistsLoaded(playlists: RdvaPlaylistItem[]) {
    if (!playlists?.length) {
      return;
    }

    if (this.mode.value === VideoPlayerMode.ARCHIVE) {
      const [firstPlaylist, lastPlaylist] = [playlists[0].playlist, playlists[playlists.length - 1].playlist];
      this.store.dispatch(new VideoPlayerChangeVideoshotState(VideoshotPopupState.LOADING));
      this.store.dispatch(new GetVideoshotPopupPlaylists([firstPlaylist, lastPlaylist]));
    }

    this.playListItemsSubject.next(playlists);

    this.currentSelectPlaylist = {
      label: this.formatDate(playlists[playlists.length - 1].date, 'dd MMMM'),
      value: playlists[playlists.length - 1]
    }

    this.dateArchiveOptions = playlists
      .map(playlist => {
        return {
          label: this.formatDate(playlist.date, 'dd MMMM'),
          value: playlist
        }
      })

    PlyrTimeHelper.sourceDate = playlists[playlists.length - 1].date;
    this.videoPlayerFacade.selectCurrentSource(playlists[playlists.length - 1].playlist);
  }

  /**
   * Handle hls error
   * @param param0 video player hls error params
   */
  private handleHlsError({ fatal, type, details }: VideoPlayerHlsError) {
    if (type === VideoPlayerErrorTypes.AUTH_ERROR) {
      this.authFacade.logout(this.router.url);
      return;
    }

    if (!fatal) {
      return;
    }

    if (this.recoverErrorTries === MAX_FATALS_COUNT) {
      this.showFatalErrorMessage(type, details);

      if (this.active) {
        this.changeMode();
      }

      return;
    }

    switch (type) {
      case ErrorTypes.MEDIA_ERROR:
        this.hls.recoverMediaError();
        break;
      case ErrorTypes.NETWORK_ERROR:
        this.hls.startLoad();
        break;
    }

    this.recoverErrorTries++;
  }

  /**
   * Prepare fatal error message
   * @param errorType Hls error type
   * @param details Hls eror details
   */
  private showFatalErrorMessage(errorType: string, details: string) {
    let reason: string;

    switch (errorType) {
      case Hls.ErrorTypes.NETWORK_ERROR:
        reason = details === 'levelEmptyError'
          ? this.translate.instant('shared.video_player.message.error.reason.empty')
          : this.translate.instant('shared.video_player.message.error.reason.error')
          ;
        break;
      case Hls.ErrorTypes.MEDIA_ERROR:
        reason = this.translate.instant('shared.video_player.message.error.reason.media');
        break;
      default:
        reason = this.translate.instant('shared.video_player.message.error.reason.unknown');
    }

    this.snackbar.showMessage(
      this.translate.instant('shared.video_player.message.error.reason.unknown', {
        text: reason
      })
    );
  }

  /**
   * Handle manifest loaded (prepare duration and fragments)
   * @param fragments Hls fragments
   */
  private handleManifestLoaded(fragments: Fragment[]) {
    if (this.mode.value !== VideoPlayerMode.ARCHIVE) {
      return;
    }
    const { rangeTimestamps, eventTimestamps } = HlsFragParseHelper.extractDateRangesFromFragments(fragments);

    this.eventsOptions = eventTimestamps
      .map(event => {
        return {
          label: this.formatDate(event.startTimestamp, 'HH:mm:ss'),
          value: event
        }
      })

    this.rdVideoService.updatePlayerTimelineData({
      date: this.currentSelectPlaylist.value.date,
      mediaPeriodItems: rangeTimestamps,
      eventTimestamps
    })
  }

  private handleSelectionEvents(option: Option<VideoPlayerFragmentShortDate>) {
    this.currentSelectEvent = option;
    this.rdVideoService.updateSelectedPlayerTime(option.value.startPlayerTime);
  }

  /**
   * Handle source selection event
   * @param source video Uri
   */
  private handleSelectionPlaylist(option: Option<RdvaPlaylistItem>) {
    if (!option) {
      return;
    }

    this.rdVideoService.destroyHlsStream(this.cameraId);
    this.initHls();

    this.rdVideoService.updateSelectPlaylistPlayer(option);
    this.currentSelectPlaylist = option;

    if (this.mode.value === VideoPlayerMode.ARCHIVE || this.mode.value === VideoPlayerMode.LIVE_HLS) {
      this.rdVideoService.loadSourceHls(this.cameraId, option.value.playlist)
    }
  }

  /**
   * Handle source selection event
   * @param source video Uri
   */
  private handleSourceSelection(source: string) {
    if (!source) {
      return;
    }

    if (this.mode.value === VideoPlayerMode.ARCHIVE || this.mode.value === VideoPlayerMode.LIVE_HLS) {
      this.rdVideoService.loadSourceHls(this.cameraId, source)
    }
  }

  /**
   * Handle successfully loaded playlists
   * @param {RdvaPlaylistItem[]} playlists rdva playlists items
   */
  private handlePlaylistsSuccessLoaded(playlists: RdvaPlaylistItem[]) {
    if (playlists && playlists?.length !== 0) {
      this.handlePlaylistsLoaded(playlists);
      return;
    }
  }

  private openDoor(): void {
    console.log(this.releId)

    this.rdaApiService.openDoor(this.rdaUid, this.releId).pipe(
      catchError((error: HttpErrorResponse) => of(error)),
      take(1)
    ).subscribe((response) => {
      if (response instanceof HttpErrorResponse) {
        this.snackbar.showMessage(
          this.translate.instant('shared.video_player.message.open.failed.text', {
            text: response.message
          }),
          'error'
        );
        return;
      }
      this.snackbar.showMessage(
        this.translate.instant('shared.video_player.message.open.success'),
        'success'
      );
    });
  }

  private addPlaylistLoadListeners(): void {
    this.videoshotPopupData$.pipe(takeUntil(this.unSubscribe$))
      .subscribe(videoshotPlaylistData => {
        if (videoshotPlaylistData) {
          const firstPlaylistUrl = this.videoPlayerHelper.getPlaylistRelUrl(true, videoshotPlaylistData[0]);
          const lastPlaylistUrl = this.videoPlayerHelper.getPlaylistRelUrl(false, videoshotPlaylistData[1]);

          this.minDate = this.videoPlayerHelper.getDateFromFragmentRelUrl(firstPlaylistUrl, this.cameraId);
          this.maxDate = this.videoPlayerHelper.getDateFromFragmentRelUrl(lastPlaylistUrl, this.cameraId);
          this.minDate = this.videoPlayerHelper.shiftMinDateForward(this.minDate, this.maxDate);

          this.isPopupReadyToShow$.next(true);
          this.store.dispatch(new VideoPlayerChangeVideoshotState(VideoshotPopupState.CREATE));
        }
      })

    this.isErrorOccured$.subscribe(errorOccured => {
      if (errorOccured) {
        this.store.dispatch(new VideoPlayerChangeVideoshotState(VideoshotPopupState.CREATE));
      }
    })
  }

  private formatDate(timestamp: number, format: string = 'dd MMMM'): string {
    return this.datePipe.transform(timestamp - TZ_OFFSET_IN_MILLISECONDS, format);
  }
}
