import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { CommonModule } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { IModuleTranslationOptions, ModuleTranslateLoader } from '@larscom/ngx-translate-module-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
  LangChangeEvent, TranslateCompiler
} from '@ngx-translate/core';
import { MatPaginatorI18nService } from './services/mat-paginator-i18n.service';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { environment } from 'environments/environment';
import { LocaleService } from './services/locale.service';
import { EnvironmentLocale } from './models/translate.models';

export function HttpLoaderFactory(http: HttpClient) {
  const baseTranslateUrl = './assets/i18n';
  const options: IModuleTranslationOptions = {
    modules: [
      { baseTranslateUrl },
      { baseTranslateUrl, moduleName: 'core', namespace: 'core' },
      { baseTranslateUrl, moduleName: 'shared', namespace: 'shared' },
      { baseTranslateUrl, moduleName: 'service-navbar', namespace: 'service-navbar' }
    ]
  };
  return new ModuleTranslateLoader(http, options);
}

const localeKey = environment.vivotec ? 'locale_vivotec' : 'locale';

export function TranslateFactory(translate: TranslateService) {
  return async () => {
    translate.use(localStorage.getItem(localeKey) || translate.defaultLang);
    return new Promise<void>(resolve => {
      translate.onLangChange.subscribe(() => {
        resolve();
      });
    });
  };
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.lang,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      },
      useDefaultLang: false,
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: TranslateFactory,
      deps: [TranslateService],
      multi: true
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService,
    },
    {provide: LOCALE_ID, useValue: environment.locale}
  ],
  exports: [TranslateModule],
})

export class NgxTranslateModule {
  public readonly environment = environment;

  constructor(
    public translate: TranslateService,
    private adapter: DateAdapter<Date>, 
    private localeService: LocaleService
  ) {
    translate.setDefaultLang(environment.lang);
    translate.addLangs(environment.languages);

    const currentLang = localStorage.getItem(localeKey) || translate.defaultLang;

    translate.use(currentLang);
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem(localeKey, event.lang);
      this.adapter.setLocale(event.lang);

      this.localeService.setLocale(environment.locale as EnvironmentLocale);
      if (currentLang !== event.lang) {
        document.location.reload();
      }
    });

    this.localeService.setLocale(environment.locale as EnvironmentLocale);
  }
}
