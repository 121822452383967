import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RemovePopupComponent } from '@app/shared/components';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { Account } from '@app/shared/entities/rd/account/models';
import { Constants } from '@app/shared/helpers/constants';
import { ServicesTypes } from '@app/shared/models';
import { EditPopupComponent } from '@app/shared/templates';
import { State } from '@app/store/reducers';
import { BlockAccountServiceRequest, DisconnectAccountServiceRequest } from '@app/views/abonents/models';
import { AccountServiceData } from '@app/views/abonents/models/abonent-page/account-services-list.model';
import { BlockAbonentPageService, DisconnectAbonentPageService, UnblockAbonentPageService } from '@app/views/abonents/store/actions';
import { Store } from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-abonent-services',
  templateUrl: './abonent-services.component.html',
  styleUrls: ['./abonent-services.component.scss']
})
export class AbonentServicesComponent {
  readonly HOUSE_CHAT_SERVICE = ServicesTypes.HOUSE_CHAT;

  @Input() account: Account;
  @Input() services: AccountServiceData[];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private store: Store<State>,
    private resolution: ResolutionService,
    private translate: TranslateService
  ) {}

  onDisconnect(service: AccountServiceData) {
    this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '500px',
      data: {
        title: this.translate.instant('abonent.accounts.services.disconnect.title'),
        providedData: {
          text: this.translate.instant('abonent.accounts.services.disconnect.text'),
          id: service.id,
          btnText: this.translate.instant('abonent.accounts.services.disconnect.button.submit'),
        },
        component: RemovePopupComponent,
        componentName: 'DisconnectAbonentService',
        submit: () => {
          const request: DisconnectAccountServiceRequest = { flatId: service.flat?.id };
          this.store.dispatch(new DisconnectAbonentPageService(this.account.id, service.id, request));
        }
      }
    });
  }

  onBlock(service: AccountServiceData) {
    const request: BlockAccountServiceRequest = { flatId: service.flat?.id };

    if (service.isBlocked) {
      this.store.dispatch(new UnblockAbonentPageService(this.account.id, service.id, request));
    } else {
      this.store.dispatch(new BlockAbonentPageService(this.account.id, service.id, request));
    }
  }

  onRoute(service: AccountServiceData, event: MouseEvent) {
    switch (service.type) {
      case ServicesTypes.SOFTWARE_INTERCOM:
        this.openLink(event, `services/software-intercoms/${service.id}`);
        break;
      case ServicesTypes.HARDWARE_INTERCOM:
        if (service.bindedSoftwareIntercomeId) {
          this.openLink(event, `services/software-intercoms/${service.bindedSoftwareIntercomeId}`);
        }
        
        break;
      case ServicesTypes.VIDEO_SURVEILLANCE:
        this.openLink(event, `services/video-surveillance/${service.id}`);
        break;
      case ServicesTypes.GATE:
        this.openLink(event, `services/gates/${service.id}`);
        break;
      default:
        break;
    }
  }

  shouldBeLink(serviceType: ServicesTypes): boolean {
    switch (serviceType) {
      case ServicesTypes.SOFTWARE_INTERCOM:
      case ServicesTypes.HARDWARE_INTERCOM:
      case ServicesTypes.VIDEO_SURVEILLANCE:
      case ServicesTypes.GATE:
        return true;
      default:
        return false;
    }
  }

  private openLink(event: MouseEvent, link): void {
    // eslint-disable-next-line import/no-deprecated
    if (event.metaKey || event.ctrlKey || event.which === 2) {
      window.open(link, '_blank');
    } else {
      this.router.navigate([link]);
    }
  }
}
