<mat-table
  [dataSource]="signUps"
  *ngIf="signUps?.length > 0; else emptyRequests"
>
  <ng-container matColumnDef="address">
    <mat-header-cell data-label="address" *matHeaderCellDef>
      {{ 'abonent.sign_ups.field.address' | translate }}
    </mat-header-cell>
    <mat-cell
      data-label="address"
      *matCellDef="let element"
    >
      {{ element.address | address }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="flatNumber">
    <mat-header-cell data-label="flatNumber" *matHeaderCellDef>
      {{ 'abonent.sign_ups.field.flat_number' | translate }}
    </mat-header-cell>
    <mat-cell
      data-label="flatNumber"
      *matCellDef="let element"
    >
      {{ element.address.flat }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell data-label="status" *matHeaderCellDef>
      {{ 'abonent.sign_ups.field.status' | translate }}
    </mat-header-cell>
    <mat-cell
      data-label="status"
      *matCellDef="let element"
    >
      <mat-chip
        [disabled]="true"
        [selectable]="false"
        *ngIf="signUpsService.notFinished(element)"
        color="{{ element.isFreeAddress ? 'success' : 'warn' }}"
      >
        {{
          element.isFreeAddress
            ? ('abonent.sign_ups.enum.status.free' | translate)
            : ('abonent.sign_ups.enum.status.busy' | translate)
        }}
      </mat-chip>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="isVirtual">
    <mat-header-cell *matHeaderCellDef data-label="isVirtual">
      {{ 'abonent.sign_ups.field.is_virtual' | translate }}
    </mat-header-cell>
    <mat-cell
      data-label="isVirtual"
      *matCellDef="let element"
    >
      {{ !element.isVirtual | bool }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="progress">
    <mat-header-cell *matHeaderCellDef data-label="progress">
      {{ 'abonent.sign_ups.field.progress' | translate }}
    </mat-header-cell>
    <mat-cell
      data-label="progress"
      *matCellDef="let element"
    >
      {{ element | signUpStatus }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <mat-header-cell data-label="createdAt" *matHeaderCellDef>
      {{ 'abonent.sign_ups.field.created_at' | translate }}
    </mat-header-cell>
    <mat-cell
      data-label="createdAt"
      *matCellDef="let element"
    >
      {{ element.createdAt | date: 'dd.MM.yyyy' }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell data-label="actions" *matHeaderCellDef></mat-header-cell>
    <mat-cell data-label="actions" *matCellDef="let element">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
        <mat-icon svgIcon="submenu"></mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <div class="triggered-menu-content">
          <button
            class="triggered-menu-content__button"
            mat-button color="primary"
            *ngIf="signUpsService.activeSignUpWithCompany(element)"
            (click)="element.isFreeAddress ? onConnect(element) : onDelegate(element)">
            {{
              element.isFreeAddress
                ? ('abonent.sign_ups.button.connect' | translate)
                : ('abonent.sign_ups.button.delegate' | translate)
            }}
          </button>

          <button
            class="triggered-menu-content__button"
            mat-button
            color="primary"
            (click)="onProcessed(element)"
            *ngIf="signUpsService.unprocessedCompanySignUp(element)"
          >
            {{ 'abonent.sign_ups.button.processed' | translate }}
          </button>

          <button
            class="triggered-menu-content__button"
            mat-button color="primary"
            (click)="onInfo(element)"
          >
            {{ 'abonent.sign_ups.button.about' | translate }}
          </button>

          <button
            class="triggered-menu-content__button"
            mat-button
            color="error"
            (click)="onReject(element)"
            *ngIf="signUpsService.activeSignUpWithCompany(element)"
          >
            {{ 'abonent.sign_ups.button.reject' | translate }}
          </button>
        </div>
      </mat-menu>
    </mat-cell>
  </ng-container>

  <mat-header-row [style.display]="isTablet ? 'none' : 'flex'" *matHeaderRowDef="displayedColumns">
  </mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

<ng-template #emptyRequests>
  <app-empty-data></app-empty-data>
</ng-template>
