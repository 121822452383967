import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'rd-video-control',
  templateUrl: './control.html',
  styleUrls: ['./control.scss']
})
export class RdVideoControlComponent<T> {
  @Input() name: string;
  @Input() value: T;
  @Input() icon: string;
  @Input() active: boolean = false;
  @Input() isSelectButton: boolean = false;

  @Output() clickEvent = new EventEmitter();

  onClick(): void {
    this.clickEvent.emit(this.value);
  }
}
