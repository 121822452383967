import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RdVideoService } from "../../service/video.service";
import { DecorateUntilDestroy, takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";

@DecorateUntilDestroy()
@Component({
  selector: 'rd-video-event-control',
  templateUrl: './timeline-event-control.html'
})
export class RdVideoEventControlComponent {
  @Output() clickPrevEvent = new EventEmitter();
  @Output() clickNextEvent = new EventEmitter();

  clickPrev(): void {
    this.clickPrevEvent.emit();
  }

  clickNext(): void {
    this.clickNextEvent.emit();
  }
}
