import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SignUpsReportPopupComponent } from '@app/shared/components';
import {
  ConnectionService,
  SignUpsReportRequest
} from '@app/shared/entities/rd';
import { Constants } from '@app/shared/helpers';
import {
  EditPopupComponentData,
  GetSignUpsV2Query,
  SignUpRequestV2,
  SignUpResponse,
  SignUpStatus,
  WaitAbonentSignUpsCount
} from '@app/shared/models';
import { RequestsService } from '@app/shared/services';
import {
  ResolutionBreakpoint,
  ResolutionService
} from '@app/shared/services/resolution';
import { EditPopupComponent } from '@app/shared/templates';
import {
  DialogWrapperData,
  NavbarLink,
  PaginatedPageWrapperComponent
} from '@app/shared/ui';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import {
  AbonentsDelegationPopupBody,
  AbonentsDelegationPopupComponent,
  AbonentsDelegationPopupResponse,
  RejectRequestPopupComponent,
  SignUpsInfoPopupBody,
  SignUpsInfoPopupComponent
} from '../../popups';
import { SignUpsPageStore } from './store';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import {
  IGridState,
  IMenuItems,
} from '@app/shared/components/table-with-server-pagination/table-with-server-pagination.component';
import { IPaginatorConfig } from '@app/shared/components/prime-base-table/custom-paginator/custom-paginator.component';
import { IConvertedSignUpResponse } from '@app/views/abonents/components/pages/sign-ups-page/data-sourse-converter.pipe';
import { DialogService } from 'primeng/dynamicdialog';
import { AutoConnectionModalComponent } from '@app/views/abonents/components/pages/sign-ups-page/auto-connection-modal/auto-connection-modal.component';
import { isEqual } from 'lodash';
import {
  AppSizeStates,
  DeviceEventService
} from '@app/shared/services/device-event-service.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ServiceType } from '@app/views/services/submodules/shared/abonents-delegation/pipes/service-converter.pipe';
import {ActivatedRoute, Router} from '@angular/router';
import { SignUpsPageService } from '@app/views/abonents/services';
import { SignUpsPageMode } from '@app/views/abonents/models/sign-ups-page/sign-ups-page-mode.enum';

export interface ISimpleEntity<T> {
  name: string;
  value: T;
}

@Component({
  selector: 'app-sign-ups-page',
  templateUrl: './sign-ups-page.component.html',
  styleUrls: ['./sign-ups-page.component.scss'],
  providers: [ConnectionService, SignUpsPageStore, DialogService],
  encapsulation: ViewEncapsulation.None
})
export class SignUpsPageComponent implements OnInit, AfterViewInit {
  readonly pageSize: number = Constants.PAGE_SIZE;
  protected readonly ServiceType = ServiceType;

  @ViewChild(PaginatedPageWrapperComponent)
  paginatedPageWrapper: PaginatedPageWrapperComponent;
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;
  links$: Observable<NavbarLink[]> = this.store.links$;
  links: NavbarLink[] = [];
  loading$: Observable<boolean> = this.store.loading$;

  showWarning$: Observable<boolean> = this.store.showWarning$;
  waitAbonentSignUpsCount$: Observable<WaitAbonentSignUpsCount> =
    this.store.waitAbonentSignUpsCount$;

  mdWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(
    ResolutionBreakpoint.MD_W_DOWN
  );
  xlWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(
    ResolutionBreakpoint.XL_W_DOWN
  );

  public showScrollTopButton = false;
  public requestType: ISimpleEntity<string>[] = this.initRequestTypes();
  public menuItems: IMenuItems<IConvertedSignUpResponse>[] =
    this.initMenuItem();
  public signUpsResponse: SignUpResponse[] = [];
  public singUpTableConfig = this.signUpsPageService.tableConfig$; 
  public paginatorConfig: IPaginatorConfig<number> = {
    options: [10, 20, 30],
    first: 0,
    pageSize: 10,
    pageLinkSize: 3,
    rows: 10,
    totalRecords: 0,
    currentPageReportTemplate: this.currentPaginatorTemplate
  };
  public selectedNavbar: NavbarLink = null;
  public isMobileMode: boolean;
  public paginationState: IGridState = {
    lastSavedFilters: {
      signUpType: '',
      status: ['unprocessed']
    },
    lastSelectedPageSize: 10,
    lastSelectedPage: 1,
    sortBy: null,
    sortOrder: null,
    nameFilter: null
  };
  public waitTableData$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  public typing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public canLoadMore = true;

  private unSubscribe$: Subject<void> = new Subject<void>();
  private delegationDialogRef: MatDialogRef<EditPopupComponent>;

  constructor(
    public requestsService: RequestsService,
    public signUpsService: RequestsService,
    private store: SignUpsPageStore,
    private dialog: MatDialog,
    private resolution: ResolutionService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private primeDialogService: DialogService,
    private deviceEventService: DeviceEventService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private signUpsPageService: SignUpsPageService
  ) {}

  ngOnInit() {
    this.setUpDeviceType();
    this.setUpStoreConfig();
    this.initSearchListener();
  }

  ngAfterViewInit(): void {
    if (this.isMobileMode) {
      fromEvent(document.querySelector('.mat-drawer-content'), 'scroll')
        .pipe(takeUntil(this.unSubscribe$))
        .subscribe((res) => {
          this.onScroll(res.target);
        });
    }
  }

  public onChangeTab(linkIdx: number): void {}

  public onConnect(signUp: SignUpResponse): void {
    this.primeDialogService
      .open(AutoConnectionModalComponent, {
        header: this.translate.instant('sign_ups.abonent_sign_up.header'),
        width: '560px',
        data: {
          signUp: signUp
        }
      })
      .onClose.pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        if (res) {
          this.getSingUps(this.paginationState.lastSavedFilters);
        }
      });
  }

  onDelegate(signUp: SignUpResponse) {
    const data: DialogWrapperData<
      AbonentsDelegationPopupBody,
      AbonentsDelegationPopupResponse
    > = {
      title: this.translate.instant('abonents.signups.delegate.title'),
      componentName: 'DelegateSignUp',
      body: {
        signUp
      },
      submit: (event: AbonentsDelegationPopupResponse) => {
        if (!event.status) {
          return;
        }

        const request: SignUpRequestV2 = {
          status: event.status
        };

        if (event.status === SignUpStatus.REJECTED) {
          request.rejectedReason = this.translate.instant(
            'abonents.signups.delegate.message.reject_reason'
          );
        }

        this.store.updateSignUp({
          signUpId: signUp.signUpId,
          request,
          withoutNotification: true,
          reloadPage: true
        });
      }
    };

    this.dialog.open(AbonentsDelegationPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
        ? '100%'
        : '500px',
      data
    });
  }

  public onReport(): void {
    const data: DialogWrapperData<null, SignUpsReportRequest> = {
      title: this.translate.instant('dashboard.reports.signups.title'),
      componentName: 'GetSignUpsReport',
      submit: (request: SignUpsReportRequest) =>
        this.store.getReport({ request })
    };

    this.dialog.open(SignUpsReportPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }

  onProcessed(signUpResponse: SignUpResponse) {
    this.store.updateSignUp({
      signUpId: signUpResponse.signUpId,
      request: {
        isVirtual: null,
        rejectedReason: null,
        status: SignUpStatus.PROCESSED
      }
    });
  }

  onReject(signUpResponse: SignUpResponse) {
    const data: EditPopupComponentData = {
      title: this.translate.instant('abonents.signups.reject.title'),
      providedData: { number: signUpResponse.phone },
      component: RejectRequestPopupComponent,
      componentName: 'RejectSignUp',
      submit: (reason: string) => {
        if (reason) {
          this.store.updateSignUp({
            signUpId: signUpResponse.signUpId,
            request: {
              isVirtual: null,
              rejectedReason: reason,
              status: SignUpStatus.REJECTED
            },
            dialogRefToClose: this.delegationDialogRef
          });
          this.delegationDialogRef.close();
          setTimeout(() => this.getSingUps(this.paginationState.lastSavedFilters), 1000); // TODO Ugly solution
        }
      }
    };

    this.delegationDialogRef = this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
        ? '100%'
        : '650px',
      data
    });
  }

  onInfo(signUpResponse: SignUpResponse) {
    const data: DialogWrapperData<SignUpsInfoPopupBody, null> = {
      title: this.translate.instant('abonents.signups.info.title'),
      componentName: 'InfoSignUp',
      body: {
        signUp: signUpResponse,
        showPersonalInfo: false
      }
    };

    this.dialog.open(SignUpsInfoPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
        ? '100%'
        : '500px',
      data
    });
  }

  public onConfirmationOwners(): void {
    this.selectedNavbar = this.links.find((link) => link.name === SignUpsPageMode.WAIT_ABONENT_CONFIRMATION);

    this.waitTableData$.next(true);
    this.resetSelectedFilterConfig();

    this.paginationState.lastSavedFilters.status = [
      SignUpStatus.WAIT_ABONENT_CONFIRMATION
    ];
    this.getSingUps(this.paginationState.lastSavedFilters);
  }

  public onTyping(event: Event): void {
    this.paginationState.lastSavedFilters.searchString = (
      event.target as HTMLInputElement
    ).value;
    this.typing$.next(true);
  }

  public getText(text: string): string {
    const translated = this.translate.instant(text);
    return translated === text ? text : translated;
  }

  public onPageChange(page: number): void {
    this.paginatorConfig.page = page;
    this.waitTableData$.next(true);
    this.getSingUps(this.paginationState.lastSavedFilters);
  }

  public pageSizeChange(pageSize: number): void {
    this.paginatorConfig.first = pageSize;
    this.waitTableData$.next(true);
    this.getSingUps(this.paginationState.lastSavedFilters);
  }

  public onRequestTypeChange(
    entity: 'CONNECTION' | 'DISCONNECTION' | ''
  ): void {
    this.waitTableData$.next(true);
    this.paginatorConfig.page = 0;
    this.paginationState.lastSavedFilters.signUpType = entity;
    this.resetPaginationConfig();
    this.getSingUps(this.paginationState.lastSavedFilters);
  }

  public onLinksChange(link: NavbarLink): void {
    this.signUpsPageService.updateTableConfig(link.name)
    
    this.waitTableData$.next(true);
    this.resetSelectedFilterConfig();
    if (link.name === SignUpsPageMode.COMPLETE) {
      this.paginationState.lastSavedFilters.status = [
        SignUpStatus.CONNECTED, 
        SignUpStatus.DELEGATED, 
        SignUpStatus.REJECTED
      ];
      this.getSingUps(this.paginationState.lastSavedFilters);
      return;
    }
    if (link.name === SignUpsPageMode.BY_REGION) {
      this.paginationState.lastSavedFilters.withoutCompany = true;
      this.paginationState.lastSavedFilters.byRegion = true;
      this.getSingUps(this.paginationState.lastSavedFilters);
      return;
    }
    if (link.name === SignUpsPageMode.BUILDING) {
      this.paginationState.lastSavedFilters.withoutCompany = true;
      this.getSingUps(this.paginationState.lastSavedFilters);
      return;
    }
    if (link.name === SignUpsPageMode.WAIT_ABONENT_CONFIRMATION) {
      this.paginationState.lastSavedFilters.status = [
        SignUpStatus.WAIT_ABONENT_CONFIRMATION
      ];
      this.getSingUps(this.paginationState.lastSavedFilters);
      return;
    }
    this.paginationState.lastSavedFilters.status = link.name;
    this.getSingUps(this.paginationState.lastSavedFilters);
  }

  public onMenuItemSelect(
    item: IMenuItems<IConvertedSignUpResponse>,
    op: OverlayPanel,
    signUp: IConvertedSignUpResponse
  ): void {
    item.action(signUp);
    op.toggle(false);
  }

  private initSearchListener(): void {
    this.typing$
      .pipe(debounceTime(700), takeUntil(this.unSubscribe$))
      .subscribe((response) => {
        if (response) {
          this.resetPaginationConfig();
          this.getSingUps(this.paginationState.lastSavedFilters);
        }
      });
  }

  public onScroll(target: any): void {
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    const prevButtonValue = this.showScrollTopButton;

    this.showScrollTopButton = scrollTop > 300;
    if (prevButtonValue !== this.showScrollTopButton) {
      this.cd.detectChanges();
    }

    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      !this.waitTableData$.value &&
      this.canLoadMore
    ) {
      this.waitTableData$.next(true);
      this.loadMoreData();
    }
  }

  public loadMoreData(): void {
    if (!this.paginatorConfig.pageSize) {
      this.paginatorConfig.pageSize = 10;
      this.paginatorConfig.pageSize += 10;
    }
    this.paginatorConfig.pageSize += this.paginatorConfig.pageSize;
    this.getSingUps(this.paginationState.lastSavedFilters);
  }

  public scrollToTop(): void {
    document
      .querySelector('.mat-drawer-content')
      .scrollTo({ top: 0, behavior: 'smooth' });
  }

  public onArrowClick(convertedSignUpResponse: IConvertedSignUpResponse): void {
    this.router.navigate([`/abonents/subscriber/${convertedSignUpResponse.abonentId}`]);
  }

  private setUpStoreConfig(): void {
    this.store.getCounts();
    this.links$.pipe(take(1)).subscribe((links) => {
      this.links = links;
      this.selectedNavbar = this.links[0];
      this.checkFirstInitialization();
      this.getSingUps(this.paginationState.lastSavedFilters);
    });
  }

  private getSingUps(filters: GetSignUpsV2Query = {}): void {
    this.requestsService
      .getSignUpsV2List(
        this.paginatorConfig.page,
        this.paginatorConfig.pageSize ? this.paginatorConfig.pageSize : 10,
        filters
      )
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((response) => {
        this.canLoadMore = !(
          response.totalElements <= this.paginatorConfig.pageSize
        );
        this.signUpsResponse = response.content;
        this.paginatorConfig = {
          options: [10, 20, 30],
          first: 0,
          pageLinkSize: 3,
          totalRecords: response.totalElements,
          currentPageReportTemplate: this.currentPaginatorTemplate,
          pageSize: this.paginatorConfig.pageSize
        };

        this.waitTableData$.next(false);
        this.typing$.next(false);
      });
  }

  private initMenuItem(): IMenuItems<IConvertedSignUpResponse>[] {
    return [
      {
        name: (value: IConvertedSignUpResponse) => {
          if (
            !this.signUpsService.activeSignUpWithCompany(
              value as SignUpResponse
            )
          ) {
            return null;
          }
          if (value.signUpType === 'DISCONNECTION') {
            return 'Отключение';
          }
          return value.isFreeAddress
            ? 'Подключение'
            : this.translate.instant('sign_ups.page.button.delegate');
        },
        action: (value: IConvertedSignUpResponse) => {
          const response = this.signUpsResponse.find((s) =>
            isEqual(s.signUpId, value.signUpId)
          );
          value = {
            ...value,
            services: response.services
          };
          if (value.signUpType === 'DISCONNECTION') {  
            this.onConnect(value as SignUpResponse);
            return;
          }
          value.isFreeAddress
            ? this.onConnect(value as SignUpResponse)
            : this.onDelegate(value as SignUpResponse);
        },
        style: 'cursor: pointer; color: #0da7e2; font-weight: bold;'
      },
      {
        name: (value: IConvertedSignUpResponse) => {
          if (
            !this.signUpsService.unprocessedCompanySignUp(
              value as SignUpResponse
            )
          ) {
            return null;
          }
          return this.translate.instant('sign_ups.page.button.processed');
        },
        action: (value: IConvertedSignUpResponse) => {
          this.onProcessed(value as SignUpResponse);
        },
        style: 'cursor: pointer; color: #0da7e2; font-weight: bold;'
      },
      {
        name: () => {
          return this.translate.instant('sign_ups.page.button.info');
        },
        action: (value: IConvertedSignUpResponse) => {
          this.onInfo(value as SignUpResponse);
        },
        style: 'cursor: pointer; color: #0da7e2; font-weight: bold;'
      },
      {
        name: (value: IConvertedSignUpResponse) => {
          if (
            !this.signUpsService.activeSignUpWithCompany(
              value as SignUpResponse
            )
          ) {
            return null;
          }
          return this.translate.instant('sign_ups.page.button.reject');
        },
        action: (value: IConvertedSignUpResponse) => {
          this.onReject(value as SignUpResponse);
        },
        style: 'cursor: pointer; color: #f44336; font-weight: bold;'
      }
    ];
  }

  private initRequestTypes(): ISimpleEntity<string>[] {
    return [
      {
        name: 'Все заявки',
        value: ''
      },
      {
        name: 'На подключение',
        value: 'CONNECTION'
      },
      {
        name: 'На отключение',
        value: 'DISCONNECTION'
      }
    ];
  }

  private resetPaginationConfig(): void {
    this.paginatorConfig = {
      options: [10],
      first: 10,
      pageLinkSize: 0,
      totalRecords: 0,
      currentPageReportTemplate: ``
    };
  }

  private resetSelectedFilterConfig(): void {
    this.paginationState.lastSavedFilters.status = [];
    this.paginationState.lastSavedFilters.withoutCompany = null;
    this.paginationState.lastSavedFilters.byRegion = null;
  }

  private setUpDeviceType(): void {
    if (
      this.deviceEventService.appSizeState === AppSizeStates.TABLET_SMALL ||
      this.deviceEventService.appSizeState === AppSizeStates.PHONE
    ) {
      this.isMobileMode = true;
    }
  }

  private checkFirstInitialization(): void {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((res) => {
      if (Object.values(res)?.length) {
        const address = res.query;
        const status = res.status;
      
        this.selectedNavbar = this.links.find((link) => link.name?.toLowerCase() === status?.toLowerCase());
        this.paginationState.lastSavedFilters.searchString = address;
        this.onLinksChange(this.selectedNavbar);
        setTimeout(() => { this.paginationState.lastSavedFilters.searchString = null; }, 300);
      }
    });
  }

  get currentPaginatorTemplate() {return '{first} - {last} ' + this.translate.instant('shared.prime_base_table.of') + ' {totalRecords}'}
}
