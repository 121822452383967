<mat-card class="cameras-table-container card-container">
  <mat-card-header class="cameras-table-container-header header">
    <div class="cameras-table-container-header-wrapper title-block">
      <mat-card-title class="cameras-table-container-header-wrapper__title">
        {{ 'shared.cameras.table.container.title' | translate }}
        <a *ngIf="!(smWDownBreakpoint$ | async) && !environment.vivotec" class="link link__title" [href]="link" target="_blank">
          {{ 'shared.cameras.table.container.link.how_to_connect' | translate }}
        </a>
      </mat-card-title>
    </div>
    <div class="cameras-table-container-header-actions" *ngIf="!readonly">
      <button
        mat-raised-button
        color="primary"
        matTooltip="{{ 'shared.cameras.table.container.tooltip.refresh' | translate }}"
        [matTooltipPosition]="'before'"
        (click)="onRefreshCameras()"
        [disabled]="!cameras?.length"
      >
        <mat-icon>refresh</mat-icon>
      </button>

      <button
        mat-raised-button
        color="primary-inverse"
        matTooltip="{{ 'shared.cameras.table.container.tooltip.add' | translate }}"
        [matTooltipPosition]="'before'"
        (click)="onAdd()"
        [disabled]="disabled"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-card-header>

  <hr>
  <a class="link link__mobile"  *ngIf="(smWDownBreakpoint$ | async) && !environment.vivotec" [href]="link" target="_blank">
    {{ 'shared.cameras.table.container.link.how_to_connect' | translate }}
  </a>
  <mat-card-content>
    <mat-table [dataSource]="cameras" *ngIf="cameras?.length > 0">
      <ng-container matColumnDef="active">
        <mat-header-cell data-label="active" *matHeaderCellDef>
          {{ 'shared.cameras.table.container.field.status' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="active"
          *matCellDef="let element"
        >
          <mat-chip
            [disabled]="true"
            [selectable]="false"
            [color]="cameraStatusColor(element)"
          >{{ element.active ? ('shared.cameras.table.container.enum.status.online' | translate) : ('shared.cameras.table.container.enum.status.offline' | translate) }}</mat-chip>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="uri">
        <mat-header-cell *matHeaderCellDef data-label="uri">
          {{ 'shared.cameras.table.container.field.uri' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="uri"
          *matCellDef="let element"
        >
          <span>{{ element.uri }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef data-label="id">
          {{ 'shared.cameras.table.container.field.id' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="id"
          *matCellDef="let element"
        >
          <span>{{ element.id }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="bitrate">
        <mat-header-cell *matHeaderCellDef data-label="bitrate">
          {{ 'shared.cameras.table.container.field.bitrate' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="bitrate"
          *matCellDef="let element"
          [class.mat-cell-warn]="isBitrateError(element.bitrate) === 1"
          [class.mat-cell-error]="isBitrateError(element.bitrate) === 2"
        >
          {{ element.active && element.bitrate !== null ? element.bitrate : '' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="latency">
        <mat-header-cell *matHeaderCellDef data-label="latency">
          {{ 'shared.cameras.table.container.field.latency' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="latency"
          *matCellDef="let element"
          [class.mat-cell-warn]="isLatencyError(element.segmentsCount, element.segmentLength) === 1"
          [class.mat-cell-error]="isLatencyError(element.segmentsCount, element.segmentLength) === 2"
        >
          {{ element.active ? getLatencyRange(element.segmentsCount, element.segmentLength) : ''}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="preview">
        <mat-header-cell *matHeaderCellDef data-label="preview">
          {{ 'shared.cameras.table.container.field.preview' | translate }}
        </mat-header-cell>
        <mat-cell data-label="preview" *matCellDef="let element">
          <app-camera-thumbnail [camera]="element"></app-camera-thumbnail>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef data-label="location">
          {{ 'shared.cameras.table.container.field.location' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="location"
          *matCellDef="let element"
        >
          {{ element?.location?.name || '' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="private">
        <mat-header-cell data-label="private" *matHeaderCellDef>
          {{ 'shared.cameras.table.container.field.private_camera' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="private"
          *matCellDef="let element"
        >
          {{ element.rdaUid && element.private | bool }}
          {{ element.rdaUid && element.private ? ('shared.cameras.table.container.tip.private_camera' | translate: { value: element.rdaUid }) : '' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="default">
        <mat-header-cell data-label="default" *matHeaderCellDef>
          {{ 'shared.cameras.table.container.field.displayed_when_calling' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="default"
          *matCellDef="let element"
        >
          <mat-icon
            class="cameras-table-container__default-icon"
            [class.cameras-table-container__default-icon--unselected]="!element.serviceId"
          >grade</mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef data-label="actions"></mat-header-cell>
        <mat-cell data-label="actions" *matCellDef="let element; let i = index">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
            <mat-icon svgIcon="submenu"></mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <div class="triggered-menu-content">
              <button
                class="triggered-menu-content__button"
                mat-button
                color="primary"
                (click)="onInfo(element)"
              >
                {{ 'shared.cameras.table.container.button.about' | translate }}
              </button>

              <ng-container *ngIf="!readonly">
                <button
                  class="triggered-menu-content__button"
                  mat-button
                  color="primary"
                  (click)="onEdit(element)"
                >
                  {{ 'shared.cameras.table.container.button.edit' | translate }}
                </button>

                <button
                  class="triggered-menu-content__button"
                  mat-button
                  color="warn"
                  *ngIf="element.rdva?.uri || element.rdvaUri"
                  (click)="onReset(element.rdva?.uri || element.rdvaUri, element.id)"
                >
                  {{ 'shared.cameras.table.container.button.reset' | translate }}
                </button>

                <button
                  class="triggered-menu-content__button"
                  mat-button
                  color="error"
                  (click)="onDelete(element.id, i)"
                >
                  {{ 'shared.cameras.table.container.button.delete' | translate }}
                </button>
              </ng-container>
            </div>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row
        [style.display]="(smWDownBreakpoint$ | async) ? 'none' : 'flex'"
        *matHeaderRowDef="displayedColumns"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <ngx-skeleton-loader
      *ngIf="loading"
      count="1"
      [theme]="{
        'border-radius': '8px',
        'margin-bottom': '0px',
        'margin-top': '0px',
        'height': (smWDownBreakpoint$ | async) ? '610.5px' : '228.5px'
    }"></ngx-skeleton-loader>

    <app-info-block *ngIf="cameras?.length === 0 && !loading">
      <ng-container info-block-text>
        {{ emptyCamerasCaption }}
      </ng-container>
    </app-info-block>
  </mat-card-content>
</mat-card>
