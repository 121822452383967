import { Injectable } from "@angular/core"
import { MessageService } from "../message-service/message.service"
import { takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed"
import { MessageType } from "../models/p2p.model"
import { PeerConnectionService } from "../peer-connection/peer-connection.service"
import { MessageSignalPayload } from "../models/message-service.model"

@Injectable({ providedIn: 'root' })
export class SignalService {
  constructor(
    private messageService: MessageService,
    private peerConnectionService: PeerConnectionService
  ) { }

  destroy(): void { }

  onSignal(): void {
    this.onNegotationNeeded()

    this.messageService.on<MessageSignalPayload>('signal')
      .pipe(takeUntilDestroyed(this, { destroyMethod: this.destroy }))
      .subscribe(async (signalMessage) => {
        try {
          switch (signalMessage.type) {
            case MessageType.offer:
              await this.createAndSendDescription(signalMessage.id, signalMessage.payload.description);
              break;
            case MessageType.answer:
              await this.peerConnectionService.setRemoteDescription(signalMessage.id, signalMessage.payload.description);
              break;
            case MessageType.candidate:
              this.peerConnectionService.addIceCandidate(signalMessage.id, signalMessage.payload.candidate);
              break;
          }
        } catch (error) {
          console.error('Error during processing signal', error);
        }
      })
  }

  private onNegotationNeeded(): void {
    this.peerConnectionService.pcNegotiationNeeded$
      .pipe(takeUntilDestroyed(this, { destroyMethod: this.destroy }))
      .subscribe(async (peerConnectionInfo) => {
        await this.sendOffer(peerConnectionInfo.id);
      })
  }

  private async createAndSendDescription(id: number, description?: RTCSessionDescription | null): Promise<void> {
    const answerResult = await this.peerConnectionService.createAnswer(id, description);
    if (!answerResult.success) {
      await this.sendOffer(id);
      return;
    }

    this.messageService.send('signal', {
      id,
      type: MessageType.answer,
      payload: {
        description: answerResult.description
      }
    });
  }

  private async sendOffer(id: number): Promise<void> {
    const offerResult = await this.peerConnectionService.createOffer(id);
    this.messageService.send('signal', {
      id,
      type: MessageType.offer,
      payload: {
        description: offerResult.description
      }
    })
  }
}
