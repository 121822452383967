<p-panel
  *appRdeaCanAccess="permissionsTreeKey.ABONENTS_SIGN_UPS"
  [header]="'abonent.page.navbar.owner' | translate"
  [toggleable]="true"
  [collapsed]="false"
  [expandIcon]="'pi pi-angle-down'">
  <div class="own-services">
    <ng-container *ngIf="!cardView">
      <div class="own-services-table">
        <app-prime-base-table
          [primeTableConfig]="connectionsTableConfig"
          [tableData]="abonentConnection | ownServicesAdapter : ownSearch"
          [showSkeleton]="showSkeleton$ | async"
          [hidBorder]="false"
          [pageSize]="3"
          [showEmptyImg]="false"
          (navigate)="onNavigate($event)">
          <ng-container addition-actions>
            <div class="own-services-table-actions">
              <span class="own-services-table-actions__text">
                {{'abonent.sign_ups.field.own_services' | translate}}
              </span>
              <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input
                #ownServices
                class="own-services-table-actions__control"
                pInputText
                [placeholder]="'shared.select.search.placeholder.search' | translate"
                (input)="ownSubject$.next(ownServices.value)" />
              </span>
            </div>
          </ng-container>
        </app-prime-base-table>
      </div>
    </ng-container>
    <ng-container *ngIf="cardView">
      <div class="panel-content" (click)="onOwnCardShow()">
        <span>
          {{'abonent.sign_ups.field.own_services' | translate}}
        </span>
        <div class="panel-content-search">
          <input
            #ownServices
            style="height: 35px;width: 155px;"
            pInputText
            [placeholder]="'shared.select.search.placeholder.search' | translate"
            (click)="onOwnInputClick($event)"
            (input)="ownSubject$.next(ownServices.value)">
          <img src="assets/icons/ic-angle-down.svg" alt="error">
        </div>
      </div>
      <div class="own-services-cards" *ngIf="showOwnCards" >
        <div *ngFor="let service of abonentConnection | ownServicesAdapter : ownSearch" class="own-services-cards-card" @openCloseHew>
          <div class="own-services-cards-card-header">
            <span class="own-services-cards-card-header__title">{{'abonent.sign_ups.field.account' | translate}}</span>
            <span class="own-services-cards-card-header__number">
              {{service.accountNumber}}
            </span>
          </div>
          <div class="own-services-cards-card-middle" (click)="onNavigate({ data: service, field:'serviceType' })">
            <div [ngClass]="{'active': service.isBlocked === false, 'blocked': service.isBlocked === true }"></div>
            <span>{{service.serviceType}}</span>
            <mat-icon [style]="'height: 25px; width: 18px;'" svgIcon="right-arrow"></mat-icon>
          </div>
          <div class="own-services-cards-card-footer">
            <span class="own-services-cards-card-footer">{{service.fullAddress}}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <hr/>
  <div class="shared-services">
    <ng-container *ngIf="!cardView">
      <app-prime-base-table
        [primeTableConfig]="delegationsTableConfig"
        [tableData]="abonentDelegation | ownServicesAdapter : delegationSearch"
        [showSkeleton]="showSkeleton$ | async"
        [hidBorder]="false"
        [showEmptyImg]="false"
        [pageSize]="3"
        (navigate)="onNavigate($event)">
        <ng-container addition-actions>
          <div class="own-services-table-actions">
            <span class="own-services-table-actions__text">
              {{ 'abonent.sign_ups.field.to_abonent_delegation' | translate }}
            </span>
            <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              #delegationServices
              class="own-services-table-actions__control"
              pInputText
              [placeholder]="'shared.select.search.placeholder.search' | translate"
              (click)="$event.stopPropagation()"
              (input)="delegationSubject$.next(delegationServices.value)" />
            </span>
          </div>
        </ng-container>
      </app-prime-base-table>
    </ng-container>
    <ng-container *ngIf="cardView">
      <div class="panel-content" (click)="onDelegationCardShow()">
        <span>
          {{ 'abonent.sign_ups.field.abonent_shared' | translate }}
        </span>
        <div class="panel-content-search">
          <input
            #sharedServices
            style="height: 35px;width: 155px;"
            pInputText
            [placeholder]="'shared.select.search.placeholder.search' | translate"
            (click)="onSharedInputClick($event)"
            (input)="delegationSubject$.next(sharedServices.value)">
          <img src="assets/icons/ic-angle-down.svg" alt="error">
        </div>
      </div>
      <div class="shared-services-cards" *ngIf="showDelegationCards">
        <div *ngFor="let service of abonentDelegation | ownServicesAdapter : delegationSearch" class="own-services-cards-card"  @openCloseHew>
          <div class="shared-services-cards-card">
            <div class="own-services-cards-card-header">
              <span class="own-services-cards-card-header__title">
                {{ 'abonent.sign_ups.field.account' | translate }}
              </span>
              <span class="own-services-cards-card-header__number">{{service.accountNumber}}</span>
            </div>
            <div class="own-services-cards-card-header-right">
              <div class="own-services-cards-card-header-right-phone" (click)="onNavigate({ data: service, field:'fromAbonentPhone' })">
                <span>{{service.fromAbonentPhone}}</span>
                <mat-icon [style]="'height: 25px; width: 18px;'" svgIcon="right-arrow"></mat-icon>
              </div>
            </div>
          </div>
          <div class="own-services-cards-card-middle" (click)="onNavigate({ data: service, field:'serviceType' })">
            <div [ngClass]="{'active': service.isBlocked === false, 'blocked': service.isBlocked === true }"></div>
            <span>{{service.serviceType}}</span>
            <mat-icon [style]="'height: 25px; width: 18px;'" svgIcon="right-arrow"></mat-icon>
          </div>
          <div class="own-services-cards-card-footer">
            <span class="own-services-cards-card-footer">{{service.fullAddress}}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</p-panel>

<p-panel
  *appRdeaCanAccess="permissionsTreeKey.ABONENTS_SIGN_UPS"
  [header]="'abonent.page.owner.header.accounts' | translate"
  [toggleable]="true"
  [collapsed]="true"
  [expandIcon]="'pi pi-angle-down'">
  <app-abonent-accounts
    [abonentId]="abonentId"
    [accounts]="owner?.accounts"
    [services]="accountServices"
    (selectAccount)="onSelectAccount($event.accountId)"
    (editAccount)="onEditAccount($event.accountId, $event.accountNumber)"
    (deleteAccount)="onDeleteAccount($event.accountId)"
    (blockAccount)="onBlockAccount($event.accountId)"
    (unblockAccount)="
      onUnblockAccount($event.accountId)
    "></app-abonent-accounts>
</p-panel>

<p-panel
  *appRdeaCanAccess="permissionsTreeKey.ABONENTS_SIGN_UPS"
  [header]="'abonent.page.owner.header.connection_applications' | translate"
  [toggleable]="true"
  [collapsed]="true"
  [expandIcon]="'pi pi-angle-down'">
  <app-prime-base-table
    *ngIf="!cardView"
    [primeTableConfig]="ordersTableConfig"
    [tableData]="owner?.signUps | singUps"
    [showSkeleton]="showSkeleton$ | async"
    [menuItems]="menuItems"
    [hidBorder]="false"
    [showEmptyImg]="false"
    [pageSize]="4">
    <ng-container addition-actions>
      <p-selectButton
        [options]="selectOptions"
        [formControl]="selectControl"
        [optionLabel]="'label'"
        (onChange)="onSelectChange($event.value.value)"></p-selectButton>
    </ng-container>
  </app-prime-base-table>
  <ng-container *ngIf="cardView">
    <div class="request-filters">
      <p-selectButton
        [options]="selectOptions"
        [formControl]="selectControl"
        [optionLabel]="'label'"
        (onChange)="onSelectChange($event.value.value)"
      ></p-selectButton>
    </div>
    <div class="request-cards">
      <div class="request-cards-card" *ngFor="let request of (owner?.signUps | singUps)">
        <div class="request-cards-card-header">
          <div class="request-cards-card-header-info">
            <label>{{ 'abonent.sign_ups.field.table_status' | translate }}</label>
            <span>{{request.adaptiveStatus}}</span>
          </div>
          <div class="request-cards-card-header-info">
            <label>{{ 'abonent.sign_ups.field.created_date' | translate }}</label>
            <span>{{request.createdAt | date: 'dd MMMM yyyy'}} {{ ' ' }} {{ 'shared.date_abbreviations.year' | translate}}</span>
          </div>
        </div>
        <div class="request-cards-card-footer">
          <div class="request-cards-card-footer-info">
            <span>{{request.addressStr}}, {{ 'abonent.sign_ups.field.flat' | translate }} {{request.address?.flat}},</span>
            <img *ngIf="request.isVirtual" src="assets/icons/ic-tube-dark.svg" alt="error">
          </div>
          <div class="request-cards-card-footer-actions">
            <i class="pi pi-ellipsis-v" style="color: #0DA7E2; width: 30px" (click)="op.toggle($event)"></i>
            <p-overlayPanel #op>
              <ng-template pTemplate="content">
                <ng-container *ngFor="let item of menuItems">
                  <div
                    *ngIf="item.name(request) as name"
                    (click)="onMenuItemSelect(item, op, request)"
                    [style]="item.style"
                    class="content-wrapper-table__body-custom"
                  ><span>{{name}}</span>
                  </div>
                </ng-container>
              </ng-template>
            </p-overlayPanel>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</p-panel>

<div style="display: none">
  <app-abonent-sign-ups
    #abonentSignUps
    [signUps]="owner?.signUps"
    [activeLink]="activeLink"
    (updateSignUp)="
      onUpdateSignUp($event.signUpId, $event.request)
    "></app-abonent-sign-ups>
</div>
