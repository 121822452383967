import { Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const CUSTOM_CHECKBOX_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => CheckboxComponent),
  multi: true
};

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [CUSTOM_CHECKBOX_ACCESSOR]
})
export class CheckboxComponent implements OnDestroy, ControlValueAccessor {
  @Input() label?: string;
  @Input() description?: string;
  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  @Output() private closed: EventEmitter<void> = new EventEmitter();
  @Output() private valueSelected: EventEmitter<{ value: boolean }> = new EventEmitter();
  private innerValue: boolean;
  private _disabled: boolean;
  private onTouchedCallback: () => void = () => { };
  private onChangeCallback: (_: any) => void = () => { };

  ngOnDestroy() {
    this.closed.emit();
  }

  get value(): boolean {
    return this.innerValue;
  }

  set value(v: boolean) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  get disabled(): boolean {
    return this._disabled;
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: boolean) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onSelect(event: { checked: boolean }) {
    this.valueSelected.emit({ value: event.checked });
  }
}
