import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {forkJoin, Observable} from 'rxjs';

import {LogsResponse, LogsResponseMessage} from '@app/shared/models';
import {environment} from 'environments/environment';
import {RdvaCameraLogsPatterns, RdvaCameraLogsResponse, RdvaPlaylistItem, VideoshotGetRequest} from '../models';
import {RdvaCameraLogsParser} from './rdva-camera-logs-parser';
import {LocalStorageGeneralKey, LocalStorageHelper} from '@app/shared/entities/common';
import {TranslateService} from '@ngx-translate/core';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';
import { RdvaCameraLogsStatusName } from '../models/rdva-camera-logs-status-name.enum';

@Injectable({providedIn: 'root'})
export class RdvaApiService {
  readonly rdvaHlsPort = environment.RDVAHlsPort;

  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService,
    private humanErrorTranslationService: HumanErrorTranslationService
  ) {
  }

  getPlaylists(rdvaUri: string, cameraId: number, timeZone: number = 180): Observable<RdvaPlaylistItem[]> {
    return this.httpClient.get<RdvaPlaylistItem[]>(`https://s.${rdvaUri}/archive/playlists?${cameraId}:${timeZone}`, {});
  }

  getPlaylist(source: string): Observable<string> {
    return this.httpClient.get(source, {responseType: 'text'});
  }

  resetCamera(rdvaUri: string, cameraId: number): Observable<any> {
    return forkJoin([
      this.httpClient.get(`https://s.${rdvaUri}/source/terminate?${cameraId}`),
      this.httpClient.get(`https://s.${rdvaUri}/source/reset?${cameraId}`)
    ]);
  }

  addVideoshotBatch(request: VideoshotGetRequest): Observable<any> {
    return this.httpClient.get(`https://s.${request.rdvaUri}/qvs?${request.cameraId}:${request.dateFormatString}:${request.duration}`, {});
  }

  getVideoshot(request: VideoshotGetRequest): Observable<ArrayBuffer> {
    return this.httpClient.request('get', `https://s.${request.rdvaUri}/vs?${request.cameraId}:${request.dateFormatString}:${request.duration}`, {
      responseType: 'arraybuffer'
    });
  }

  getExtendedLogs(rdvaUri: string, cameraId: number): Observable<LogsResponse[]> {
    return this.getComponentLogs(rdvaUri, cameraId)
      .pipe(
        map((response: RdvaCameraLogsResponse[]) =>
          this.prepareExtendedResponse(response)
        )
      );
  }

  getParsedLogs(rdvaUri: string, cameraId: number): Observable<LogsResponse[]> {
    return this.getComponentLogs(rdvaUri, cameraId)
      .pipe(
        map((response: RdvaCameraLogsResponse[]) =>
          this.prepareParsedResponse(response)
        )
      );
  }

  getMixedLogs(rdvaUri: string, cameraId: number): Observable<{ logs: LogsResponse[], extendedLogs: LogsResponse[] }> {
    return this.getComponentLogs(rdvaUri, cameraId)
      .pipe(
        map((response: RdvaCameraLogsResponse[]) => ({
          logs: this.prepareParsedResponse(response),
          extendedLogs: this.prepareExtendedResponse(response)
        }))
      );
  }

  getComponentLogs(sourceUri: string, sourceId: number): Observable<RdvaCameraLogsResponse[]> {
    return this.httpClient.request<RdvaCameraLogsResponse[]>('get', `https://s.${sourceUri}/logs?${sourceId}`, {});
  }

  private prepareExtendedResponse(cameraLogsResponse: RdvaCameraLogsResponse[]): LogsResponse[] {
    const logsResponse: LogsResponse[] = [];

    if (!cameraLogsResponse?.length) {
      return null;
    }

    for (let idx = cameraLogsResponse.length - 1; idx > -1; --idx) {
      logsResponse.push({
        message: cameraLogsResponse[idx].message,
        createdAt: new Date(cameraLogsResponse[idx].date).toISOString()
      });
    }

    return logsResponse;
  }

  private prepareParsedResponse(cameraLogsResponse: RdvaCameraLogsResponse[]): LogsResponse[] {
    const parsedMessages: LogsResponse[] = [];

    if (!cameraLogsResponse?.length) {
      return null;
    }

    for (let idx = cameraLogsResponse.length - 1; idx > -1; --idx) {
      let cameraLogsParsedMessage: LogsResponseMessage = null;

      for (const cameraLogsPatternsKey of Object.keys(RdvaCameraLogsPatterns)) {
        const regExp: RegExp = new RegExp(RdvaCameraLogsPatterns[cameraLogsPatternsKey]);
        const message: string = cameraLogsResponse[idx].message;

        if(regExp.test(message) && cameraLogsPatternsKey === RdvaCameraLogsStatusName.FAILED){
          cameraLogsParsedMessage = RdvaCameraLogsParser.FAILED(this.humanErrorTranslationService.translate(message), this.translate);
          break;
        }

        if (regExp.test(message)) {
          cameraLogsParsedMessage = RdvaCameraLogsParser[cameraLogsPatternsKey](message, this.translate);
          break;
        }
      }

      if (cameraLogsParsedMessage) {
        parsedMessages.push({
          message: cameraLogsParsedMessage,
          createdAt: new Date(cameraLogsResponse[idx].date).toISOString()
        });
      }
    }

    return parsedMessages;
  }
}
