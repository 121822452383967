import { Injectable } from "@angular/core";
import { TZ_OFFSET_IN_MILLISECONDS } from "@app/shared/components/video-player";
import { takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";
import { Option } from "../components/select-control/select-control";
import { IState } from "../models/state.model";
import { TimelineData } from "../models/timeline.model";

export type State = IState[keyof IState];

@Injectable({ providedIn: 'root' })

export class PlayerService {
  private timelineDataSubject = new BehaviorSubject<TimelineData | null>(null)
  timelineData$ = this.timelineDataSubject.asObservable()

  private timelineMovePositionSubject = new BehaviorSubject<number>(0);
  timelineMovePosition$ = this.timelineMovePositionSubject.asObservable();

  private sliderPositionSubject = new BehaviorSubject<number>(0);
  sliderPosition$ = this.sliderPositionSubject.asObservable();

  private sliderIsDraggingSubject = new BehaviorSubject<boolean>(false);
  sliderIsDragging$ = this.sliderIsDraggingSubject.asObservable();

  private tooltipPositionSubject = new BehaviorSubject<number>(0);
  tooltipPosition$ = this.tooltipPositionSubject.asObservable();

  private tooltipValueSubject = new BehaviorSubject<string>('');
  tooltipValue$ = this.tooltipValueSubject.asObservable();

  private tooltipIsHiddenSubject = new BehaviorSubject<boolean>(true);
  tooltipIsHidden$ = this.tooltipIsHiddenSubject.asObservable();

  private selectedDateTimeSubject = new BehaviorSubject<number | null>(null);
  selectedDateTime$ = this.selectedDateTimeSubject.asObservable();

  private selectedPlayerTimeSubject = new BehaviorSubject<number | null>(null);
  selectedPlayerTime$ = this.selectedPlayerTimeSubject.asObservable();

  private currentTimePlayerSubject = new BehaviorSubject<number | null>(null);
  currentTimePlayer$ = this.currentTimePlayerSubject.asObservable()

  private selectedPlaylistPlayerSubject = new BehaviorSubject<Option | null>(null);
  selectedPlaylistPlayer$ = this.selectedPlaylistPlayerSubject.asObservable();

  private stateSubject = new BehaviorSubject<State | null>(null);
  stateSubject$ = this.stateSubject.asObservable()
    .pipe(filter(state => !!state))


  getState(): State {
    return this.stateSubject.value;
  }

  resetState(): void {
    this.stateSubject.next(null);
  }

  getTimelineData(): TimelineData {
    return this.timelineDataSubject.value;
  }

  updateTimelineDataSubject(value: TimelineData): void {
    this.timelineDataSubject.next(value)
  }

  updateState(value: State): void {
    this.stateSubject.next(value)
  }

  updateTimelineMovePosition(value: number): void {
    this.timelineMovePositionSubject.next(value)
  }

  updateSliderPosition(value: number): void {
    this.sliderPositionSubject.next(value)
  }

  updateTooltipPosition(value: number): void {
    this.tooltipPositionSubject.next(value)
  }

  updateTooltipValue(value: string): void {
    this.tooltipValueSubject.next(value)
  }

  updateTooltipIsHidden(value: boolean): void {
    this.tooltipIsHiddenSubject.next(value)
  }

  updateSliderIsDragging(value: boolean): void {
    this.sliderIsDraggingSubject.next(value);
  }

  updateSelectedDateTime(value: number): void {
    this.selectedDateTimeSubject.next(value);
  }

  updateSelectedPlayerTime(value: number): void {
    this.selectedPlayerTimeSubject.next(value);
  }

  updateSelectedPlaylistPlayer(item: Option): void {
    this.selectedPlaylistPlayerSubject.next(item);
  }

  updateCurrentTimePlayer(value: number): void {
    this.currentTimePlayerSubject.next(value);
  }

  getSliderIsDragging(): boolean {
    return this.sliderIsDraggingSubject.value;
  }

  getSliderPosition(): number {
    return this.sliderPositionSubject.value;
  }
}