export class Converter {
  static pixelToPercentage(value: number, totalWidth: number): number {
    return value / totalWidth * 100;
  }

  static timestampToPercentage(time: number, startTime: number, endTime: number): number {
    if (endTime === startTime) {
      return 0;
    }
  
    const percentage = ((time - startTime) / (endTime - startTime)) * 100;
    return percentage;
  }

  static timestampToPixel(time: number, startTime: number, endTime: number, totalWidth: number): number {
    const percentage = this.timestampToPercentage(time, startTime, endTime);
    return this.percentageToPixel(percentage, totalWidth);
  }

  static percentageToTimestamp(percentage: number, startTime: number, endTime: number): number {
    if (endTime === startTime) {
      return 0;
    }
  
    const timestamp = ((percentage / 100) * (endTime - startTime) + startTime);
    return timestamp;
  }

  static percentageToPixel(percentage: number, totalWidth: number): number {
    return (percentage / 100) * totalWidth;
  }
}