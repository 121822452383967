import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HlsInfo } from "../models/hls.model";
import { RdeaHls, VideoPlayerHelperService } from "@app/shared/components/video-player";
import { LocalStorageGeneralKey, LocalStorageHelper } from "@app/shared/entities";
import { MediaService } from "../media.service/media.service";
import { IVideoConfigHls } from "../models/video.models";

@Injectable({ providedIn: 'root' })
export class HlsConnectionService {
  private hlsInfoListSubject = new BehaviorSubject<HlsInfo[]>([]);
  hlsInfoList$ = this.hlsInfoListSubject.asObservable();

  constructor(private mediaService: MediaService) { }

  createHlsConnection(config: IVideoConfigHls): RdeaHls {
    const token = LocalStorageHelper.getItem(LocalStorageGeneralKey.AUTH_TOKEN);

    const hls = new RdeaHls(token, {
      autoStartLoad: true,
      maxBufferHole: 10,
      liveDurationInfinity: true,
      startFragPrefetch: true,
      manifestLoadingTimeOut: 10000,
      manifestLoadingMaxRetry: 5,
    });

    const hlsInfo: HlsInfo = {
      id: config.id,
      hls
    }

    const currentList = this.hlsInfoListSubject.value;
    this.hlsInfoListSubject.next([...currentList, hlsInfo])

    this.mediaService.addMedia({
      id: config.id,
      connectMedia: (element: HTMLVideoElement): void => {
        hls.attachMedia(element)
      }
    })

    return hls;
  }

  loadSource(id: number, url: string): void {
    const { hls } = this.findHlsInfo(id);
    hls.loadSource(url);
  }

  destroyHls(id: number): void {
    const { hls } = this.findHlsInfo(id);
    hls.destroy();

    const filteredHlsInfoList = this.filterHlsInfo(id);
    this.hlsInfoListSubject.next(filteredHlsInfoList);

    this.mediaService.removeMedia(id);
  }

  destroy(): void {
    this.hlsInfoListSubject.value
      .forEach(value => value.hls.destroy());
    this.hlsInfoListSubject.next([]);
  }

  private findHlsInfo(id: number): HlsInfo {
    return this.hlsInfoListSubject.value
      .find(value => value.id == id)
  }

  private filterHlsInfo(id: number): HlsInfo[] {
    return this.hlsInfoListSubject.value
      .filter(value => value.id !== id);
  }
}
