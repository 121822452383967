import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { AddressInfo, Country } from '@app/shared/models';
import { AddressFormatter } from '@app/shared/services';
import { Subject } from 'rxjs';
import { YandexAddressHelper } from './yandex-address.helper';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-yandex-address',
  templateUrl: './yandex-address.component.html',
  styleUrls: ['./yandex-address.component.scss'],
  providers: [YandexAddressHelper]
})
export class YandexAddressComponent implements OnInit, OnDestroy {
  @Input() label: string; 
  @Input() initialAddress: AddressInfo;
  @Input() country: Country;
  
  @Output() private selectAddress: EventEmitter<{ address: string, entrance: string }> = new EventEmitter();
  @Output() private getAddressError: EventEmitter<{ error: string }> = new EventEmitter();
  private onDestroy$: Subject<void> = new Subject();
  private suggestView: any;

  constructor(
    public controlContainer: ControlContainer,
    private yandexAddressHelper: YandexAddressHelper,
    private addressFormatter: AddressFormatter,
    private translate: TranslateService
  ) { }

  get formGroup(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  ngOnInit() {
    this.initSuggestions();
    this.prepareControlls();
    this.initListeners();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getError(): string {
    const control = this.getControl('rawAddress');

    if (control.valid) {
      return null;
    }

    if (control.hasError('city')) {
      return this.translate.instant('shared.address.input.message.specify_city');
    }

    if (control.hasError('street')) {
      return this.translate.instant('shared.address.input.message.specify_street');
    }

    if (control.hasError('house')) {
      return this.translate.instant('shared.address.input.message.specify_house');
    }

    return null;
  }

  onKey(event) {
    if (this.suggestView.options.get('zIndex') !== 40000) {
      this.suggestView.options.set('zIndex', 40000);
    }
  }

  private initSuggestions() {
    this.yandexAddressHelper.getMapSuggestions(
      (suggestView: any) =>
        this.suggestView = suggestView,
      (address: { kind: string, name: string }[], addressString: string) =>
        this.setControls(address, addressString),
      (error: string) => this.getAddressError.emit({ error }),
      () => { }
    );
  }

  private prepareControlls() {
    if (!this.initialAddress) {
      return;
    }

    this.setValue('country', this.initialAddress.country);
    this.setValue('city', this.initialAddress.city);
    this.setValue('street', this.initialAddress.street);
    this.setValue('house', this.initialAddress.house);
    this.setValue('block', this.initialAddress.block);
    this.setValue('housing', this.initialAddress.housing);
    this.setValue('universalCode', this.initialAddress.universalCode);
    this.setValue('rawAddress', this.addressFormatter.formatAddressInfo(this.initialAddress));
    this.getControl('rawAddress').disable();
  }

  private initListeners() {
    this.yandexAddressHelper.getAddress(
      (success: boolean, error: string) => {
        if (!success) {
          this.getAddressError.emit({ error });
          return;
        }

        this.suggestView.options.set('zIndex', -40000);
      }
    );
  }

  private setControls(address: { kind: string, name: string }[], addressString: string) {
    const { addressInfo, entrance } = this.yandexAddressHelper.getAddressInfo(address);
    addressInfo.country = this.country;

    this.setValue('country', this.country, true);

    if (addressInfo.house && !addressInfo.city) {
      this.setValue(
        'city',
        this.translate.instant('shared.address.input.message.no_city'),
        true
      );
    } else {
      this.setValue('city', addressInfo.city, true);
    }

    this.setValue('house', addressInfo.house, true);
    this.setValue('building', addressInfo.building, true);
    this.setValue('housing', addressInfo.housing, true);
    this.setValue('block', addressInfo.block, true);

    if (addressInfo.house && !addressInfo.street) {
      this.setValue(
        'street',
        this.translate.instant('shared.address.input.message.no_street'),
        true
      );
    } else {
      this.setValue('street', addressInfo.street, true);
    }

    this.setValue('rawAddress', addressString, true);

    this.selectAddress.emit({
      address: this.addressFormatter.formatAddressInfo(addressInfo),
      entrance
    });
  }

  private setValue(name: keyof AddressInfo | 'rawAddress', value: string | Country, touched?: boolean) {
    const control = this.getControl(name);

    if (touched) {
      control.markAsTouched();
    }

    control.setValue(value);
  }

  private getControl(name: keyof AddressInfo | 'rawAddress'): AbstractControl {
    return this.controlContainer.control.get(name as string);
  }
}
