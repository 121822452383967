import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ResourcePath, ResourcesHelper} from '@app/shared/entities';

import {
  PaymentsRegistrationChecklistStep,
  PaymentsWizardService,
  PaymentsWizardStep,
  PaymentsWizardStepper
} from '../../models';
import { PaymentsWizardStore } from '../../store';
import {
  PaymentsWizardEntityType
} from '@app/views/payments/components/popups/payments-wizard/models/payments-wizard-entity-type';

@Component({
  selector: 'app-payments-wizard-registration',
  templateUrl: './payments-wizard-registration.component.html',
  styleUrls: ['./payments-wizard-registration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentsWizardRegistrationComponent {
  @Input() public step: PaymentsWizardStepper;

  public readonly checklistStep: typeof PaymentsRegistrationChecklistStep =
    PaymentsRegistrationChecklistStep;
  public readonly entityType: typeof PaymentsWizardEntityType = PaymentsWizardEntityType;
  public readonly serviceType: typeof PaymentsWizardService = PaymentsWizardService;

  public readonly mobileApplicationLink: string =
    'https://play.google.com/store/apps/details?id=com.rosdomofon.rdua';

  public readonly resourcePath: typeof ResourcePath = ResourcePath;
  public selectedCheckpoint = 0;
  public selectedEntityType: PaymentsWizardEntityType = PaymentsWizardEntityType.INDIVIDUAL;
  public service: PaymentsWizardService;

  @Output() private skipStep: EventEmitter<void> = new EventEmitter();

  constructor(
    public resourcesHelper: ResourcesHelper,
    private store: PaymentsWizardStore
  ) {
    store.service$.subscribe((service) => {
      this.service = service;
      this.selectedCheckpoint = this.service === PaymentsWizardService.ROBOKASSA
        ? PaymentsRegistrationChecklistStep.ENTITY
        : PaymentsRegistrationChecklistStep.COMPANY_REGISTRATION;
    });
  }

  public onNextCheckpoint(): void {
    this.selectedCheckpoint++;

    if (
      this.selectedCheckpoint >
      PaymentsRegistrationChecklistStep.CONCLUSION_AGREEMENT
    ) {
      this.completeStep();
    }
  }

  public onSkipStep(): void {
    this.completeStep();

    setTimeout(() => this.skipStep.emit(), 0);
  }

  public onEntityType(entityType: PaymentsWizardEntityType): void {
    this.selectedEntityType = entityType;
    this.selectedCheckpoint = PaymentsRegistrationChecklistStep.COMPANY_REGISTRATION;
  }

  private completeStep(): void {
    this.store.updateStepState({
      step: PaymentsWizardStep.REGISTRATION,
      stepState: { complete: true }
    });

    this.store.updateStepState({
      step: PaymentsWizardStep.INTEGRATION,
      stepState: { disabled: false, active: true }
    });
  }

  public get youtubeLink(): string {
    switch (this.service) {
      case PaymentsWizardService.YOOKASSA:
        return 'https://www.youtube.com/embed/NXxVxuUtEnE';
      case PaymentsWizardService.ROBOKASSA:
        switch (this.selectedCheckpoint) {
          case PaymentsRegistrationChecklistStep.COMPANY_REGISTRATION:
            return this.isEntityLegal ? 'https://www.youtube.com/embed/pnG6FNB2m_I' : 'https://www.youtube.com/embed/v6IftOgaWRs';
          case PaymentsRegistrationChecklistStep.CONFIG_CASHBOX:
            return 'https://www.youtube.com/embed/gqvXyuhff9M';
          case PaymentsRegistrationChecklistStep.CONCLUSION_AGREEMENT:
            return this.isEntityLegal ? 'https://www.youtube.com/embed/NFFs3bXcGXE' : 'https://www.youtube.com/embed/o2BYYzdOg18';
        }
        break;
    }
  }

  public get isShowYoutubeLink(): boolean {
    if (this.service === PaymentsWizardService.YOOKASSA) {
      return true;
    }
    return this.selectedCheckpoint > PaymentsRegistrationChecklistStep.ENTITY &&
      this.selectedCheckpoint <= PaymentsRegistrationChecklistStep.CONCLUSION_AGREEMENT;
  }

  public get isEntityLegal(): boolean {
    return this.selectedEntityType === PaymentsWizardEntityType.LEGAL;
  }
}
