import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Observable } from 'rxjs';

import { ResourcePath } from '@app/shared/entities';
import {
  Account,
  ConnectionCreateRequest,
  CreateAbonentAndGetAccountsResponse,
  ServiceInfoResponse,
  ServiceResponse
} from '@app/shared/entities/rd';
import { Dictionary } from '@app/shared/helpers';
import { Abonent, Address } from '@app/shared/models';
import { AccountSelectionType } from '../account-selection';
import {
  AbonentConnectionToolData,
  AbonentConnectionToolPageMode,
  ServiceSelectedArgs
} from './models';
import { AbonentConnectionToolStore } from './store';
import { ServiceEntranceFlats } from '@app/views/services/components';

@Component({
  selector: 'app-abonent-connection-tool',
  templateUrl: './abonent-connection-tool.component.html',
  styleUrls: ['./abonent-connection-tool.component.scss'],
  providers: [AbonentConnectionToolStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbonentConnectionToolComponent implements OnInit {
  readonly resourcePath: typeof ResourcePath = ResourcePath;
  readonly pageMode: typeof AbonentConnectionToolPageMode =
    AbonentConnectionToolPageMode;

  @Input() loading: boolean;
  @Input() phone: number;
  @Input() flatNumber: number;
  @Input() flatId: number;
  @Input() flatList: Dictionary<ServiceEntranceFlats>;
  @Input() addresses: Address[];
  @Input() hasPhysicalTubeDefault: boolean;
  @Input() hasPhysicalTubeHidden: boolean;
  @Input() virtualFlat: boolean;
  @Input() servicesSelectionRequired: boolean;
  @Input() noPhone: boolean;
  @Input() abonentAndAccountLoadingForced: boolean;
  @Input() defaultService: Pick<ServiceResponse, 'id' | 'type'>;
  services$: Observable<ServiceInfoResponse[]> = this.store.services$;
  servicesLoading$: Observable<boolean> = this.store.servicesLoading$;
  abonentDataDisabled$: Observable<boolean> = this.store.abonentDataDisabled$;
  pageMode$: Observable<AbonentConnectionToolPageMode> = this.store.pageMode$;
  abonentAndAccountsResponse$: Observable<CreateAbonentAndGetAccountsResponse> =
    this.store.abonentAndAccountsResponse$;
  selectedServices$: Observable<Pick<ServiceResponse, 'id' | 'type'>[]> =
    this.store.selectedServices$;
  connectedServices$: Observable<Dictionary<boolean>> =
    this.store.connectedServices$;
  servicesForDelete$: Observable<Pick<ServiceResponse, 'id' | 'type'>[]> =
    this.store.servicesForDelete$;

  @Output() private submitConnections: EventEmitter<{
    request: Partial<ConnectionCreateRequest>;
  }> = new EventEmitter();
  private request: Partial<ConnectionCreateRequest> = {};
  private services: Pick<ServiceResponse, 'id' | 'type'>[] = [];

  constructor(private store: AbonentConnectionToolStore) {}

  ngOnInit() {
    this.request.phone = this.phone;
    this.request.flatId = this.flatId;
    this.request.flatNumber = this.flatNumber;

    this.store.updatePageMode(AbonentConnectionToolPageMode.CONNECTION);

    this.store.addAbonentAccountsListener(
      (response: CreateAbonentAndGetAccountsResponse) => {
        if (response?.accounts?.length === 0) {
          this.submitConnections.emit({ request: this.request });
        } else {
          this.store.updateServicesLoading(false);
          this.store.updatePageMode(
            AbonentConnectionToolPageMode.ACCOUNT_SELECTION
          );
        }
      }
    );
  }

  getEntranceId(): number {
    return this.request.entranceId;
  }

  onChangeServicesSelection(event: Pick<ServiceResponse, 'id' | 'type'>[]) {
    this.store.updateSelectedServices(event);
    this.store.updateServiceForDelete(event);
  }

  onTubeAvailabilityChange(hasPhysicalTube: boolean) {
    this.hasPhysicalTubeDefault = hasPhysicalTube;
  }

  onAbonentDataEntered(entranceId: number, flatNumber: number, phone: number) {
    this.request.entranceId = entranceId;
    this.request.flatNumber = flatNumber;
    this.request.phone = phone;

    this.store.getFlatServices({
      entranceId,
      flatId: this.flatId,
      hasPhysicalTube: this.hasPhysicalTubeDefault
    });
  }

  onSubmitConnection(data: AbonentConnectionToolData) {
    this.request.entranceId = data.entranceId;
    this.services = data.services;

    this.request.phone = data.phone;
    this.request.withoutPhoneNumber = data.withoutPhoneNumber;

    this.request.virtual = !data.hasPhysicalTube;

    this.request.servicesForConnect = data.services;
    this.request.servicesForDelete = data.servicesForDelete;
    if (data.loadAbonentAndAccounts && !data.withoutPhoneNumber) {
      this.store.createAbonentAndGetAccounts({ phone: data.phone });
      return;
    }

    this.submitConnections.emit({ request: this.request });
  }

  onAccountSelected(
    abonent: Abonent,
    account: Account,
    type: AccountSelectionType
  ) {
    if (type === AccountSelectionType.NEW_ACCOUNT) {
      this.request.account = null;
    } else {
      this.request.account = account;
      this.request.abonent = abonent;
    }

    this.request.servicesForConnect = this.services;

    this.submitConnections.emit({ request: this.request });
  }

  onSkipSelected(): void {
    this.request.servicesForConnect = this.services;
    this.submitConnections.emit({ request: this.request });
  }
}
