import { Component, Inject, Injector, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from '@app/shared/entities/common/loader';
import { EditPopupComponentData } from '@app/shared/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PopupBasicComponent } from '../popup-basic/popup-basic.component';

@Component({
  selector: 'app-edit-popup',
  templateUrl: './edit-popup.component.html',
  styleUrls: ['./edit-popup.component.scss']
})
export class EditPopupComponent implements OnDestroy {
  outlet: PopupBasicComponent;
  dataInjector: Injector;
  loading: boolean;
  private subject: Subject<any>;
  private onDestroy$: Subject<void>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditPopupComponentData,
    private injector: Injector,
    private loaderService: LoaderService
  ) {
    this.onDestroy$ = new Subject<void>();
    this.initComponentOutlet();
    this.initLoaderListener();
  }

  ngOnDestroy() {
    this.destroySubject();
  }

  private initComponentOutlet() {
    this.subject = new Subject();
    this.subject.asObservable().subscribe(resp => {
      if (resp) {
        this.loaderService.loaderState = { state: true, type: 'component' };
        this.data.submit(resp);
      }
    });

    this.outlet = this.data.component;
    this.dataInjector = Injector.create({
      providers: [
        { provide: 'data', useValue: this.data.providedData },
        { provide: 'subject', useValue: this.subject }
      ],
      parent: this.injector
    });
  }

  private destroySubject() {
    this.subject.next();
    this.subject.complete();
  }

  private initLoaderListener() {
    this.loaderService.getComponentLoaderState()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(state => this.loading = state);
  }
}
