import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MediaInfo } from "../models/video.models";

@Injectable({ providedIn: 'root' })
export class MediaService {
  private mediaInfoListSubject = new BehaviorSubject<MediaInfo[]>([]);
  mediaInfoList$ = this.mediaInfoListSubject.asObservable();

  addMedia(value: MediaInfo): void {
    const currentValue = this.mediaInfoListSubject.value;
    this.mediaInfoListSubject.next([...currentValue, value])
  }

  removeMedia(id: number): void {
    const filteredStreamList = this.mediaInfoListSubject.value
      .filter(value => value.id !== id)

    this.mediaInfoListSubject.next(filteredStreamList)
  }

  destroy(): void {
    this.mediaInfoListSubject.next([])
  }
}
