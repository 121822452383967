import { ActionReducerMap } from '@ngrx/store';
import { VideoPlayerActions, VideoPlayerActionTypes } from './video-player.actions';
import { videoPlayerInitialState, VideoPlayerModuleState, VideoPlayerState } from './video-player.state';
import { VideoshotPopupState } from '../models';

function reducer(state = videoPlayerInitialState, action: VideoPlayerActions): VideoPlayerState {
    switch (action.type) {
        case VideoPlayerActionTypes.VideoPlayerGetPlaylists: {
            return {
                ...state
            };
        }

        case VideoPlayerActionTypes.VideoPlayerGetPlaylistsSuccess: {
            return {
                ...state,
                playlists: action.response
            };
        }

        case VideoPlayerActionTypes.VideoPlayerGetPlaylistsFailure: {
            return {
                ...state
            };
        }

        case VideoPlayerActionTypes.VideoPlayerCreateVideoshotTaskSuccess: {
            return {
                ...state,
                videoshotPopupState: VideoshotPopupState.DOWNLOAD
            };
        }

        case VideoPlayerActionTypes.VideoPlayerGetVideoshot: {
            return {
                ...state,
                videoshotError: null
            };
        }

        case VideoPlayerActionTypes.VideoPlayerGetVideoshotSuccess: {
            return {
                ...state,
                videoshotError: null
            };
        }

        case VideoPlayerActionTypes.VideoPlayerGetVideoshotFailure: {
            return {
                ...state,
                videoshotError: action.error
            };
        }

        case VideoPlayerActionTypes.VideoPlayerInitVideoshotState: {
            return {
                ...state,
                videoshotPopupState: VideoshotPopupState.CREATE
            };
        }

        case VideoPlayerActionTypes.VideoPlayerChangeVideoshotState: {
            return {
                ...state, 
                videoshotPopupState: action.newState
            }
        }

        case VideoPlayerActionTypes.VideoPlayerSelectCurrentSource: {
            return {
                ...state,
                currentSource: action.source
            };
        }

        case VideoPlayerActionTypes.VideoPlayerClearState: {
            return videoPlayerInitialState;
        }

        case VideoPlayerActionTypes.SaveVideoshotTimeData: {
            return {
                ...state, 
                videoshotTimeData: action.timeData
            }
        }

        case VideoPlayerActionTypes.GetVideoshotPopupPlaylists: {
            return {
                ...state
            };
        }

        case VideoPlayerActionTypes.GetVideoshotPopupPlaylistsSuccess: {
            return {
                ...state,
                videoshotPopupPlaylistsData: action.response
            };
        }

        case VideoPlayerActionTypes.GetVideoshotPopupPlaylistsFailure: {
            return {
                ...state,
                isErrorOccurred: action.isErrorOccurred
            };
        }


        default: {
            return {
                ...state
            };
        }
    }
}

export const videoPlayerReducer: ActionReducerMap<VideoPlayerModuleState> = {
    videoPlayer: reducer
};
