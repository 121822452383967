import { Subject } from "rxjs";
import { PeerConnectionState } from "./state.model";

export enum MessageType {
  offer = 'offer',
  answer = 'answer',
  candidate = 'candidate',
}

export interface DescriptionResult {
  success: boolean;
  description?: RTCSessionDescription | null;
}

export interface IMediaStreamInfo {
  id: number;
  mediaStream: MediaStream;
}

export interface PeerConnectionInfo {
  id: number;
  peerConnection: RTCPeerConnection;
  state$: Subject<PeerConnectionState>
}