import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { RdeaColorType } from '@app/shared/models';

@Component({
  selector: 'app-text-badge',
  templateUrl: './text-badge.component.html',
  styleUrls: ['./text-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextBadgeComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input()
  @HostBinding('attr.color')
  color: RdeaColorType = 'primary';
}
