import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Converter } from '../../helpers/converter';
import { PlayerService } from '../../service/player.service';

@Component({
  selector: 'rd-video-timeline-slider',
  templateUrl: './timeline-slider.html',
  styleUrls: ['./timeline-slider.scss']
})
export class RdVideoTimelineSliderComponent {
  @Input() timelineElementRef: HTMLDivElement;
  @Output() changePosition = new EventEmitter<number>();
  @Output() eventMouseUp = new EventEmitter<MouseEvent>();
  
  sliderPosition$ = this.playerService.sliderPosition$

  constructor(private playerService: PlayerService) { }

  onSliderDragStart(): void {
    this.playerService.updateSliderIsDragging(true);
  }

  @HostListener('document:mouseup', ['$event'])
  onSliderDragEnd(event: MouseEvent): void {
    if (this.playerService.getSliderIsDragging()) {
      this.updateSliderPosition(event);
      this.eventMouseUp.emit(event)
      this.playerService.updateSliderIsDragging(false);
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onSliderMove(event: MouseEvent): void {
    this.updateSliderPosition(event);
  }

  private updateSliderPosition(event: MouseEvent): void {
    if (!this.playerService.getSliderIsDragging()) {
      return;
    }

    const timelineRect = this.timelineElementRef.getBoundingClientRect();
    const offsetX = event.clientX - timelineRect.left;

    if (offsetX > 0 && offsetX < timelineRect.width) {
      const percentage = Converter.pixelToPercentage(offsetX, timelineRect.width);
      this.playerService.updateSliderPosition(percentage);
      this.changePosition.emit(percentage);
    }

    if (offsetX < 0) {
      this.playerService.updateSliderPosition(0);
      this.changePosition.emit(0);
    }

    if (offsetX > timelineRect.width) {
      this.playerService.updateSliderPosition(100);
      this.changePosition.emit(100);
    }
  }
}