<ng-container [formGroup]="formGroup">
  <mat-form-field class="custom-address-form-field" appearance="outline">
    <mat-label>
      {{ label || ('shared.address.input.custom.label' | translate) }}
    </mat-label>
    <div class="custom-address-form-field-wrapper">
      <input
        type="text"
        matInput
        formControlName="rawAddress"
        class="custom-address__input"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
      />
    </div>
  </mat-form-field>

  <input formControlName="country" type="hidden" />
  <input formControlName="city" type="hidden" />
  <input formControlName="street" type="hidden" />
  <input formControlName="house" type="hidden" />
  <input formControlName="building" type="hidden" />
  <input formControlName="housing" type="hidden" />
  <input formControlName="block" type="hidden" />
  <input formControlName="fiasCode" type="hidden" />
  <input formControlName="kladrCode" type="hidden" />
  <input formControlName="universalCode" type="hidden" />
</ng-container>
