<div
  class="phone-input"
  [formGroup]="formGroup">
  <mat-form-field
    class="phone-input-prefix none-padding relative-size mat-form-field-small"
    appearance="outline">
    <mat-select
      (selectionChange)="onPrefixSelected($event)"
      formControlName="prefix">
      <mat-option
        *ngFor="let prefix of prefixesList"
        [value]="prefix.prefix">
        {{ prefix.prefix }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    class="phone-input-number none-padding relative-size mat-form-field-small"
    appearance="outline">
    <mat-label #phoneLabel>
      <ng-content select="[phone-input-label]"></ng-content>
    </mat-label>

    <mat-label *ngIf="phoneLabel.childNodes.length == 0">
      <mat-label>
        {{ 'shared.phone.input.field.number' | translate }}
      </mat-label>
    </mat-label>

    <input
      type="tel"
      id="phone"
      matInput
      formControlName="number"
      appPhoneValidation
      [prefix]="prefix"
      [placeholder]="placeholder"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      [required]="required" />
    <mat-error>
      {{ 'shared.phone.input.error.number' | translate }}
    </mat-error>

    <mat-hint #hint>
      <ng-container select="[phone-input-hint]"></ng-container>
    </mat-hint>
  </mat-form-field>
</div>
