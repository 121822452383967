<ng-container [ngSwitch]="mode$ | async">
  <ng-container *ngSwitchCase="VideoPlayerMode.LIVE_WEBRTC" [ngTemplateOutlet]="videoBlock"></ng-container>
  <ng-container *ngSwitchCase="VideoPlayerMode.LIVE_HLS" [ngTemplateOutlet]="videoBlock"></ng-container>
  <ng-container *ngSwitchCase="VideoPlayerMode.ARCHIVE" [ngTemplateOutlet]="videoBlock"></ng-container>
</ng-container>

<button
  mat-fab
  mat-dialog-close
  color="transparent"
  class="video-stream-popup__close-button"
  *ngIf="mdWDownBreakpoint$ | async"
>
  <mat-icon>close</mat-icon>
</button>
<app-component-loader
  *ngIf="loading$| async"
  [sizePx]=50
  [overTheComponent]="false"
  class="video-stream-popup-loader"
></app-component-loader>


<ng-template #videoBlock>
  <div class="video-container">
    <div class="video-wrapper">
      <rd-video [streamId]="cameraId">
        <video #video class="video-block"></video>
      </rd-video>
    </div>

    <div class="overlay-container">
      <div class="overlay-content">
        <div class="player-container">
          <rd-video-play-control class="play-pause-btn"></rd-video-play-control>
          <ng-container *ngIf="(mode$ | async) === VideoPlayerMode.ARCHIVE">
            <rd-video-event-control
              (clickPrevEvent)="onPrevEvent()"
              (clickNextEvent)="onNextEvent()"
            ></rd-video-event-control>
            <rd-video-timeline class="w-100"></rd-video-timeline>
          </ng-container>
          
        </div>
        <div class="controls">
          <ng-container *ngIf="(mode$ | async) === VideoPlayerMode.ARCHIVE">
            <rd-video-select-control 
              [icon]="'assets/icons/ic-call-answer.svg'" 
              [isStaticLabel]="true"
              [options]="eventsOptions"
              [emptyOptionTemplate]="emptyOptionEvent"
              [name]="'shared.video_player.plyr.template.button.events' | translate"
              (selectChange)="handleSelectionEvents($event)"
            ></rd-video-select-control >
            <rd-video-select-control 
              [selectValueDefault]="currentSelectPlaylist"
              [options]="dateArchiveOptions"
              (selectChange)="handleSelectionPlaylist($event)"
            ></rd-video-select-control>
            <rd-video-control 
              [name]="'shared.video_player.plyr.template.button.get_video_shot' | translate"
              (clickEvent)="openVideoShotPopup()"
            ></rd-video-control>
          </ng-container>
          <rd-video-control 
            *ngIf="isCanOpenDoor"
            [name]="'shared.video_player.plyr.template.button.open_door' | translate"
            (clickEvent)="openDoor()"
          ></rd-video-control>
          <rd-video-control 
            [name]="getModeButtonName()"
            (clickEvent)="changeMode()"
          ></rd-video-control>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #emptyOptionEvent>
  <span>{{'shared.video_player.plyr.template.message.no_events' | translate }}</span>
</ng-template>
