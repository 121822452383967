import {
  IDateFormatterHelper,
  RdeaDateCompareResult,
  RdeaDateToDateTimeStringRequest,
  RdeaDateToTimeStringOptions
} from '../models';

export class RdeaDate extends Date implements IDateFormatterHelper {
  private static readonly minuteInMilliseconds = 60 * 1000;
  public static readonly dayInMilliseconds = 24 * 60 * 60 * 1000;

  public toDateTimeString({
    time = true,
    date = true,
    utc0 = false,
    timeWithoutOneSecond = false
  }: RdeaDateToDateTimeStringRequest = {}): string {
    if (!this) {
      return '';
    }

    let timeVal: number = this.getTime();

    if (!utc0) {
      timeVal = timeVal + this.getTimezoneOffset() * 60 * 1000;
    }

    const dateUtc0 = new Date(timeVal);

    let dateString = '';

    if (date) {
      dateString = `${this.getDateString.bind(dateUtc0)()} `;
    }

    let timeString = timeWithoutOneSecond ? '23:59:59' : '00:00:00';

    if (time) {
      timeString = this.getTimeString.bind(dateUtc0)();
    }

    return `${dateString}${timeString}`;
  }

  public toDayMonthString() {
    if (!this) {
      return '';
    }

    return this.toLocaleString('ru', { day: '2-digit', month: 'long' });
  }

  public getDateString(divider: string = '-'): string {
    const year = new Intl.DateTimeFormat('en-GB', { year: 'numeric' }).format(
      this
    );
    const month = new Intl.DateTimeFormat('en-GB', { month: '2-digit' }).format(
      this
    );
    const day = new Intl.DateTimeFormat('en-GB', { day: '2-digit' }).format(
      this
    );

    return `${year}${divider}${month}${divider}${day}`;
  }

  public getTimeString({
    divider = ':',
    format = 'numeric'
  }: RdeaDateToTimeStringOptions = {}): string {
    const hours =
      '0' +
      new Intl.DateTimeFormat('en-GB', { hour: format, hour12: false }).format(
        this
      );
    const minutes: string =
      '0' + new Intl.DateTimeFormat('en-GB', { minute: format }).format(this);
    const seccods =
      '0' + new Intl.DateTimeFormat('en-GB', { second: format }).format(this);

    return `${hours.slice(-2)}${divider}${minutes.slice(
      -2
    )}${divider}${seccods.slice(-2)}`;
  }

  public getDateTimeString({
    dateDivider = '-',
    timeDivider = ':',
    partsDivider = ' '
  }: {
    dateDivider?: '-' | ':';
    timeDivider?: '-' | ':';
    partsDivider?: '-' | ' ';
  } = {}): string {
    const dateTimeFormat = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false
    });
    const [
      { value: month },
      {},
      { value: day },
      {},
      { value: year },
      {},
      { value: hour },
      {},
      { value: minute },
      {},
      { value: second }
    ] = dateTimeFormat.formatToParts(this);

    return `${year}${dateDivider}${month}${dateDivider}${day}${partsDivider}${hour}${timeDivider}${minute}${timeDivider}${second}`;
  }

  public getTimezoneName(): string {
    const currentDate: Date = new Date();
    const dateStringShort: string = currentDate.toLocaleDateString(undefined);
    const dateStringLong: string = currentDate.toLocaleDateString(undefined, {
      timeZoneName: 'long'
    });

    const containingIndex: number = dateStringLong.indexOf(dateStringShort);

    if (containingIndex >= 0) {
      const trimmed: string =
        dateStringLong.substring(0, containingIndex) +
        dateStringLong.substring(containingIndex + dateStringShort.length);

      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
    }

    return dateStringLong;
  }

  public static compareDates(
    date1: RdeaDate,
    date2: RdeaDate,
    options?: { until: 'milliseconds' | 'minutes' }
  ): RdeaDateCompareResult {
    if (options?.until === 'minutes') {
      const dates: string[] = [];

      for (const date of [date1, date2]) {
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
          date
        );

        let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
          date
        );

        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

        let ho = new Intl.DateTimeFormat('en', { hour: '2-digit' }).format(
          date
        );

        let min = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(
          date
        );

        dates.push(`${ye}${mo}${da}${ho}${min}`);
      }

      return dates[0] > dates[1] ? 1 : dates[0] < dates[1] ? -1 : 0;
    }

    const time1: number = date1.getTime();
    const time2: number = date2.getTime();

    return time1 > time2 ? 1 : time1 < time2 ? -1 : 0;
  }

  public static toHHMM(hours: number, minutes: number): string {
    let preparedHours: string = '--';
    let preparedMinutes: string = '--';

    if (hours !== null && hours !== undefined && isFinite(hours)) {
      preparedHours = hours < 10 ? '0' + hours : `${hours}`;
    }

    if (minutes !== null && minutes !== undefined && isFinite(minutes)) {
      preparedMinutes = minutes < 10 ? '0' + minutes : `${minutes}`;
    }

    return `${preparedHours}:${preparedMinutes}`;
  }

  /**
   * finds the difference only between two dates
   * @param smallDate 
   * @param bigDate 
   * @returns dates difference in seconds, type number
   */
  public static getDifferenceBetweenDates(smallDate: Date, bigDate: Date): number {
    const [smallTimestamp, bigTimestamp] = [smallDate.getTime(), bigDate.getTime()];
    return (bigTimestamp - smallTimestamp) / 1000;
  }
}
