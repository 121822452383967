import { Injectable } from "@angular/core";
import { TZ_OFFSET_IN_MILLISECONDS, VideoPlayerFragmentShortDate } from "@app/shared/components/video-player";
import { BehaviorSubject } from "rxjs";
import { RangeDateTimeline } from "../models/timeline.model";

@Injectable({ providedIn: 'root' })

export class MediaPeriodService {
  convertTimeToTimestamp(time: number, mediaPeriodItems: VideoPlayerFragmentShortDate[]): number {
    return mediaPeriodItems.reduce((acc, currentValue, index) => {
      const findResult = mediaPeriodItems.find(mediaPeriod => acc >= mediaPeriod.startTimestamp && acc <= mediaPeriod.endTimestamp)

      if (findResult) {
        return acc;
      }

      const timeSum = currentValue.startTimestamp + (acc === 0 ? time * 1000 : acc)

      if((timeSum - currentValue.endTimestamp) < 200){
        return timeSum;
      }

      if (timeSum >= currentValue.endTimestamp) {
        return timeSum - currentValue.endTimestamp;
      } else {
        return timeSum
      }
    }, 0)
  }

  calculatePercentage(currentTime: number, startTime: number, endTime: number): number {
    if (endTime === startTime) {
      return 0;
    }

    const percentage = ((currentTime - startTime) / (endTime - startTime)) * 100;
    return percentage;
  }

  calculateMsPreviewMediaPeriod(index: number, mediaPeriodItems: VideoPlayerFragmentShortDate[]): number {
    const mediaPeriodItemsFiltered = mediaPeriodItems
      .filter((mediaPeriod, i) => i < index)

    const secondList = mediaPeriodItemsFiltered
      .map(mediaPeriod => mediaPeriod.endTimestamp - mediaPeriod.startTimestamp);

    return secondList.reduce((acc, currentValue) => acc + currentValue, 0);
  }

  calculateMsDifferenceMediaPeriod(timestamp: number, index: number, mediaPeriodItems: VideoPlayerFragmentShortDate[]): number {
    const dateOffset = timestamp + TZ_OFFSET_IN_MILLISECONDS;

    return dateOffset - mediaPeriodItems[index].startTimestamp;
  }

  findSelectedMediaPeriodIndex(timestamp: number, mediaPeriodItems: VideoPlayerFragmentShortDate[]): number {
    return mediaPeriodItems
      .findIndex((mediaPeriod) => {
        const dateOffset = timestamp + TZ_OFFSET_IN_MILLISECONDS
        return mediaPeriod.startTimestamp <= dateOffset && mediaPeriod.endTimestamp >= dateOffset
      })
  }

  findClosestMediaPeriod(timestamp: number, mediaPeriodItems: VideoPlayerFragmentShortDate[]): VideoPlayerFragmentShortDate | null {
    const timestampOffset = timestamp + TZ_OFFSET_IN_MILLISECONDS;

    return mediaPeriodItems.reduce((closest, current, index) => {
      const next = mediaPeriodItems[index + 1]

      if (timestampOffset <= closest.startTimestamp) {
        return closest
      }

      if (timestampOffset <= current.startTimestamp) {
        return Math.abs(timestampOffset - closest.endTimestamp) < Math.abs(timestampOffset - current.startTimestamp)
          ? closest
          : current
      }

      return current;
    });
  }

  calculateSecondForPlayer(timestamp: number, mediaPeriodItems: VideoPlayerFragmentShortDate[]): number {
    const selectedMediaPeriodItemIndex = this.findSelectedMediaPeriodIndex(timestamp, mediaPeriodItems);

    if (selectedMediaPeriodItemIndex === -1) {
      const closesMediaPeriod = this.findClosestMediaPeriod(timestamp, mediaPeriodItems)
      const second = this.calculateOffsetSecondByTimestamp(timestamp, closesMediaPeriod)
      return second;
    }

    const msPreviewMediaPeriod = this.calculateMsPreviewMediaPeriod(selectedMediaPeriodItemIndex, mediaPeriodItems);
    const msDifference = this.calculateMsDifferenceMediaPeriod(timestamp, selectedMediaPeriodItemIndex, mediaPeriodItems);

    return (msPreviewMediaPeriod + msDifference) / 1000;
  }

  calculateOffsetSecondByTimestamp(timestamp: number, mediaPeriod: VideoPlayerFragmentShortDate): number {
    const timestampOffset = timestamp + TZ_OFFSET_IN_MILLISECONDS;
    return Math.abs(timestampOffset - mediaPeriod.startTimestamp) < Math.abs(timestampOffset - mediaPeriod.endTimestamp)
      ? mediaPeriod.startPlayerTime
      : (mediaPeriod.endTimestamp - mediaPeriod.startTimestamp) / 1000 + mediaPeriod.startPlayerTime
  }

  isTimestampInMediaPeriods(timestamp: number, mediaPeriodItems: VideoPlayerFragmentShortDate[]): boolean {
    const selectedMediaPeriodItemIndex = this.findSelectedMediaPeriodIndex(timestamp, mediaPeriodItems);

    if(selectedMediaPeriodItemIndex === -1){
      return false;
    }

    return true;
  }

  getRangeDateTimeline(selectedDate: number, mediaPeriodStartDate: number): RangeDateTimeline {
    const mediaPeriodStartDateOffset = mediaPeriodStartDate - TZ_OFFSET_IN_MILLISECONDS;
    
    const selectedDateOffset = new Date(selectedDate);
    selectedDateOffset.setHours(0, 0, 0, 0)
    
    const startTime = selectedDateOffset.getTime() < mediaPeriodStartDateOffset ? selectedDateOffset : mediaPeriodStartDateOffset;
    const endTime = new Date(selectedDateOffset.getTime() + 24 * 60 * 60 * 999.99);
    
    return { startTime: new Date(startTime) , endTime };
  }
}