import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VideoshotPopupState } from '@app/shared/components/video-player/models';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { Observable, Subject } from 'rxjs';
import { DurationString } from '../duration-input/duration-input.models';
import * as moment from 'moment';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-video-player-videoshot-container',
  templateUrl: './video-player-videoshot-container.component.html',
  styleUrls: ['./video-player-videoshot-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlayerVideoshotContainerComponent implements OnInit, OnDestroy {
  mdWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.MD_W_DOWN);
  readonly minTimeDuration: DurationString = '00:01';
  readonly maxTimeDuration: DurationString = '30:00';
  private readonly defaultDurationValue: DurationString = '05:00';
  private readonly defaultDurationInSeconds: number = 299;
  private _minDate: Date;
  private readonly onDestroy$ = new Subject();

  form: FormGroup = new FormGroup({
    date: new FormControl(null, Validators.required),
    duration: new FormControl(this.defaultDurationValue)
  });

  private _loading: boolean;

  @Input() 
  set loading(loading: boolean) {
    this._loading = loading;
    this.form?.[loading ? 'disable' : 'enable']();
  }
  get loading(): boolean { return this._loading }

  @Input() set minDate(value: Date) {
    if (value) {
      this._minDate = value;
      this.form.patchValue({date: this._minDate})
    }
  }

  get minDate() {return this._minDate};
  
  @Input() maxDate: Date;
  @Input() depthInHours!: number;
  @Input() state: VideoshotPopupState;
  
  @Output() private createVideoshotTask: EventEmitter<{ date: Date, duration: number }> = new EventEmitter();
  @Output() private downloadVideoshot: EventEmitter<void> = new EventEmitter();
  @Output() private initVideoshotBuilding: EventEmitter<void> = new EventEmitter();

  constructor(
    private resolution: ResolutionService
  ) { }

  ngOnInit(): void {
    this.addDateControlListener();
  }

  onCreateVideoshotTask() {
    const { date, duration } = this.form.getRawValue();

    this.createVideoshotTask.emit({
      date: date instanceof Date ? date : date.toDate(),
      duration: this.convertDuration(duration)
    })
  }

  onDownloadVideoshot() {
    this.downloadVideoshot.emit();
  }

  onInitVideoshotBuilding() {
    this.initVideoshotBuilding.emit();
  }
  
  ngOnDestroy(): void {
    this.onDestroy$.next('');
    this.onDestroy$.complete();
  }

  private addDateControlListener(): void {
    this.dateControl.valueChanges.pipe(
      debounceTime(300),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      if (this.dateControl.touched && this.dateControl.dirty) {
        this.durationControl.markAsTouched();
        this.durationControl.updateValueAndValidity();
      }
    });
  }
  
  /**
   * convert duration string mm:ss to seconds
   * @param duration convert duration string mm:ss to seconds
   * @returns duration in seconds
   */
  private convertDuration(duration: string): number {
    if (duration === null) {
      return this.defaultDurationInSeconds;
    }

    const splittedValues: string[] = duration.split('');
    const minutes = Number.parseInt(splittedValues[0] + splittedValues[1], 10);
    const seconds = Number.parseInt(splittedValues[2] + splittedValues[3], 10);

    return (minutes * 60) + seconds;
  }

  get maxMinutes() { return this.maxTimeDuration.split(':')[0] };
  get maxSeconds() { return this.maxTimeDuration.split(':')[1] };
  
  get durationControl() { return this.form.get('duration') };
  get dateControl() { return this.form.get('date')};

  get minDateMomentFormat() { return moment(this.minDate) }
  get maxDateMomentFormat() { return moment(this.maxDate) }
}
