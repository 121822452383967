import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, ControlContainer, FormGroup} from '@angular/forms';
import {KzPostAddressPart, KzPostData, KzPostResponse} from '@app/shared/entities/integrations/kz-post';
import {AddressInfo, Country} from '@app/shared/models';
import {AddressFormatter} from '@app/shared/services';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {KzPostAddressHelper} from './kz-post-address.helper';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-kz-post-address',
  templateUrl: './kz-post-address.component.html',
  styleUrls: ['./kz-post-address.component.scss'],
  providers: [KzPostAddressHelper]
})
export class KzPostAddressComponent implements OnInit, OnDestroy {
  private readonly districtTypes = ['A3', 'A5', 'A7', 'A10'];

  @Input() label: string;
  @Input() country;
  @Input() initialAddress: AddressInfo;

  options: KzPostData[];

  private onDestroy$: Subject<void> = new Subject();
  @Output() private submittedAddress: EventEmitter<{ address: string }> = new EventEmitter();
  @Output() private getAddressError: EventEmitter<{ error: string }> = new EventEmitter();

  constructor(
    public controlContainer: ControlContainer,
    private kzPostAddressHelper: KzPostAddressHelper,
    private addressFormatter: AddressFormatter,
    private translate: TranslateService
  ) { }

  get formGroup(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  ngOnInit() {
    this.initFilterInput();
    this.prepareControlls();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getError(): string {
    const control = this.getControl('rawAddress');

    if (control.valid) {
      return null;
    }

    if (control.hasError('city')) {
      return this.translate.instant('shared.address.input.message.specify_city');
    }

    if (control.hasError('street')) {
      return this.translate.instant('shared.address.input.message.specify_street');
    }

    if (control.hasError('house')) {
      return this.translate.instant('shared.address.input.message.specify_house');
    }

    return null;
  }

  onOptionSelected(idx: number) {
    this.selectAddress(this.options[idx]);
    const addressInfo: AddressInfo = this.getAddressInfoFromControls();
    const address: string = this.addressFormatter.formatAddressInfo(addressInfo);
    this.getControl('rawAddress').setValue(address);

    this.submittedAddress.emit({ address });
  }

  private prepareControlls() {
    if (!this.initialAddress) {
      return;
    }

    this.setValue('country', this.initialAddress.country);
    this.setValue('city', this.initialAddress.city);
    this.setValue('street', this.initialAddress.street);
    this.setValue('house', this.initialAddress.house);
    this.setValue('block', this.initialAddress.block);
    this.setValue('housing', this.initialAddress.housing);
    this.setValue('universalCode', this.initialAddress.universalCode);
    this.setValue('rawAddress', this.addressFormatter.formatAddressInfo(this.initialAddress));
    this.getControl('rawAddress').disable();
  }

  private initFilterInput() {
    if (this.getControl('rawAddress').value) {
      this.getControl('rawAddress').disable();
      return;
    }

    this.getControl('rawAddress').valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe((value: string) => {
        if (value && !this.getControl('rawAddress').disabled) {
          this.kzPostAddressHelper.getAddress(
            value,
            (success: boolean, response: KzPostResponse, error: string) => {
              if (!success) {
                this.getAddressError.emit({ error });
                return;
              }

              this.options = response.data;
            }
          );
        } else {
          this.options = [];
        }
      });
  }

  private selectAddress(data: KzPostData) {
    this.setValue('country', this.country, true);
    this.setValue('house', data.fullAddress.number, true);
    this.setValue('block', data.fullAddress.additional, true);
    this.setValue('housing', data.fullAddress.corpusNumber, true);

    const parts: KzPostAddressPart[] = data.fullAddress.parts.filter(part => this.districtTypes.indexOf(part.type.id) === -1);

    const allIds: string[] = parts.map(part => part.id);
    const cityPart: KzPostAddressPart = parts.find(part => part.parentId !== null && allIds.indexOf(part.parentId) === -1);
    this.setValue(
      'city',
      cityPart
        ? cityPart.nameRus
        : this.translate.instant('shared.address.input.message.no_city')
      ,
      true
    );

    const parentIds = parts.map(part => part && part.parentId);
    const streetPart = parts.find(part => part && parentIds.indexOf(part.id) === -1);

    if (cityPart && (cityPart.id !== streetPart.id)) {
      this.setValue('street', streetPart.nameRus, true);
      this.setValue('universalCode', streetPart.id, true);
    } else {
      this.setValue(
        'street',
        this.translate.instant('shared.address.input.message.no_street'),
        true
      );
      this.setValue('universalCode', null, true);
    }

    const addressInfo: AddressInfo = this.getAddressInfoFromControls();
    this.setValue('rawAddress', this.addressFormatter.formatAddressInfo(addressInfo), true);
  }

  private getAddressInfoFromControls(): AddressInfo {
    return {
      country: { name: null, shortName: null },
      city: this.getControl('city').value,
      street: this.getControl('street').value,
      house: this.getControl('house').value,
      building: null,
      housing: this.getControl('housing').value,
      block: this.getControl('block').value,
      fiasCode: null,
      kladrCode: null,
      universalCode: this.getControl('universalCode').value,
    };
  }

  private setValue(name: keyof AddressInfo | 'rawAddress', value: string | Country, touched?: boolean) {
    const control = this.getControl(name);

    if (touched) {
      control.markAsTouched();
    }

    control.setValue(value);
  }

  private getControl(name: keyof AddressInfo | 'rawAddress'): AbstractControl {
    return this.controlContainer.control.get(name as string);
  }
}
