<div class="apartment-video-change-tariff__wrapper">
  <div *ngIf="showLoading" class="apartment-video-change-tariff__content__loader__wrapper">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div class="apartment-video-change-tariff__content apartment-video-change-tariff__content__wrapper">
    <div *ngIf="loaded" [formGroup]="modalForm" class="content__form__wrapper">
      <div class="content__form__field__wrapper">
        <p-inputNumber 
          inputId="tariff" 
          class="content__form__field--item"
          formControlName="tariff" 
          mode="decimal" 
          placeholder="0.00" 
          [minFractionDigits]="2"
          [maxFractionDigits]="2"
          [useGrouping]="false"
          (onInput)="onInputChange($event)"
        >
        </p-inputNumber>
      </div>
    </div>
  </div>
  <div class="apartment-video-change-tariff__footer__wrapper">
    <p-button 
      [label]="'apartments_video_surveillance.popup.change' | translate"
      [styleClass]="modalForm.valid ? '' : 'p-button-secondary'" 
      class="apartment-video-change-tariff__footer--fw-action"
      (click)="onSaveAction()"
    >
    </p-button>
  </div>
</div>
