import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';

import { AbonentsFromEntrancesResponse } from '@app/shared/components';
import {
  ConnectionService,
  FlatApiService,
  ServiceHelperService,
  ServicesList
} from '@app/shared/entities/rd';
import { Constants, Dictionary } from '@app/shared/helpers';
import { Address, ServicesTypes } from '@app/shared/models';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData, DialogWrapperSize } from '@app/shared/ui';
import {
  ServiceAbonentsDisconnectPopupBody,
  ServiceAbonentsDisconnectPopupComponent,
  ServiceAbonentsFromEntrancesPopupComponent,
  ServiceAbonentsFromEntrancesPopupData,
  ServiceConnectionBlockPopupBody,
  ServiceConnectionBlockPopupComponent,
  ServiceConnectionDeletePopupBody,
  ServiceConnectionDeletePopupComponent,
  ServiceConnectionPopupBody,
  ServiceConnectionPopupComponent,
  ServiceConnectionPopupResponse,
  ServiceDelegationPopupComponent,
  ServiceDelegationPopupData,
  ServiceFlatDeletePopupBody,
  ServiceFlatDeletePopupComponent
} from '@app/views/services/components/popups';
import { ServiceBlockRequest } from '@app/views/services/models';
import { ServiceFacade } from '@app/views/services/store';
import {
  ServiceEntrancesFlats,
  ServiceFlat,
  ServiceFlatsAddConnectionData,
  ServiceFlatsFilters
} from './models';
import {
  ServiceManagePopupComponent,
  ServiceManagePopupModel
} from '@app/views/services/components/popups/connections/service-manage-popup';
import {
  ServiceTariffPopupComponent,
  ServiceTariffPopupModel
} from '../../popups/connections/service-tariff-popup';
import { ServicesHelper } from '@app/views/services';
import { PhonePipe } from '@app/shared/pipes';
import {
  MatBottomSheet,
  MatBottomSheetRef
} from '@angular/material/bottom-sheet';
import { FlatFilterBottomSheetComponent } from '../../popups/flat-filter/flat-filter-bottom-sheet/flat-filter-bottom-sheet.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-service-flats',
  templateUrl: './service-flats.component.html',
  styleUrls: ['./service-flats.component.scss'],
  providers: [ConnectionService, PhonePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceFlatsComponent implements OnInit, OnDestroy {
  public mobileTariffState = false;
  public filterRowState = false;
  public selectedFlatsObj: Dictionary<ServiceFlat[]> = {};
  public selectedFlatList: ServiceFlat[] = [];
  public readonly numberMask = /^[\d]+$/gm;
  private isMobile: boolean;

  constructor(
    protected dialog: MatDialog,
    protected resolution: ResolutionService,
    protected serviceFacade: ServiceFacade,
    protected serviceHelperService: ServiceHelperService,
    protected serviceHelper: ServicesHelper,
    protected flatApiService: FlatApiService,
    protected phonePipe: PhonePipe,
    protected bottomSheet: MatBottomSheet,
    protected translate: TranslateService
  ) {}

  readonly servicesType = ServicesTypes;

  @Input() enabled: boolean;
  @Input() addresses: Address[];
  @Input() dependantServices: ServicesList[];
  @Input() flats: ServiceEntrancesFlats;
  @Input() loading: boolean;
  @Input() showPrefix: boolean;
  @Input() tariff: number;
  @Input() flatsFilters: ServiceFlatsFilters = {
    activeAbonent: false,
    activeServices: [],
    blockedAbonent: false,
    emptyFlat: false,
    blockedServices: [],
    stoppedServices: [],
    phone: ''
  };
  filterInput: UntypedFormControl = new UntypedFormControl();
  mdWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(
    ResolutionBreakpoint.MD_W_DOWN
  );
  lgWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(
    ResolutionBreakpoint.LG_W_DOWN
  );
  xlgWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(
    ResolutionBreakpoint.XLG_W_DOWN
  );
  xlWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(
    ResolutionBreakpoint.XL_W_DOWN
  );
  xxlWUpBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(
    ResolutionBreakpoint.XXL_W_UP
  );
  mdWUpBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(
    ResolutionBreakpoint.MD_W_UP
  );

  @Input() public serviceType!: ServicesTypes;
  @Input() public serviceId!: number;
  @Input() public hardwareTariff!: number;
  @Input() public companyId!: number;
  @Output() manageClose: EventEmitter<void> = new EventEmitter<void>();
  private onDestroy$: Subject<void> = new Subject();

  ngOnInit() {
    this.serviceFacade.filterFlats(<ServiceFlatsFilters>{});
    this.xlgWDownBreakpoint$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  public onResetFilters(): void {
    this.flatsFilters = Object.assign(this.flatsFilters, {
      activeAbonent: false,
      activeServices: [],
      blockedAbonent: false,
      emptyFlat: false,
      blockedServices: [],
      phone: '',
      rangeStart: null,
      rangeEnd: null,
      stoppedServices: []
    });

    this.serviceFacade.filterFlats(this.flatsFilters);
  }
  onDeleteSharedAbonents() {
    const selectedFlats = this.selectedFlatList;
    const data: DialogWrapperData<ServiceAbonentsDisconnectPopupBody, void> = {
      title: this.translate.instant('services.flats.delete_shared_abonents.title'),
      componentName: `Disconnect${this.serviceType}Abonents`,
      body: {
        flats: selectedFlats.filter((flat) => flat.sharedAbonents.length > 0)
      }
    };

    this.dialog.open(ServiceAbonentsDisconnectPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
        ? DialogWrapperSize.MAX
        : DialogWrapperSize.SM,
      data
    });
  }

  onAddConnections() {
    const data: DialogWrapperData<
      ServiceAbonentsFromEntrancesPopupData,
      { selectedConnections: AbonentsFromEntrancesResponse[] }
    > = {
      title: this.translate.instant('services.flats.add_connections.title'),
      componentName: `${this.serviceType}AbonentsFromEntrancesConnection`,
      body: {
        entrances: this.addresses.map((entrance) => ({
          id: entrance.entrance.id
        })),
        ignoredServices: [this.serviceId]
      },
      submit: (event: {
        selectedConnections: AbonentsFromEntrancesResponse[];
      }) => {
        if (event.selectedConnections.length < 1) {
          return;
        }

        this.serviceFacade.addServiceConnectionsFromEntrances(
          event.selectedConnections,
          this.companyId
        );
      }
    };

    this.dialog.open(ServiceAbonentsFromEntrancesPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
        ? DialogWrapperSize.MAX
        : DialogWrapperSize.SM,
      data
    });
  }

  onBlockService(flat: ServiceFlat) {
    const serviceTypeText: string =
      this.serviceHelperService.getServiceNameTypeText(this.serviceType);
    const blocked: boolean = flat.services
      ? flat.services[this.serviceType]?.blocked
      : false;

    const title = blocked
      ? this.translate.instant('services.flats.unblock_service.title', {
        title: serviceTypeText
      })
      : this.translate.instant('services.flats.block_service.title', {
        title: serviceTypeText
      });

    const text = blocked
      ? this.translate.instant('services.flats.unblock_service.title', {
        title: serviceTypeText
      })
      : this.translate.instant('services.flats.block_service.title', {
        title: serviceTypeText
      });

    const data: DialogWrapperData<ServiceConnectionBlockPopupBody, void> = {
      title: title,
      componentName: `${blocked ? 'Unblock' : 'Block'}${
        this.serviceType
      }Connection`,
      body: {
        text,
        blocked: flat.services[this.serviceType]?.blocked
      },
      submit: async () => {
        const serviceId = this.serviceHelper.getServiceIdFromParams();
        if (flat.services[this.serviceType]?.blocked) {
          await this.serviceFacade.unblockConnection(
            flat.services[this.serviceType]
          );
          this.serviceFacade.getService(serviceId);
          return;
        }

        const request: ServiceBlockRequest = {
          entranceId: flat.flat.address.entrance.id,
          flatNumber: flat.flatNumber,
          flatId: flat.flat.id,
          accountId: flat.account.id,
          service: { id: this.serviceId, type: this.serviceType },
          connection: flat.services?.[this.serviceType]
        };

        await this.serviceFacade.blockConnection(request);
        this.serviceFacade.getService(serviceId);
      }
    };

    this.dialog.open(ServiceConnectionBlockPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
        ? DialogWrapperSize.MAX
        : DialogWrapperSize.SM,
      data
    });
  }

  onDeleteConnection(flat: ServiceFlat, serviceType: ServicesTypes) {
    const data: DialogWrapperData<ServiceConnectionDeletePopupBody, void> = {
      title: this.translate.instant('services.flats.delete_connection.title', {
        title: this.serviceHelperService.getServiceNameTypeText(serviceType)
      }),
      componentName: `Delete${serviceType}Connection`,
      body: {
        connection: flat.services[serviceType]
      }
    };

    this.dialog.open(ServiceConnectionDeletePopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
        ? DialogWrapperSize.MAX
        : DialogWrapperSize.SM,
      data
    });
  }

  onDeleteFlat() {
    const flats = this.selectedFlatList;

    const data: DialogWrapperData<ServiceFlatDeletePopupBody, void> = {
      title: this.translate.instant('services.flats.delete_flat.title', {
        flats: flats
          .map((flat) => '№' + flat.flatNumber.toString())
          .join(', ')
      }),
      componentName: `Delete${this.serviceType}Flat`,
      body: {
        flats
      }
    };

    const dialogRef = this.dialog.open(ServiceFlatDeletePopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
        ? DialogWrapperSize.MAX
        : DialogWrapperSize.SM,
      data
    });

    dialogRef.beforeClosed().subscribe(async (response) => {
      if (response) {
        await this.serviceFacade.getConnectionsInit();
        this.selectedFlatList = [];
      }
    });
  }

  onDelegate() {
    const flat = this.selectedFlatList[0];
    const data: DialogWrapperData<ServiceDelegationPopupData, void> = {
      title: this.translate.instant('services.flats.delegate.title'),
      componentName: `CreateDelegation`,
      body: {
        owner: flat.account.owner,
        entranceId: flat.flat.address.entrance.id,
        flatNumber: flat.flat.address.flat
      }
    };

    this.dialog.open(ServiceDelegationPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
        ? DialogWrapperSize.MAX
        : DialogWrapperSize.SM,
      data
    });
  }

  onManage() {
    const data: DialogWrapperData<ServiceManagePopupModel, void> = {
      title: this.translate.instant('services.flats.manager.title'),
      componentName: 'ManageServices',
      body: {
        addresses: this.addresses,
        flats: this.selectedFlatsObj,
        serviceType: this.serviceType,
        flatList: []
          .concat(...Object.values(this.selectedFlatsObj))
          .filter((flat) => flat)
      },
      submit: async () => {
        await this.manageClose.emit();
        this.selectedFlatsObj = {};
        this.selectedFlatList = [];
      }
    };

    this.dialog.open(ServiceManagePopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
        ? DialogWrapperSize.MAX
        : DialogWrapperSize.SM,
      data
    });
  }

  onTariff() {
    const flats = this.selectedFlatList;

    const data: DialogWrapperData<ServiceTariffPopupModel, void> = {
      title: this.translate.instant('services.flats.tariff.title'),
      componentName: 'ServiceTariffPopup',
      body: {
        flats,
        showCheckboxForAllAbonents: false,
        serviceType: this.serviceType
      },
      submit: async () => {
        await this.serviceFacade.getConnectionsInit();
        this.serviceFacade.getService(this.serviceId);
        this.selectedFlatList = [];
      }
    };

    this.dialog.open(ServiceTariffPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
        ? DialogWrapperSize.MAX
        : DialogWrapperSize.SM,
      data
    });
  }

  protected addConnection(request: ServiceFlatsAddConnectionData) {
    const {
      addresses,
      flat,
      serviceType,
      virtualFlat,
      servicesSelectionRequired,
      defaultService,
      existingConnectionsIds,
      hasPhysicalTubeDefault,
      hasPhysicalTubeHidden,
      noPhone,
      responseMessage
    }: ServiceFlatsAddConnectionData = request;

    let { title, componentName }: ServiceFlatsAddConnectionData = request;

    // Set title and component name if this variables undefined
    if (!title) {
      if (serviceType) {
        title = this.translate.instant('services.flats.add_connection_service.title', {
          title: this.serviceHelperService.getServiceNameTypeText(
            serviceType
          )
        });
        componentName = `Add${serviceType}Connection`;
      } else {
        title = this.translate.instant('services.flats.add_connection_abonent.title');
        componentName = `AddAbonentConnection`;
      }
    }

    const abonentAndAccountLoadingForced: boolean =
      !!request.existingConnectionsIds?.length;

    const flatId: number = flat?.flat?.id;

    const data: DialogWrapperData<
      ServiceConnectionPopupBody,
      Partial<ServiceConnectionPopupResponse>
    > = {
      title,
      componentName,
      body: {
        phone: flat?.account?.owner?.phone,
        addresses,
        flatNumber: flat?.flatNumber,
        flatId,
        noPhone,
        defaultService,
        companyId: this.companyId,
        virtualFlat,
        servicesSelectionRequired,
        hasPhysicalTubeDefault,
        hasPhysicalTubeHidden,
        abonentAndAccountLoadingForced,
        responseMessage,
        flatList: this.flats
      },
      submit: async (event: Partial<ServiceConnectionPopupResponse>) => {
        event.request.companyId = this.companyId;

        if (existingConnectionsIds) {
          event.request.existingConnectionsIds = existingConnectionsIds;
        }

        await this.serviceFacade.createConnections({
          ...event.request,
          responseMessage
        });
      }
    };

    this.dialog.open(ServiceConnectionPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
        ? DialogWrapperSize.MAX
        : DialogWrapperSize.SM,
      data
    });
  }

  public getHardwareIntercomId(): number | undefined {
    let hardwareId;
    for (const service of this.dependantServices) {
      if (service.type === ServicesTypes.HARDWARE_INTERCOM) {
        hardwareId = service.id;
      }
    }
    return hardwareId;
  }

  public async onToggleFilterRow(): Promise<void> {
    if (this.isMobile) {
      this.openFilterModal();
    } else {
      this.filterRowState = !this.filterRowState;
    }
  }

  public flatChecked(flats: ServiceFlat[]): void {
    this.selectedFlatList = [
      ...this.selectedFlatList,
      ...flats.filter((flat) => flat && flat.flat?.id)
    ];
  }

  public flatUnchecked(flats: ServiceFlat[]): void {
    const flatIds = flats.map((flat) => {
      if (flat?.flat) {
        return flat.flat.id;
      }
    });
    this.selectedFlatList = this.selectedFlatList.filter(
      (selectedFlat) => !flatIds.includes(selectedFlat.flat.id)
    );
  }

  public handleFlatsToManage(serviceEntFlats: Dictionary<ServiceFlat[]>) {
    const entanceId = Object.keys(serviceEntFlats)[0];
    this.selectedFlatsObj[entanceId] = serviceEntFlats[entanceId];
    const s = Object.values(this.selectedFlatsObj);
  }

  public openFilterModal(): void {
    this.bottomSheet.open(FlatFilterBottomSheetComponent);
  }

  public get selectedFilterCount(): number {
    return Object.values(this.flatsFilters).filter((filter) => {
      if (Array.isArray(filter)) {
        return filter.length > 0;
      } else {
        return filter;
      }
    }).length;
  }

  public get tariffActiveState(): boolean {
    if (this.selectedFlatList.length === 1) {
      return (
        !!this.selectedFlatList[0].flat && !!this.selectedFlatList[0].services
      );
    } else if (this.selectedFlatList.length > 1) {
      return !!this.selectedFlatList.find((flat) => {
        return !!flat.flat && !!flat.services;
      });
    }
  }

  public get manageActiveState(): boolean {
    const selectedFlatList = []
      .concat(...Object.values(this.selectedFlatsObj))
      .filter((flat) => flat);
    return selectedFlatList.length > 0;
  }

  public get deleteAbonentsActiveState(): boolean {
    return (
      this.selectedFlatList.filter((flat) => flat.sharedAbonents?.length > 0)
        .length > 0
    );
  }

  public get deleteActiveState(): boolean {
    return this.selectedFlatList.filter((flat) => !!flat.flat).length > 0;
  }
}
