import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import {
  PreviosQueryParams,
  PreviosQueryType,
  RouteDetectionService
} from '@app/shared/entities/common/route-detection';
import { Constants } from '@app/shared/helpers/constants';
import { NavbarLink } from '@app/shared/ui';
import { State } from '@app/store/reducers';
import { GetAbonentsPage } from '@app/views/abonents/store/actions';
import {
  getAbonentsLoading,
  getAbonentsSuccess
} from '@app/views/abonents/store/states';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import { SortOrder } from '@app/views/abonents/models/sort.models';

@Component({
  selector: 'rdea-abonents-page',
  templateUrl: './abonents-page.component.html',
  styleUrls: ['./abonents-page.component.scss']
})
export class AbonentsPageComponent implements OnInit, OnDestroy {
  readonly pageSize = Constants.PAGE_SIZE;
  readonly displayedAbonentsColumns = ['value', 'resolved', 'actions'];
  /**
   * @TODO: Localize
   */
  readonly links: NavbarLink[] = [
    { name: 'unresolved', text: this.translate.instant('abonents.page.navbar.unresolved') },
    { name: 'resolved', text: this.translate.instant('abonents.page.navbar.resolved') }
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  filterInput: UntypedFormControl = new UntypedFormControl();
  queryParams: PreviosQueryParams;
  chat$: Observable<any>;
  abonents$: Observable<any>;
  activeLink: string;
  savedActiveLink: string;
  sortOrder: SortOrder = 'desc';

  public loading = true;

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    public resolution: ResolutionService,
    private routeDetection: RouteDetectionService,
    private store: Store<State>,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.initQueryParams();
    this.initLoading();
    this.initAbonentsState();
    this.initStore();
    this.initFilter();
    this.initPagination();
    this.loadAbonents(0, this.sortOrder);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onChangeTab(idx: number) {
    this.activeLink = this.links[idx].name;
    this.loadAbonents(0, this.sortOrder);
  }

  private initQueryParams() {
    this.queryParams = this.routeDetection.getQueryParams();
  }

  private initLoading() {
    this.store
      .select(getAbonentsLoading)
      .subscribe((state: boolean) => this.loading = state);
  }

  private initAbonentsState() {
    if (this.queryParams.type === PreviosQueryType.ABONENTS) {
      const activeLink =
        this.queryParams.status !== null ? this.queryParams.status : null;
      if (this.queryParams.query) {
        this.activeLink = null;
        this.savedActiveLink = activeLink;
      } else {
        this.activeLink = activeLink;
      }
    } else {
      this.activeLink = 'unresolved';
      this.savedActiveLink = null;
    }
  }

  private initFilter() {
    if (
      this.queryParams.query &&
      this.queryParams.type === PreviosQueryType.ABONENTS
    ) {
      this.filterInput.setValue(this.queryParams.query);
    }

    this.filterInput.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.onDestroy$)
      )
      .subscribe((value: string) => {
        if (value) {
          this.savedActiveLink = this.activeLink;
          this.activeLink = null;
        } else {
          this.activeLink = this.savedActiveLink ?? null;
          this.savedActiveLink = null;
        }

        this.paginator.pageIndex = 0;
        this.loadAbonents(this.paginator.pageIndex, this.sortOrder);
      });
  }

  private initPagination() {
    if (this.queryParams.page) {
      this.paginator.pageIndex = this.queryParams.page;
    }

    this.paginator.page.subscribe((e: PageEvent) =>
      this.loadAbonents(e.pageIndex, this.sortOrder)
    );
  }

  private initStore() {
    this.abonents$ = this.store.pipe(
      select(getAbonentsSuccess),
      map((data) => ({ data: data.abonents, totalCount: data.totalCount }))
    );
  }

  private loadAbonents(page: number = 0, sort?: string) {
    this.loading = true;
    const resolved =
      this.activeLink === 'resolved'
        ? true
        : this.activeLink === 'unresolved'
        ? false
        : null;
    this.store.dispatch(
      new GetAbonentsPage(
        page,
        this.pageSize,
        resolved,
        this.filterInput.value,
        sort
      )
    );
  }

  onClickSort(order: SortOrder) {
    this.sortOrder = order;
    this.loadAbonents(this.paginator.pageIndex, this.sortOrder);
  }
}
