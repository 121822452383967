import { Observable, Subject, MonoTypeOperatorFunction } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// eslint-disable-next-line camelcase
import { ɵNG_PIPE_DEF, ɵPipeDef, Type } from '@angular/core';
import { IDestroyedStreamOptions } from './interface';

const DESTROYED_STREAM_DEFAULT_NAME = '_destroyed_';
const DEFAULT_DESTROY_METHOD_NAME = 'ngOnDestroy';
const DECORATOR_APPLIED = Symbol('UntilDestroy');
// eslint-disable-next-line camelcase
const NG_PIPE_DEF = ɵNG_PIPE_DEF as 'ɵpipe';

interface PipeType<T> extends Type<T> {
  ɵpipe: ɵPipeDef<T>;
}

/**
 * Decorate component or directive that uses `takeUntilDestroyed` operator. Required for proper operator work
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function DecorateUntilDestroy(): ClassDecorator {
  return (target: Function) => {
    const destroyedStreamName = getDestroyStreamName(DEFAULT_DESTROY_METHOD_NAME);

    if (isPipe(target)) {
      const def = target.ɵpipe;

      // eslint-disable-next-line functional/immutable-data
      def.onDestroy = getNewDestroyMethod(def.onDestroy, destroyedStreamName);
    } else {
      // eslint-disable-next-line functional/immutable-data
      target.prototype.ngOnDestroy = getNewDestroyMethod(target.prototype.ngOnDestroy, destroyedStreamName);
    }

    // eslint-disable-next-line functional/immutable-data
    target.prototype[DECORATOR_APPLIED] = true;
  };
}

/**
 * Rxjs operator. By default uses `ngOnDestroy` as destroy method and init destroy stream once.
 *
 * For other cases use `options` param.
 *
 * ### Usage example
 * ```
 * // regular case
 * takeUntilDestroyed(this)
 *
 * // non-component or custom destroy method
 * takeUntilDestroyed(this, { destroyMethod: this.destroy })
 * ```
 */
export function takeUntilDestroyed<T, TClass>(
  instance: TClass,
  options: IDestroyedStreamOptions = {},
): MonoTypeOperatorFunction<T> {
  const destroyMethodName = options.destroyMethod
    ? getClassMethodName(instance, options.destroyMethod) ?? DEFAULT_DESTROY_METHOD_NAME
    : DEFAULT_DESTROY_METHOD_NAME;
  // we have to separate destroy streams based on used destroy method
  const destroyedStreamName = getDestroyStreamName(destroyMethodName);

  if (!options.destroyMethod) {
    // check that class is decorated
    if (!(DECORATOR_APPLIED in Object.getPrototypeOf(instance).constructor.prototype)) {
      throwError(instance, `Missed '@DecorateUntilDestroy' decorator usage`);
    }
  } else if (!instance[destroyedStreamName]) {
    if (!instance[destroyMethodName]) {
      throwError(instance, `Missed destroy method '${destroyMethodName}'`);
    }

    // eslint-disable-next-line functional/immutable-data
    instance[destroyMethodName] = getNewDestroyMethod(instance[destroyMethodName], destroyedStreamName);
  }

  // initial destroy stream setup
  if (!instance[destroyedStreamName]) {
    // eslint-disable-next-line functional/immutable-data
    instance[destroyedStreamName] = new Subject<void>();
  }

  return (source: Observable<T>) => {
    return source.pipe(takeUntil(instance[destroyedStreamName]));
  };
}

function throwError<T>(target: T, textPart: string): void {
  throw new Error(`takeUntilDestroyed: ${textPart} in '${Object.getPrototypeOf(target).constructor.name}'`);
}

function getClassMethodName<T>(classObj: T, method: Function): string | null {
  const methodName = Object.getOwnPropertyNames(classObj).find(prop => classObj[prop] === method);

  if (methodName) {
    return methodName;
  }

  const proto = Object.getPrototypeOf(classObj);
  if (proto) {
    return getClassMethodName(proto, method);
  }

  return null;
}

function getNewDestroyMethod(
  originalDestroy: ((...args: unknown[]) => unknown) | null | undefined,
  destroyedStreamName: string,
): () => unknown {
  return function (this: Function, ...args: unknown[]): unknown {
    let result: unknown | undefined;

    // Invoke the original `ngOnDestroy` if it exists
    if (originalDestroy) {
      result = originalDestroy.call(this, ...args);
    }

    if (this[destroyedStreamName]) {
      this[destroyedStreamName].next();
    }

    return result;
  };
}

// we have to separate destroy streams based on used destroy method
function getDestroyStreamName(destroyMethodName: string): string {
  return DESTROYED_STREAM_DEFAULT_NAME + destroyMethodName;
}

export function isPipe<T>(target: Object): target is PipeType<T> {
  return !!target[NG_PIPE_DEF];
}
