<div class="paginator-container">
  <p-paginator
    [first]="paginatorConfig?.first"
    [rows]="paginatorConfig?.pageSize"
    [totalRecords]="paginatorConfig?.totalRecords"
    (onPageChange)="onPageChange($event)"
    [showCurrentPageReport]="true"
    [pageLinkSize]="paginatorConfig?.pageLinkSize"
    [currentPageReportTemplate]="paginatorConfig?.currentPageReportTemplate"
    [showPageLinks]="true"
    [showFirstLastIcon]="true"
  ></p-paginator>
  <div class="paginator-container-settings">
    <p-dropdown
      appendTo="body"
      [options]="paginatorConfig?.options"
      [optionLabel]="paginatorConfig?.optionLabel"
      [optionValue]="paginatorConfig?.optionValue"
      [style]="{'height':'46px'}"
      [(ngModel)]="paginatorConfig.pageSize"
      (onChange)="onPageSizeChange($event.value)"
    ></p-dropdown>
  </div>
</div>
