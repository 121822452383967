export interface PeerConnectionState {
  type: StateEnum,
  state: string
}

export enum StateEnum {
  iceConnectionState = 'iceConnectionState',
  signalingState = 'signalingState',
  connectionState = 'connectionState',
}

export interface IState {
  playerState: PlayerState,
  updatePlayerState: UpdatePlayerState
}

export interface UpdatePlayerState {
  type: StateType.UpdatePlayerState;
  value: PlayerStateEnum;
}

export interface PlayerState {
  type: StateType.PlayerState;
  value: PlayerStateEnum;
}

export enum PlayerStateEnum {
  PLAY = 'play',
  PAUSE = 'pause'
}

export enum StateType {
  UpdatePlayerState = 'UpdatePlayerState',
  PlayerState = 'PlayerState'
}
