import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActiveHistoryChartData } from '@app/shared/containers/active-history-chart';
import { ActiveHistoryResponse } from '@app/shared/entities/rd';
import { Dictionary } from '@app/shared/helpers';
import { ServiceActivitySource } from '@app/views/services/models';

@Component({
  selector: 'app-service-active-history-chart',
  templateUrl: './service-active-history-chart.component.html',
  styleUrls: ['./service-active-history-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceActiveHistoryChartComponent {
  @Input() sources!: ServiceActivitySource[];
  @Input() blocksCount: number;
  @Input() set activeHistory(data: Dictionary<ActiveHistoryResponse[]>) {
    if (!data) {
      return;
    }

    this._data = Object.entries(data).reduce((acc, [key, value]) => {
      acc[key] = value
        ? value.map(({ value, timestamp }) => ({
            state: parseFloat(value) > 0,
            value,
            timestamp,
          })).reverse()
        : null;
      return acc;
    }, {});
  }
  @Input() title: string;

  @Output() reloadChart: EventEmitter<{ blocksCount: number }> = new EventEmitter();
  private _data: Dictionary<ActiveHistoryChartData[]>;

  onReloadChart(blocksCount: number) {
    this.reloadChart.emit({ blocksCount });
  }

  get data(): Dictionary<ActiveHistoryChartData[]> {
    return this._data;
  }
}
