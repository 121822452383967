<app-dialog-wrapper [componentName]="data.componentName" [title]="data.title">
    <app-video-player-videoshot-container
        class="video-player__container"
        [class.width-for-mobile]="data.body.isMobile" 
        [state]="state$ | async"
        [loading]="dialogWrapperService.pendingState$ | async"
        [minDate]="data.body.minDate"
        [maxDate]="data.body.maxDate"
        [depthInHours]="data.body.depthInHours"
        (downloadVideoshot)="onDownloadVideoshot()"
        (initVideoshotBuilding)="onInitVideoshotBuilding()"
        (createVideoshotTask)="onCreateVideoshotTask($event.date, $event.duration)"
    ></app-video-player-videoshot-container>
</app-dialog-wrapper>