<div class="container-main">
  <rd-video-control *ngIf="!isStaticLabel; else staticLabel"
    [name]="selectValueLabel"
    [icon]="icon"
    (clickEvent)="onClick()" 
    [active]="!isHidden"
  ></rd-video-control>

  <div [hidden]="isHidden" class="container">
    <div class="container-wrapper">
      <div *ngIf="!options || options.length === 0" (mousedown)="onClick()">
        <ng-container *ngTemplateOutlet="emptyOptionTemplate"></ng-container>
      </div>          
      
      <rd-video-control *ngFor="let option of options" 
        [name]="option.label"
        [value]="option"
        [isSelectButton]="true"
        (clickEvent)="onSelectChange($event)" 
      ></rd-video-control>
    </div>
  </div>
</div>

<ng-template #staticLabel>
  <rd-video-control
    [name]="name"
    [icon]="icon"
    (clickEvent)="onClick()" 
    [active]="!isHidden"
  ></rd-video-control>
</ng-template>
