import { Injectable } from '@angular/core';
import { LoggerService } from '@app/shared/entities/common';
import { CameraApiService } from '@app/shared/entities/rd';
import { PlyrControlsHelper, RdeaHls, RdeaPlyr } from './../helpers';
import { HlsHelper } from '../helpers/hls-helper';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class VideoPlayerHelperService {
  private hlsHelper: HlsHelper
  private plyrControlsHelper: PlyrControlsHelper

  constructor(
    private cameraApiService: CameraApiService,
    private loggerService: LoggerService,
    private translate: TranslateService
  ) {
  }

  initControlsHelper(plyr: RdeaPlyr): PlyrControlsHelper {
    this.plyrControlsHelper = new PlyrControlsHelper(plyr, this.loggerService, this.translate);
    return this.plyrControlsHelper;
  }

  initHlsHelper(hls: RdeaHls): HlsHelper {
    this.hlsHelper = new HlsHelper(hls, this.loggerService);
    return this.hlsHelper;
  }

  destroyHlsHelper(): void {
    this.hlsHelper.destroy()
  }

  destroyPlyrControlsHelper(): void {
    this.plyrControlsHelper.destroy()
  }

  /**
   * Get live source link
   * @param {string} rdvaUri RDVA Url
   * @param {number} cameraId camera ID
   * @returns live source
   */
  getLiveSource(rdvaUri: string, cameraId: number): string {
    return this.cameraApiService.getHlsLink(rdvaUri, cameraId);
  }

  /**
   * Get date from fragment's relurl
   * @param timestamp timestamp from dlarchive link
   * @returns date covered
   */
  getDateFromFragmentRelUrl(relurl: string, cameraId: number): Date {
    const timestamp = relurl?.split(`/dlarchive/${cameraId}/`)[1]?.replace('.ts', '');

    if (!timestamp) {
      return null;
    }

    const splittedDateAndTime: string[] = timestamp.split('T');
    const splittedDate: string[] = splittedDateAndTime[0].split('-');
    const dateObj = {
      year: Number.parseInt(splittedDate[0], 10),
      month: Number.parseInt(splittedDate[1], 10),
      day: Number.parseInt(splittedDate[2], 10),
      hour: Number.parseInt(splittedDate[3], 10),
      minute: Number.parseInt(splittedDate[4], 10),
      second: Number.parseInt(splittedDate[5], 10)
    };
    const timezoneOffsetInHours: number = new Date().getTimezoneOffset() / 60;

    return new Date(
      dateObj.year,
      dateObj.month - 1,
      dateObj.day,
      dateObj.hour - timezoneOffsetInHours,
      dateObj.minute,
      dateObj.second - Number.parseFloat(splittedDateAndTime[1])
    );
  }

  public getPlaylistRelUrl(isFirstPlaylist: boolean, data: string): string {
    const startIndex = isFirstPlaylist ? data.indexOf('/dlarchive') : data.lastIndexOf('/dlarchive');
    const endIndex = (isFirstPlaylist ? data.indexOf('.ts') : data.lastIndexOf('.ts')) + 3;
    return data.substring(startIndex, endIndex);
  }

  public shiftMinDateForward(minDate: Date, maxDate: Date): Date {
    const defaultShiftTime: number = (60 * 5) * 1000;

    if (minDate.getTime() + defaultShiftTime > maxDate.getTime()) {
      return minDate;
    }

    return new Date(minDate.getTime() + defaultShiftTime);
  }
}
