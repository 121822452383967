import { Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";

export interface Option<T = unknown> {
  value: T;
  label: string;
}

@Component({
  selector: 'rd-video-select-control',
  templateUrl: './select-control.html',
  styleUrls: ['./select-control.scss']
})
export class RdVideoSelectControlComponent<T> {
  @Input() public set selectValueDefault(value: Option | null) {
    if (!value) return;
    this.selectValueLabel = value.label;
  }
  @Input() isStaticLabel: boolean = false;
  @Input() name: string;
  @Input() options: Option<T>[];
  @Input() icon: string;
  @Input() emptyOptionTemplate: TemplateRef<unknown>;

  selectValueLabel: string;
  isHidden = true;

  @Output() selectChange = new EventEmitter<Option>();

  onSelectChange(value: Option): void {
    this.selectValueLabel = value.label;
    this.isHidden = !this.isHidden;
    this.selectChange.emit(value);
  }

  onClick(): void {
    this.isHidden = !this.isHidden;
  }
}
