import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {
  DEFAULT_CURRENCY_CODE,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { ServiceWorkerHelper } from '@app/core/helpers';
import { CoreEffects, coreReducers } from '@app/core/store';
import { JwtModule } from '@auth0/angular-jwt';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MaterialModule } from './../material.module';

import { SharedPipesModule } from '@app/shared';
import { httpTokenProvider } from '@app/core/interceptors';
import { SharedDirectivesModule } from '@app/shared/directives';
import {
  AppComponent,
  MenuContainerComponent,
  TitleBarComponent,
  TopBarComponent
} from '@app/core/components';
import {
  ChangePasswordPopupModule,
  LicenseeReportPopupModule
} from '@app/shared/components';
import { ButtonLinkModule, SplashLoaderModule } from '@app/shared/ui';
import { LanguageSwitchComponent } from '@app/translate/components/language-switch/language-switch.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {ButtonModule} from 'primeng/button';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        SplashLoaderModule,
        ButtonLinkModule,
        RouterModule,
        SharedPipesModule,
        SharedDirectivesModule,
        LicenseeReportPopupModule,
        ChangePasswordPopupModule,
        JwtModule.forRoot({
            config: {tokenGetter: () => localStorage.getItem('rdea:AuthToken')}
        }),
        StoreModule.forFeature('core', coreReducers),
        EffectsModule.forFeature([CoreEffects]),
        TranslateModule,
        FormsModule,
        ButtonModule
    ],
  providers: [
    httpTokenProvider,
    ServiceWorkerHelper,
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'RUB'
    }
  ],
  declarations: [
    AppComponent,
    LanguageSwitchComponent,
    TopBarComponent,
    TitleBarComponent,
    MenuContainerComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        `${parentModule} has already been loaded. Import CoreModule module in the AppModule only.`
      );
    }
  }
}
