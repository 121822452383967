import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  GetVideoshotPopupPlaylists,
  HlsFragParseHelper,
  HlsHelper,
  MAX_FATALS_COUNT,
  RdeaHls,
  RdeaPlyr,
  TZ_OFFSET_IN_MILLISECONDS,
  TZ_OFFSET_IN_MINUTES,
  VideoPlayerChangeVideoshotState,
  VideoPlayerErrorTypes,
  VideoPlayerFacade,
  VideoPlayerFragmentShortDate,
  VideoPlayerHelperService,
  VideoPlayerHlsError,
  VideoPlayerMode,
  VideoPlayerState,
  VideoPlayerVideoshotPopupComponent,
  VideoPlayerVideoshotRequest,
  VideoshotPopupState
} from '@app/shared/components/video-player';
import { RdvaApiService, RdvaPlaylistItem } from '@app/shared/entities/integrations';
import { catchError, delay, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { DialogWrapperData } from '@app/shared/ui';
import { Constants, parseError } from '@app/shared/helpers';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@app/shared/components';
import { isEmpty } from 'lodash';
import { RdeaDate } from '@app/shared/entities';
import Hls, { ErrorTypes, Fragment } from 'hls.js';
import { AuthFacade } from '@app/views';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { CameraApiService, ICamerasRdaConfig, RdaApiService } from '@app/shared/entities/rd';
import { VideoManagerN } from '@app/views/services/submodules/video-manager/models/view-manager';
import { animate, AnimationBuilder, AnimationPlayer, keyframes, style } from '@angular/animations';
import ScreenItem = VideoManagerN.ScreenItem;
import { RdVideoService } from '@app/shared/video/service/video.service';
import { DecorateUntilDestroy, takeUntilDestroyed } from '@app/shared/rxjs/operator/take-until-destroyed';
import { Store } from '@ngrx/store';

const slide_in_bottom = animate('0.4s 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940)', keyframes([
  style({ visibility: 'visible', opacity: 0, }),
  style({ transform: 'translateY(400px)', opacity: 0, }),
  style({ transform: 'translateY(0)', opacity: 1, }),
]));

const slide_out_bottom = animate('1.2s 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940)', keyframes([
  style({ visibility: 'visible', }),
  style({ transform: 'translateY(0)', opacity: 1, }),
  style({ transform: 'translateY(400px)', opacity: 0, visibility: 'hidden', }),
  style({ opacity: 1, transform: 'translateY(0)', }),
]));

@DecorateUntilDestroy()

@Component({
  selector: 'app-video-manager-player',
  templateUrl: './video-manager-player.component.html',
  styleUrls: ['./video-manager-player.component.scss'],
  providers: [VideoPlayerFacade, VideoPlayerHelperService],
  encapsulation: ViewEncapsulation.None
})
export class VideoManagerPlayerComponent implements OnInit, AfterViewChecked, OnDestroy {
  protected readonly isEmpty = isEmpty;

  @Input() public width: string;
  @Input() public height: string;
  @Input() public token: string;
  @Input() public hideSmallActions = false;
  @Input() public disablePlyrAutoHide = false;
  @Input() public depthInHours: number;
  @Input() public camera: ScreenItem;


  @ViewChild('video') public videoElement: ElementRef<HTMLVideoElement>;
  @ViewChild('playerWrapperElement') public playerWrapperElement: ElementRef<HTMLDivElement>;

  public playlists: MenuItem[] = [];
  public eventsList: MenuItem[] = [];
  public archivePlaylist: { playlist: string; label: string; } = null;
  public selectedEvent: { label: string; id: string; item: VideoPlayerFragmentShortDate } = null;
  public loaded = false;

  public smallScreenActions: MenuItem[] = [];

  public errorLoadingPlaylist = false;
  public errorLoadingVideo = false;

  public get ngClassMainWrapper(): { [key: string]: boolean } {
    return {
      'video-manager-player2--disable-plyr-auto-hide-controls': this.disablePlyrAutoHide || (!this.disablePlyrAutoHide && this.mouseIn),
      'video-manager-player2--hide-plyr-progress': this.mode.value === VideoPlayerMode.LIVE,
      'video-manager-player2--small-size': this.mode.value === VideoPlayerMode.LIVE
    };
  }

  public get isSmallSize(): boolean {
    return this.playerWrapperElement?.nativeElement?.offsetWidth <= 550;
  }

  public get rdvaURI(): string {
    return this.camera?.camera?.rdva?.uri;
  }

  public get cameraId(): number {
    return this.camera?.camera?.id;
  }

  public get isCanAdd10Sec(): boolean {
    return this.plyr?.duration - this.plyr?.currentTime >= 10;
  }

  public get isCanOpenDoor(): boolean {
    return this.releInfo !== null;
  }

  public get isCanMinus10Sec(): boolean {
    return this.plyr?.currentTime >= 10;
  }

  public get isCanChangeToPrevEvent(): boolean {
    const currentEventIndex = this.eventsList.findIndex((e) => e.id === this.selectedEvent?.id);
    return !isEmpty(this.eventsList) && this.eventsList[0].id !== null && currentEventIndex > 0;
  }

  public get isCanChangeToNextEvent(): boolean {
    const currentEventIndex = this.eventsList.findIndex((e) => e.id === this.selectedEvent?.id);
    return !isEmpty(this.eventsList) && this.eventsList[0].id !== null && currentEventIndex < this.eventsList.length - 1;
  }

  public get downloadVideoShotAction(): MenuItem {
    return {
      label: this.translate.instant('shared.video_player.plyr.template.button.get_video_shot'),
      icon: 'pi pi-save',
      command: () => this.openVideoshotPopup(),
    };
  }

  public get playlistArchiveAction(): MenuItem {
    return {
      label: this.archivePlaylist?.label ? this.archivePlaylist.label : this.translate.instant('abonent.page.info.header.events'),
      icon: 'pi pi-calendar',
      items: this.playlists,
    };
  }

  public get eventsAction(): MenuItem {
    return {
      label: this.selectedEvent?.label ? this.selectedEvent.label : this.translate.instant('abonent.page.info.header.events'),
      icon: 'pi pi-bell',
      items: this.eventsList,
    };
  }

  public get openDoorAction(): MenuItem {
    return this.isCanOpenDoor ?
      {
        label: this.translate.instant('shared.video_player.plyr.template.button.open_door'),
        icon: 'pi pi-sign-out',
        command: () => this.openDoor(),
      } :
      null;
  }

  private mouseIn = false;
  private mouseActioned = false;
  @ViewChild('actionsSmall') private actionsSmall: ElementRef<HTMLDivElement>;
  @ViewChild('actionsLarge') private actionsLarge: ElementRef<HTMLDivElement>;

  private releInfo: ICamerasRdaConfig = null;
  private plyr: RdeaPlyr;
  private recoverErrorTries = 0;
  private activeStreamPlaylist: string;
  private minDate: Date;
  private maxDate: Date;
  private destroy$ = new Subject<void>();
  private player: AnimationPlayer | undefined;
  private isPopupReadyToShow$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  private videoshotPopupData$: Observable<string[]> = this.videoPlayerFacade.videoshotPlaylistData$;
  private isErrorOccured$: Observable<boolean> = this.videoPlayerFacade.isErrorOccured$;

  private mode = new BehaviorSubject<VideoPlayerMode>(VideoPlayerMode.LIVE)
  mode$ = this.mode.asObservable();

  readonly isLiveActive$ = this.mode$
    .pipe(
      map(value => value === VideoPlayerMode.LIVE)
    )

  readonly isArchiveActive$ = this.mode$
    .pipe(
      map(value => value === VideoPlayerMode.ARCHIVE)
    )

  constructor(
    private videoPlayerHelper: VideoPlayerHelperService,
    private rdvaApiService: RdvaApiService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private resolution: ResolutionService,
    private snackbar: SnackbarService,
    private router: Router,
    private authFacade: AuthFacade,
    private rdaApiService: RdaApiService,
    private cameraApiService: CameraApiService,
    private animationBuilder: AnimationBuilder,
    private rdVideoService: RdVideoService, 
    protected videoPlayerFacade: VideoPlayerFacade,
    private store: Store<VideoPlayerState>,
  ) {}

  public ngOnInit(): void {
    this.initOnChangeModePlayer();
    this.addPlaylistLoadListeners();

    this.activeStreamPlaylist = this.videoPlayerHelper.getLiveSource(this.rdvaURI, this.cameraId);
    this.rdvaApiService.getPlaylists(this.rdvaURI, this.camera?.camera?.id, -TZ_OFFSET_IN_MINUTES)
      .pipe(
        takeUntil(this.destroy$),
        catchError((err) => {
          this.snackbar.showMessage(
            this.translate.instant('shared.video_player.message.get_playlists.failed', {
              text: parseError(err)
            })
          );
          this.errorLoadingPlaylist = true;
          return of(null);
        }),
        mergeMap((response: RdvaPlaylistItem[] | null) => {
          if (!response) {
            this.loaded = true;
            return this.getDoorKeys();
          }

          if (isEmpty(response)) {
            this.snackbar.showMessage(
              this.translate.instant('shared.video_player.message.get_playlists.empty'),
              'info'
            );
            this.loaded = true;
            return this.getDoorKeys();
          }

          this.dispatchGetVideoshotPopupData(response);
          this.playlists = response.map((item) => {
            this.archivePlaylist = {
              playlist: item?.playlist,
              label: new RdeaDate(item.date).toDayMonthString(),
            };

            return {
              label: new RdeaDate(item.date).toDayMonthString(),
              command: () => {
                this.archivePlaylist = {
                  playlist: item?.playlist,
                  label: new RdeaDate(item.date).toDayMonthString(),
                };

                this.rdVideoService.destroyHlsStream(this.cameraId);
                this.initHls();
                this.rdVideoService.loadSourceHls(this.cameraId, item?.playlist)

              }
            };
          });
          return this.getDoorKeys();
        })
      )
      .subscribe((response: ICamerasRdaConfig | null) => {
        if (!response) {
          this.releInfo = null;
          return;
        }
        if (response?.releId !== null && response?.uid !== null) {
          this.releInfo = response;
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.rdVideoService.destroy();
    this.videoPlayerFacade.clearState();
  }

  public ngAfterViewChecked(): void {
    const newActions = [
      this.openDoorAction,
      { separator: true },
      this.changeEventAction(this.isCanChangeToPrevEvent, false),
      this.changeSomeSecAction(this.isCanMinus10Sec, false),
      this.changeSomeSecAction(this.isCanAdd10Sec, true),
      this.changeEventAction(this.isCanChangeToPrevEvent, true),
      { separator: true },
      this.playlistArchiveAction,
      this.eventsAction,
      { separator: true },
      this.downloadVideoShotAction,
    ].filter((a) => a);
    if (this.smallScreenActions?.length !== newActions.length) {
      this.smallScreenActions = newActions;
    }
  }

  @HostListener('mousemove', ['$event'])
  public onMouseMove(event: any): void {
    this.mouseIn = true;
    if (!this.mouseActioned) {
      this.animateActionsWrapper(false);
    }
  }

  @HostListener('mouseenter', ['$event'])
  public onMouseEnter(event: MouseEvent): void {
    if (this.mouseIn) {
      return;
    }
    this.mouseIn = true;
    this.animateActionsWrapper(false);
  }

  @HostListener('mouseleave', ['$event'])
  public onMouseLeave(event: any): void {
    if ((event.relatedTarget as HTMLElement)?.classList?.contains('video-manager-player2__tired-actions')) {
      return;
    }
    this.mouseIn = false;
    this.animateActionsWrapper(true);
  }

  private initOnChangeModePlayer(): void {
    this.mode$
      .pipe(
        // delay so that the video element has time to render
        delay(1),
        takeUntilDestroyed(this)
      )
      .subscribe((mode) => {
        switch (mode) {
          case VideoPlayerMode.ARCHIVE:
            this.initArchive()
            break;
          case VideoPlayerMode.LIVE:
            this.initLive()
            break;
          default:
            break;
        }
      })
  }

  private initLive(): void {
    this.rdVideoService.addReceiverWebRTC({
      id: this.cameraId
    });

    this.initPlayer(VideoPlayerMode.LIVE);
  }

  private initArchive(): void {
    this.selectedEvent = null;
    
    this.initHls();
    this.rdVideoService.loadSourceHls(this.cameraId, this.archivePlaylist.playlist);

    this.animateActionsWrapper(true);

    this.initPlayer(VideoPlayerMode.ARCHIVE);
  }

  /**
   * Create and configurate Hls instance
   */
  private initHls() {
    const hls = this.rdVideoService.addReceiverHls({
      id: this.cameraId
    });

    const hlsHelper = this.videoPlayerHelper.initHlsHelper(hls);

    hlsHelper.addErrorListeners((error: VideoPlayerHlsError) =>
      this.handleHlsError(error, hls)
    );

    hlsHelper.addManifestLoadedListener((fragments: Fragment[]) =>
      this.handleManifestLoaded(fragments)
    );
  }

  private initPlayer(mode: VideoPlayerMode): void {
    this.plyr = new RdeaPlyr(mode, this.videoElement.nativeElement, this.translate, {
      autoplay: true,
      controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'pip', 'airplay', 'fullscreen'],
      hideControls: !this.disablePlyrAutoHide,
      invertTime: false,
      clickToPlay: false,
      volume: 0,
      muted: true,
      fullscreen: { enabled: true }
    }, true);

    this.plyr.muted = true;

    this.plyr.on('canplay', (d) => {
      this.loaded = true;
    });

    this.plyr.on('seeking', () => {
      this.loaded = false;
    });

    this.plyr.on('seeked', () => {
      this.loaded = true;
    });

  }

  public changeModeToLive(): void {
    this.rdVideoService.destroyHlsStream(this.cameraId);
    this.mode.next(VideoPlayerMode.LIVE)
  }

  public changeModeToArchive(): void {
    this.rdVideoService.destroyWebRTCStream(this.cameraId);
    this.mode.next(VideoPlayerMode.ARCHIVE)
  }

  public openVideoshotPopup(): void {
    const data: DialogWrapperData<VideoPlayerVideoshotRequest, null> = {
      title: this.translate.instant('shared.video_player.video_shot.title'),
      componentName: 'GetVideoshot',
      body: {
        minDate: this.minDate,
        maxDate: this.maxDate,
        depthInHours: this.depthInHours,
        rdvaUri: this.rdvaURI,
        cameraId: this.cameraId,
        token: this.token,
        isMobile: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
      }
    };

    this.dialog.open(VideoPlayerVideoshotPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      autoFocus: false,
      data
    });

    if (!this.minDate || !this.maxDate) {
      this.isPopupReadyToShow$.pipe(takeUntil(this.destroy$)).subscribe((isDataReceived) => {
        if (isDataReceived) {
          data.body.minDate = this.minDate;
          data.body.maxDate = this.maxDate;
        }
      });
    }
  }

  public changeTimelineOnSomeSec(plus: boolean): void {
    this.plyr.currentTime = plus ? this.plyr.currentTime + 10 : this.plyr.currentTime - 10;
  }

  public openDoor(): void {
    this.rdaApiService.openDoor(this.releInfo?.uid, this.releInfo?.releId).pipe(
      catchError((error: HttpErrorResponse) => of(error)),
      take(1)
    ).subscribe((response) => {
      if (response instanceof HttpErrorResponse) {
        this.snackbar.showMessage(
          this.translate.instant('shared.video_player.message.open.failed.text', {
            text: response.message
          }),
          'error'
        );
        return;
      }
      this.snackbar.showMessage(
        this.translate.instant('shared.video_player.message.open.success'),
        'success'
      );
    });
  }

  public changeEvent(next: boolean): void {
    const currentEventIndex = this.eventsList.findIndex((e) => e.id === this.selectedEvent?.id);
    this.eventsList[next ? (currentEventIndex + 1) : (currentEventIndex - 1)].command();
  }

  private animateActionsWrapper(hide: boolean): void {
    if (this.disablePlyrAutoHide) {
      return;
    }
    if (this.player) {
      this.player.destroy();
    }
    const metadata = hide ? slide_out_bottom : slide_in_bottom;
    const factory = this.animationBuilder.build(metadata);
    const player = factory.create(
      this.isSmallSize ?
        this.actionsSmall.nativeElement :
        this.actionsLarge.nativeElement
    );
    this.mouseActioned = !hide;
    player.play();
  }

  private changeSomeSecAction(condition: boolean, plus: boolean): MenuItem {
    return condition ?
      {
        label: this.translate.instant(plus ?
          'shared.video_player.plyr.template.button.plus_10_second' :
          'shared.video_player.plyr.template.button.minus_10_second'),
        icon: plus ? 'pi pi-angle-double-right' : 'pi pi-angle-double-left',
        command: () => this.changeTimelineOnSomeSec(plus)
      } :
      null;
  }

  private changeEventAction(condition: boolean, next: boolean): MenuItem {
    return condition ?
      {
        label: this.translate.instant(next ?
          'shared.video_player.plyr.template.button.next_event2' :
          'shared.video_player.plyr.template.button.prev_event2'),
        icon: next ? 'pi pi-angle-right' : 'pi pi-angle-left',
        command: () => this.changeEvent(next)
      } :
      null;
  }

  private getDoorKeys(): Observable<ICamerasRdaConfig | null> {
    return this.cameraApiService.getCameraRDAConfigById(this.cameraId)
      .pipe(
        catchError((error: HttpErrorResponse) => of(null)),
        takeUntil(this.destroy$),
      );
  }

  private handleHlsError({ fatal, type, details }: VideoPlayerHlsError, hls: RdeaHls): void {
    if (type === VideoPlayerErrorTypes.AUTH_ERROR) {
      this.authFacade.logout(this.router.url);
      return;
    }
    if (!fatal) {
      return;
    }
    if (this.recoverErrorTries === MAX_FATALS_COUNT) {
      this.showFatalErrorMessage(type, details);
      return;
    }
    switch (type) {
      case ErrorTypes.MEDIA_ERROR:
        hls.recoverMediaError();
        break;
      case ErrorTypes.NETWORK_ERROR:
        hls.startLoad();
        break;
    }
    this.recoverErrorTries++;
  }

  private showFatalErrorMessage(errorType: string, details: string): void {
    let reason: string;
    switch (errorType) {
      case Hls.ErrorTypes.NETWORK_ERROR:
        reason = details === 'levelEmptyError'
          ? this.translate.instant('shared.video_player.message.error.reason.empty')
          : this.translate.instant('shared.video_player.message.error.reason.error')
          ;
        break;
      case Hls.ErrorTypes.MEDIA_ERROR:
        reason = this.translate.instant('shared.video_player.message.error.reason.media');
        break;
      default:
        reason = this.translate.instant('shared.video_player.message.error.reason.unknown');
    }
    this.snackbar.showMessage(
      this.translate.instant('shared.video_player.message.error.reason.unknown', {
        text: reason
      })
    );
  }

  private handleManifestLoaded(fragments: Fragment[]): void {
    const { rangeTimestamps, eventTimestamps } = HlsFragParseHelper.extractDateRangesFromFragments(fragments);
    if (!rangeTimestamps?.length) {
      this.eventsList = [{ label: this.translate.instant('shared.video_player.plyr.template.message.no_events') }];
      return;
    }
    if (!eventTimestamps?.length) {
      this.eventsList = [{
        label: this.translate.instant('shared.video_player.plyr.template.message.no_events'),
        id: null
      }];
    } else {
      this.eventsList = eventTimestamps.map((item) => {
        const date = new RdeaDate(item.startTimestamp);
        const label = new RdeaDate(date.getTime() - TZ_OFFSET_IN_MILLISECONDS).toDateTimeString({
          date: false,
          utc0: true
        });
        return {
          id: `${item?.startTimestamp}`,
          label,
          command: () => {
            this.selectedEvent = { label, id: `${item?.startTimestamp}`, item };
            this.applySelectedTime({ absoluteTimeWithOffset: date.getTime() - TZ_OFFSET_IN_MILLISECONDS });
          },
        };
      });
    }
  }

  private applySelectedTime(options: { percent?: number; absoluteTimeWithOffset?: number }): void {
    const diff2 = options.absoluteTimeWithOffset - (this.selectedEvent.item.startTimestamp - TZ_OFFSET_IN_MILLISECONDS);
    this.plyr.currentTime = this.selectedEvent.item.startPlayerTime + diff2 / 1000;
    setTimeout(() => {
      this.plyr.currentTime = this.selectedEvent.item.startPlayerTime + diff2 / 1000;
    }, 1000);
  }

  private addPlaylistLoadListeners(): void {
    this.videoshotPopupData$.pipe(takeUntil(this.destroy$))
      .subscribe(videoshotPlaylistData => {
        if (videoshotPlaylistData) {
          const firstPlaylistUrl = this.videoPlayerHelper.getPlaylistRelUrl(true, videoshotPlaylistData[0]);
          const lastPlaylistUrl = this.videoPlayerHelper.getPlaylistRelUrl(false, videoshotPlaylistData[1]);

          this.minDate = this.videoPlayerHelper.getDateFromFragmentRelUrl(firstPlaylistUrl, this.cameraId);
          this.maxDate = this.videoPlayerHelper.getDateFromFragmentRelUrl(lastPlaylistUrl, this.cameraId);
          this.minDate = this.videoPlayerHelper.shiftMinDateForward(this.minDate, this.maxDate);
          
          this.isPopupReadyToShow$.next(true);
          this.store.dispatch(new VideoPlayerChangeVideoshotState(VideoshotPopupState.CREATE));
        }
      }
    )

    this.isErrorOccured$.subscribe(errorOccured => {
      if (errorOccured) {
        this.store.dispatch(new VideoPlayerChangeVideoshotState(VideoshotPopupState.CREATE));
      }
    })
  }

  private dispatchGetVideoshotPopupData(response: RdvaPlaylistItem[]): void {
    const [firstPlaylist, lastPlaylist] = [response[0].playlist, response[response.length - 1].playlist];
    this.store.dispatch(new VideoPlayerChangeVideoshotState(VideoshotPopupState.LOADING));
    this.store.dispatch(new GetVideoshotPopupPlaylists([firstPlaylist, lastPlaylist]));
  }
}
