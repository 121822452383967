<app-info-block contentWidth="50"
  *ngIf="
    (status$ | async) === 'loading' ||
    (status$ | async) === 'account-checking' ||
    (status$ | async) === 'connection'
  "
>
  <app-component-loader
    info-block-image
    [sizePx]=50
    [overTheComponent]="false"
  ></app-component-loader>

  <ng-container info-block-text>
    <ng-container *ngIf="(status$ | async) === 'loading'">
      {{ 'shared.connections.abonents.from.entrances.state.loading' | translate }}
    </ng-container>

    <ng-container *ngIf="(status$ | async) === 'account-checking'">
      {{ 'shared.connections.abonents.from.entrances.state.account_checking' | translate }}
    </ng-container>

    <ng-container *ngIf="(status$ | async) === 'connection'">
      {{ 'shared.connections.abonents.from.entrances.state.connection' | translate:{
        done: (doneConnections$ | async),
        total: (totalConnections$ | async)
      } }}
    </ng-container>
  </ng-container>
</app-info-block>

<ng-container *ngIf="(status$ | async) === 'accounts'">
  <div class="abonents-from-entrances-content-connections">
    <app-info-block contentWidth="50">
      <ng-container info-block-text>
        {{ 'shared.connections.abonents.from.entrances.message.accounts' | translate }}
      </ng-container>
    </app-info-block>

    <app-abonents-list
      [loading]="loading"
      [connections]="connections$ | async"
      [selectedAll]="selectedAll$ | async"
      (connectionSelected)="onSelectConnection($event.selectedValue)"
    ></app-abonents-list>
  </div>

  <div>
    <ng-container
      #connections="var"
      [appVar]="connections$ | async"
    >
      <button
        mat-raised-button
        color="primary"
        (click)="onSelectConnections(connections.appVar)"
        [disabled]="loading || !(connections$ | async)?.length"
        class="abonents-from-entrances-content__button"
      >
        {{ 'shared.connections.abonents.from.entrances.button.select_connections' | translate }}
      </button>
    </ng-container>
  </div>
</ng-container>

<div *ngIf="(status$ | async) === 'success'">
  <app-info-block contentWidth="50">
    <img
      width="50px"
      info-block-image
      [src]="resourcesHelper.getResource(resourcePath.CHECK_LG) | safeUrl"
      [srcset]="resourcesHelper.getResource(resourcePath.CHECK_LG, true) | safeUrl"
    >
    <ng-container info-block-text>
      {{ 'shared.connections.abonents.from.entrances.message.success' | translate }}
    </ng-container>
  </app-info-block>
</div>

<div *ngIf="(status$ | async) === 'error'">
  <app-info-block contentWidth="50">
    <img
      width="50px"
      info-block-image
      [src]="resourcesHelper.getResource(resourcePath.ERROR_LG) | safeUrl"
      [srcset]="resourcesHelper.getResource(resourcePath.ERROR_LG, true) | safeUrl"
    >
    <ng-container info-block-text>
      {{ 'shared.connections.abonents.from.entrances.message.error' | translate }}
    </ng-container>
  </app-info-block>
</div>

<div *ngIf="(status$ | async) === 'account-select'">
  <app-account-selection
    [abonent]="abonent"
    [address]="address"
    [accounts]="accounts"
    [canBeSkipped]="true"
    (selectAccount)="onSelectAccount($event.account, $event.type)"
  ></app-account-selection>
</div>
