import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IMediaStreamInfo } from "../models/p2p.model";

@Injectable({ providedIn: 'root' })
export class MediaStreamService {
  private mediaStreamInfoListSubject = new BehaviorSubject<IMediaStreamInfo[]>([]);
  mediaStreamInfoList$ = this.mediaStreamInfoListSubject.asObservable();

  addMediaStream(value: IMediaStreamInfo): void {
    const currentValue = this.mediaStreamInfoListSubject.value;
    this.mediaStreamInfoListSubject.next([...currentValue, value])
  }

  addTrack(id: number, track: MediaStreamTrack): void {
    const mediaStreamInfo = this.findMediaStreamInfo(id);
    mediaStreamInfo.mediaStream.addTrack(track);
  }

  removeMediaStream(id: number): void {
    const filteredStreamList = this.mediaStreamInfoListSubject.value
      .filter(value => value.id !== id)

    this.mediaStreamInfoListSubject.next(filteredStreamList)
  }

  destroy(): void {
    this.mediaStreamInfoListSubject.value
      .forEach(value => value.mediaStream.getTracks()
          .forEach(track => {
            track.stop();
            value.mediaStream.removeTrack(track);
          })
      );

    this.mediaStreamInfoListSubject.next([]);
  }

  private findMediaStreamInfo(id: number): IMediaStreamInfo {
    return this.mediaStreamInfoListSubject.value
      .find(value => value.id == id)
  }
}
