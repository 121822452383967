import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { DurationString } from "./duration-input.models";
import { RdeaDate } from "@app/shared/entities";
import * as moment from "moment";

const ALL_NUMBERS_LENGTH = 4;

export function minDuration(minimum: DurationString): ValidatorFn {
  const minMinutes = parseInt(minimum.split(':')[0]);
  const minSeconds = parseInt(minimum.split(':')[1]);

  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.getRawValue();

    //If user hasn't entered all the numbers, do not check
    if (value.length < ALL_NUMBERS_LENGTH) {
      return null;
    }

    const [minutes, seconds] = getSplittedDuration(value);
    if (minutes > minMinutes || (minutes === minMinutes && seconds >= minSeconds)) {
      return null;
    }

    return { minDuration: true };
  }
}

export function maxDuration(maximum: DurationString): ValidatorFn {
  const maxMinutes = parseInt(maximum.split(':')[0]);
  const maxSeconds = parseInt(maximum.split(':')[1]);
  
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.getRawValue();
    
    if (value.length < ALL_NUMBERS_LENGTH) {
      return null;
    }

    const [minutes, seconds] = getSplittedDuration(value);
    if (minutes < maxMinutes || (minutes === maxMinutes && seconds === maxSeconds)) {
      return null;
    }

    return { maxDuration: true };
  }
}

export function enterAllNumbers(control: AbstractControl): ValidationErrors | null {
  const value = control.getRawValue();

  if (value.length > 0 && value.length < ALL_NUMBERS_LENGTH) {
    return { enterAllNumbers: true };
  }
  
  const [minutes, seconds] = getSplittedDuration(value);
  if (isNaN(minutes) && isNaN(seconds)) {
    return { enterAllNumbers: true };
  }

  return null;
}
export function durationExceedsMaxTime(maxDate: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value.length < ALL_NUMBERS_LENGTH) {
      return null;
    }

    const formGroup = control.parent;
    const date = formGroup.get('date').getRawValue();
    const duration = formGroup.get('duration').getRawValue();

    if (!duration || !date) {
      return null;
    }

    const [minutes, seconds] = getSplittedDuration(duration);
    const durationInSeconds = seconds + (minutes * 60);

    const remainingTimeInSeconds: number = RdeaDate.getDifferenceBetweenDates( moment.isMoment(date) ? date.toDate() : date, maxDate);
    
    if (durationInSeconds <= remainingTimeInSeconds) {
      return null;
    }

    const [remainingMinutes, remainingSeconds] = getFormatedMinutesSeconds(remainingTimeInSeconds);
    return {durationExceedsMaxTime: true, remainingMinutes, remainingSeconds};
  }
}

 export function getSplittedDuration(value: string): [minutes: number, seconds: number] {
  if (value.includes(':')) {
    const splittedValues = value.split(':');
    return [parseInt(splittedValues[0]), parseInt(splittedValues[1])];
  }

  const splittedValue = value.split('');
  return [
    parseInt(splittedValue[0] + splittedValue[1]), 
    parseInt(splittedValue[2] + splittedValue[3])
  ];
}

function getFormatedMinutesSeconds(remainingTimeInSeconds: number): [string, string] {
  const remainingMinutes = Math.floor(remainingTimeInSeconds / 60);
  const formatedMinutes = remainingMinutes > 10 ? `${remainingMinutes}` : `0${remainingMinutes}`;

    if (remainingTimeInSeconds % 60 === 0) {
      return [formatedMinutes, '00'];
    }

    const remainingSeconds = remainingTimeInSeconds - (remainingMinutes * 60);
    const formatedSeconds = remainingSeconds > 10 ? `${remainingSeconds}` : `0${remainingSeconds}`;
    return [formatedMinutes, formatedSeconds];
}