<div class="timeline-container">
  <div class="timeline" #timeline
    (mousemove)="onTimelineMove($event)" 
    (mouseleave)="onTimelineLeave()"
    (mouseenter)="onTimelineEnter()"
    (mouseup)="onTimelineUp($event)"
  >
    <rd-video-timeline-canvas></rd-video-timeline-canvas>
    <rd-video-timeline-slider (eventMouseUp)="onTimelineUp($event)" [timelineElementRef]="timeline"></rd-video-timeline-slider>
    <rd-video-timeline-tooltip [timelineElementRef]="timeline"></rd-video-timeline-tooltip>
  </div>
  <div class="time-labels">
    <span>{{ formatDate( (selectedDateTime$ | async), 'HH:mm:ss') }}</span>
  </div>
</div>
