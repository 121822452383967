<div class="table-page-container">
  <mat-card>
    <mat-card-header
      class="header"
      [class.header-grid]="rdosImages?.length > 0 && rdosImages[0].link"
    >
      <div class="title-block">
        <mat-card-title>
          {{ 'dashboard.rdos.images.title' | translate }}
        </mat-card-title>
      </div>

      <button
        *ngIf="rdosImages?.length > 0 && rdosImages[0].link"
        mat-raised-button
        color="primary-inverse"
        class="edit-btn"
        matTooltip="{{ 'dashboard.rdos.images.tooltip.get_last_version' | translate }}"
        (click)="onGetLastVersion()"
        [matTooltipPosition]="'before'"
      >
        {{ 'dashboard.rdos.images.button.get_last_version' | translate }}
        <mat-icon>save_alt</mat-icon>
      </button>
    </mat-card-header>

    <hr>

    <mat-card-content class="overview-box-content">
      <mat-table [dataSource]="rdosImages" *ngIf="rdosImages?.length > 0; else emptyData">
        <ng-container matColumnDef="version">
          <mat-header-cell data-label="version" *matHeaderCellDef>
            {{ 'dashboard.rdos.images.field.version' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="version"
            *matCellDef="let element"
          >
            {{ element.version }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="releaseMessage">
          <mat-header-cell data-label="releaseMessage" *matHeaderCellDef>
            {{ 'dashboard.rdos.images.field.release_message' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="releaseMessage"
            *matCellDef="let element"
          >
            {{ element.releaseMessage }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <mat-header-cell data-label="createdAt" *matHeaderCellDef>
            {{ 'dashboard.rdos.images.field.created_at' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="createdAt"
            *matCellDef="let element"
          >
            {{ element.createdAt | date: 'dd.MM.yyyy HH:mm:ss' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell data-label="actions" *matHeaderCellDef></mat-header-cell>
          <mat-cell data-label="actions" *matCellDef="let element">
            <button
              *ngIf="element.link"
              matTooltip="{{ 'dashboard.rdos.images.tooltip.open_link' | translate }}"
              [matTooltipPosition]="'before'"
              mat-icon-button
              (click)="onOpenLink(element)"
            >
              <mat-icon>save_alt</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row
          [style.display]="resolution.isTablet ? 'none' : 'flex'"
          *matHeaderRowDef="displayedColumns"
        >
          {{ 'dashboard.rdos.images.field.actions' | translate }}
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <ng-template #emptyData>
        <app-empty-data *ngIf="rdosImages && rdosImages.length === 0"></app-empty-data>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
