import Hls, { ErrorData } from 'hls.js';

export class RdeaHls extends Hls {
    /**
     * Overrided Hls constructor with auth JWT token and video HTML selector
     * @param token auth JWT token
     * @param {HTMLMediaElement} media video HTML selector
     */
    constructor(
        token: string,
        {
            autoStartLoad = true,
            maxBufferHole = 1,
            liveDurationInfinity = true,
            startFragPrefetch = true,
        }: any = {}
    ) {
        super({
            xhrSetup: (xhr: XMLHttpRequest) => {
                xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            },
            autoStartLoad,
            maxBufferHole,
            liveDurationInfinity,
            startFragPrefetch
        });
    }

    /**
     * Compare error with non fatal errors
     * @param {ErrorData} data error details
     * @returns recoverable error indicator
     */
    recoverableError(data: ErrorData): boolean {
        return data.details === Hls.ErrorDetails.FRAG_PARSING_ERROR;
    }
}
