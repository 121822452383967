export enum EnvironmentLocale {
  ru_RU = 'ru-RU',
  pt_BR = 'pt-BR'
} 

export interface LocaleTranslationModel {
    dayNames: string[],
    dayNamesShort: string[],
    dayNamesMin: string[],
    monthNames: string[],
    monthNamesShort: string[],
    dateFormat: string,
    firstDayOfWeek: number
}