import { registerLocaleData } from "@angular/common";
import localeRu from '@angular/common/locales/ru';
import localePtBr from '@angular/common/locales/pt';
import localeRuExtra from '@angular/common/locales/ru';
import { Injectable } from "@angular/core";
import { EnvironmentLocale, LocaleTranslationModel } from "../models/translate.models";
import { PrimeNGConfig } from "primeng/api";

const PT_TRANSLATION: LocaleTranslationModel = {
  dayNames: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
  dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  monthNames: [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 
    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
  ],
  monthNamesShort: [
    'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 
    'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
  ],
  dateFormat: "dd/mm/yy",
  firstDayOfWeek: 0
};

const RU_TRANSLATION: LocaleTranslationModel = {
  dayNames: ["воскресенье", "понедельник", "вторник", "среда", "четверг", "пятница", "суббота"],
  dayNamesShort: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
  dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  monthNames: ["январь", "февраль", "март", "апрель", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"],
  monthNamesShort: ["янв", "февр", "март", "апр", "май", "июнь", "июль", "авг", "сент", "окт", "нояб", "дек"],
  dateFormat: "dd.mm.yy",
  firstDayOfWeek: 1
}

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private currentLocale: EnvironmentLocale;

  constructor(private primengConfig: PrimeNGConfig) {}
  
  setLocale(locale: EnvironmentLocale): void {
    this.currentLocale = locale;

    switch(locale) {
      case EnvironmentLocale.pt_BR: 
        registerLocaleData(localePtBr);
        this.translateLabels(PT_TRANSLATION);
        break;
      default:
        registerLocaleData(localeRu, locale, localeRuExtra);
        this.translateLabels(RU_TRANSLATION);
    }
  }

  getCurrentLocale(): EnvironmentLocale {
    return this.currentLocale;
  }

  private translateLabels(translation: LocaleTranslationModel): void {
    this.primengConfig.setTranslation(translation);
  }
}

