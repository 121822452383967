import { Injectable } from "@angular/core";
import { IMessageService, MessageSignalPayload } from "../models/message-service.model";
import { Observable, of } from "rxjs";
import { HttpApiService } from "./http-api.service";
import { takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { take } from "rxjs/operators";

const SINGLE_TAKE = 1;

@Injectable({ providedIn: 'root' })
export class MessageService implements IMessageService {

  constructor(private httpApiService: HttpApiService) { }

  send(event: string, messagePayload: MessageSignalPayload): void {
    this.httpApiService.postSessionDescription(messagePayload)
      .pipe(
        take(SINGLE_TAKE),
        takeUntilDestroyed(this, { destroyMethod: this.destroy })
      )
      .subscribe()
  }

  on<T = void>(event: string): Observable<T> {
    return this.httpApiService.response$ as Observable<T>;
  }

  destroy(): void { }
}
