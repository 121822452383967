import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ProgressBarMode } from '@angular/material/progress-bar';
import { LoaderService } from '@app/shared/entities/common';
import { DialogWrapperService } from './dialog-wrapper.service';
import { DialogWrapperSize } from '@app/shared/ui';
import { UrlService } from '@app/shared/services';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-wrapper',
  templateUrl: './dialog-wrapper.component.html',
  styleUrls: ['./dialog-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DialogWrapperComponent implements OnInit, OnDestroy {
  readonly mode: ProgressBarMode = 'indeterminate';
  private onDestroy$: Subject<void> = new Subject();
  private _title: string;

  public linkText: string;

  @Input() componentName!: string;
  @Input() progress: boolean;
  @Input() hasBackButton?: boolean;
  @Input() size: DialogWrapperSize;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() back: EventEmitter<void> = new EventEmitter<void>();
  loading: boolean;

  public readonly DialogWrapperSize = DialogWrapperSize;

  constructor(
    public dialogWrapperService: DialogWrapperService,
    private loader: LoaderService,
    private changeDetectorRef: ChangeDetectorRef,
    private urlService: UrlService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.initLoaderListener();

  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }


  private initLoaderListener() {
    this.loader.getComponentLoaderState()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(state => {
        this.loading = state;
        this.changeDetectorRef.detectChanges();
      });
  }

  get link(): string {
    return this.urlService.links[this.title];
  }

  get title(): string {
    return this._title;
  }

  @Input()
  set title(v: string) {
    this._title = v;
    this.linkText = (v.includes(this.translate.instant('shared.ui.dialog_wrapper.addition'))
      ? this.translate.instant('shared.ui.dialog_wrapper.add')
      : this.translate.instant('shared.ui.dialog_wrapper.tune')).toLowerCase();
  }
}
