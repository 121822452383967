<mat-table class="abonents-table"
  [dataSource]="delegations"
  *ngIf="delegations?.length > 0; else emptyDelegations"
>

  <ng-container matColumnDef="value">
    <mat-header-cell *matHeaderCellDef data-label="value">
      {{ 'abonent.delegations.field.phone' | translate }}
    </mat-header-cell>
    <mat-cell
      data-label="value"
      *matCellDef="let element"
    >
      {{ element[toAbonent ? 'toAbonent' : 'fromAbonent'].phone?.toString() | phone }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="resolved">
    <mat-header-cell data-label="resolved" *matHeaderCellDef>
      {{ 'abonent.delegations.field.status' | translate }}
    </mat-header-cell>
    <mat-cell
      data-label="resolved"
      *matCellDef="let element"
    >
      <mat-chip-list [class.mat-chip-list--place-content]="resolution.isTablet">
        <mat-chip
          [disabled]="true"
          [selectable]="false"
          color="{{ element[toAbonent ? 'toAbonent' : 'fromAbonent'].resolved ? 'success' : 'error' }}"
        >
          {{
            element[toAbonent ? 'toAbonent' : 'fromAbonent'].resolved
              ? ('abonent.delegations.enum.status.processed' | translate)
              : ('abonent.delegations.enum.status.not_processed' | translate)
          }}
        </mat-chip>
      </mat-chip-list>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell data-label="actions" *matHeaderCellDef></mat-header-cell>
    <mat-cell data-label="actions" *matCellDef="let element">
      <button mat-icon-button color="error"
        *ngIf="deleteEnabled"
        (click)="onDeleteDelegation(element.id)"
      >
        <mat-icon aria-label="Delete">delete</mat-icon>
      </button>

      <button mat-icon-button
        *ngIf="changeActiveEnabled"
        matTooltip="{{ 'abonent.delegations.tooltip.change_delegated_abonent' | translate }}"
        (click)="changeDelegatedAbonent(element.fromAbonent.id)"
      >
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>

      <a mat-icon-button
        [routerLink]="['../' + element[toAbonent ? 'toAbonent' : 'fromAbonent'].id]"
        [queryParams]="{ phone: element[toAbonent ? 'toAbonent' : 'fromAbonent'].phone?.toString() }"
      >
        <mat-icon aria-label="Edit">navigate_next</mat-icon>
      </a>
    </mat-cell>
  </ng-container>

  <mat-header-row
    [style.display]="resolution.isTablet ? 'none' : 'flex'"
    *matHeaderRowDef="displayedAbonentsColumns"
  ></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedAbonentsColumns;"></mat-row>
</mat-table>

<ng-template #emptyDelegations>
  <app-empty-data></app-empty-data>
</ng-template>
