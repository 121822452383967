import { Rdva } from "@app/shared/models";

export namespace VideoManagerN {
  export const lsScreensKey = 'screens';
  export enum ViewVariants {
    one = 1,
    largeOneAndFourOnRightSide,
    twoByTwo,
    threeByThree,
    fourByFour,
    fiveByFive,
    sixBySix,
    sevenBySeven,
    eightByEight,
  }

  export interface Screen {
    id: number;
    name: string;
    layout: ViewVariants;
    cameras: ScreenItem[];
  }

  export interface ScreenItem {
    index: number;
    cameraId: number;
    camera?: Camera;
  }

  export interface Camera {
    id: number;
    configuration: string;
    rdva: Rdva;
  }

  export type AddCameraModalInput = ScreenItem;

  export interface AddCameraModalOutput {
    originalInput: AddCameraModalInput;
    selectedCamera: Camera;
  }

  export function GetViewScheme(variant: VideoManagerN.ViewVariants, hideMore?: boolean): string {
    switch (variant) {
      case VideoManagerN.ViewVariants.one:
        return 'view-scheme--one';
      case VideoManagerN.ViewVariants.largeOneAndFourOnRightSide:
        return 'view-scheme--1-and-4-on-right-side';
      case VideoManagerN.ViewVariants.twoByTwo:
        return 'view-scheme--two-by-two';
      case VideoManagerN.ViewVariants.threeByThree:
        return 'view-scheme--three-by-three';
      case VideoManagerN.ViewVariants.fourByFour:
        return hideMore ? 'view-scheme--one' : 'view-scheme--four-by-four';
      case VideoManagerN.ViewVariants.fiveByFive:
        return hideMore ? 'view-scheme--one' : 'view-scheme--five-by-five';
      case VideoManagerN.ViewVariants.sixBySix:
        return hideMore ? 'view-scheme--one' : 'view-scheme--six-by-six';
      case VideoManagerN.ViewVariants.sevenBySeven:
        return hideMore ? 'view-scheme--one' : 'view-scheme--seven-by-seven';
      case VideoManagerN.ViewVariants.eightByEight:
        return hideMore ? 'view-scheme--one' : 'view-scheme--eight-by-eight';
      default:
        return 'view-scheme--one';
    }
  }

  export function GetMaxBlockByViewScheme(variant: VideoManagerN.ViewVariants): number {
    switch (variant) {
      case VideoManagerN.ViewVariants.one:
        return 1;
      case VideoManagerN.ViewVariants.largeOneAndFourOnRightSide:
        return 5;
      case VideoManagerN.ViewVariants.twoByTwo:
        return 4;
      case VideoManagerN.ViewVariants.threeByThree:
        return 9;
      case VideoManagerN.ViewVariants.fourByFour:
        return 16;
      case VideoManagerN.ViewVariants.fiveByFive:
        return 25;
      case VideoManagerN.ViewVariants.sixBySix:
        return 36;
      case VideoManagerN.ViewVariants.sevenBySeven:
        return 49;
      case VideoManagerN.ViewVariants.eightByEight:
        return 64;
      default:
        return 1;
    }
  }

  export function GetBlockColumnsByViewScheme(variant: VideoManagerN.ViewVariants): number {
    switch (variant) {
      case VideoManagerN.ViewVariants.one:
        return 1;
      case VideoManagerN.ViewVariants.largeOneAndFourOnRightSide:
        return 0;
      case VideoManagerN.ViewVariants.twoByTwo:
        return 2;
      case VideoManagerN.ViewVariants.threeByThree:
        return 3;
      case VideoManagerN.ViewVariants.fourByFour:
        return 4;
      case VideoManagerN.ViewVariants.fiveByFive:
        return 5;
      case VideoManagerN.ViewVariants.sixBySix:
        return 6;
      case VideoManagerN.ViewVariants.sevenBySeven:
        return 7;
      case VideoManagerN.ViewVariants.eightByEight:
        return 8;
      default:
        return 1;
    }
  }

  export function GetBlockRowByViewScheme(variant: VideoManagerN.ViewVariants): number {
    switch (variant) {
      case VideoManagerN.ViewVariants.one:
        return 1;
      case VideoManagerN.ViewVariants.largeOneAndFourOnRightSide:
        return 0;
      case VideoManagerN.ViewVariants.twoByTwo:
        return 2;
      case VideoManagerN.ViewVariants.threeByThree:
        return 3;
      case VideoManagerN.ViewVariants.fourByFour:
        return 4;
      case VideoManagerN.ViewVariants.fiveByFive:
        return 5;
      case VideoManagerN.ViewVariants.sixBySix:
        return 6;
      case VideoManagerN.ViewVariants.sevenBySeven:
        return 7;
      case VideoManagerN.ViewVariants.eightByEight:
        return 8;
      default:
        return 1;
    }
  }
}
