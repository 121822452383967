import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

import {
  AbonentConnectionToolData,
  SnackbarService
} from '@app/shared/components';
import { ResourcePath, ResourcesHelper } from '@app/shared/entities';
import {
  PhoneModel,
  PhonesUtils,
  ServiceInfoResponse,
  ServiceResponse
} from '@app/shared/entities/rd';
import { Address } from '@app/shared/models';
import { AddressFormatter } from '@app/shared/services';
import { PhonePipe } from '@app/shared/pipes';
import { ServiceEntranceFlats } from '@app/views/services/components';
import { Dictionary } from '@app/shared/helpers/dictionary';
import { TranslateService } from '@ngx-translate/core';
import { ServiceSelectedArgs } from '../../models';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
@Component({
  selector: 'app-abonent-connection-tool-content',
  templateUrl: './abonent-connection-tool-content.component.html',
  styleUrls: ['./abonent-connection-tool-content.component.scss'],
  providers: [PhonePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbonentConnectionToolContentComponent implements OnInit {
  readonly resourcePath: typeof ResourcePath = ResourcePath;

  @Input() phone: number;
  @Input() loading: boolean;
  @Input() flatId: number;
  @Input() entranceId: number;
  @Input() flatNumber: number;
  @Input() flatList: Dictionary<ServiceEntranceFlats>;
  @Input() addresses: Address[];
  @Input() servicesLoading: boolean;
  @Input() virtualFlat: boolean;
  @Input() hasPhysicalTubeDefault: boolean;
  @Input() hasPhysicalTubeHidden: boolean;
  @Input() services: ServiceInfoResponse[];
  @Input() noPhone: boolean;
  @Input() set abonentDataDisabled(abonentDataDisabled: boolean) {
    this.prepareFormState(abonentDataDisabled);
  }
  @Input() servicesSelectionRequired: boolean;
  @Input() abonentAndAccountLoadingForced: boolean;
  @Input() defaultService: Pick<ServiceResponse, 'id' | 'type'>;
  @Input() selectedServices: Pick<ServiceResponse, 'id' | 'type'>[];
  @Input() connectedServices: Dictionary<boolean>;
  @Input() servicesForDelete: Pick<ServiceResponse, 'id' | 'type'>[];
  flatsFilterInputValue: string;
  form: UntypedFormGroup = this.initForm();
  noPhoneChecked = false;

  @Output() private changeServiceSelection: EventEmitter<{
    services: Pick<ServiceResponse, 'id' | 'type'>[];
  }> = new EventEmitter();

  @Output() private submitConnection: EventEmitter<AbonentConnectionToolData> =
    new EventEmitter();
  @Output() private abonentDataEntered: EventEmitter<{
    entranceId: number;
    flatNumber: number;
    phone: number;
  }> = new EventEmitter();
  @Output() private changePhysicalTubeAvailable: EventEmitter<{
    hasPhysicalTube: boolean;
  }> = new EventEmitter();

  constructor(
    public resourcesHelper: ResourcesHelper,
    private snackbar: SnackbarService,
    private phonePipe: PhonePipe,
    private addressFormatter: AddressFormatter,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (this.phone && String(this.phone).length === 11) {
      const phoneModel: PhoneModel = PhonesUtils.parsePhone(this.phone);
      if (phoneModel) {
        this.form.get('phone').setValue(phoneModel);
        this.form.get('phone').disable();
      }
    }

    if (this.flatNumber) {
      this.form.get('flatNumber').setValue(this.flatNumber);
      this.flatNumber
        ? this.form.get('flatNumber').disable()
        : this.form.get('flatNumber').enable();
    }
    this.addresses = this.addresses.filter((address) => !!address);

    this.form.get('entranceId').enable();
    if (this.addresses?.length) {
      this.form.get('entranceId').setValue(this.addresses[0]?.entrance.id);

      if (this.addresses?.length === 1) {
        this.form.get('entranceId').disable();
      }

      if (this.addresses?.length > 1) {
        this.form.get('entranceId').enable();
      }
    }

    if (this.addresses?.length > 0 && this.flatNumber) {
      this.onContinue();
    }
  }

  onContinue() {
    if (!this.checkFormForContinue()) {
      return;
    }

    const {
      entranceId,
      flatNumber,
      phone
    }: { entranceId: number; flatNumber: number; phone: PhoneModel } =
      this.form.getRawValue();

    this.abonentDataEntered.emit({
      entranceId,
      flatNumber,
      phone: PhonesUtils.toNumber(phone)
    });
  }

  onServiceSelected(services: ServiceSelectedArgs) {
    this.changeServiceSelection.emit({ services: services.servicesToAdd }); 
  }

  onConnect() {
    if (!this.checkFormForConnect()) {
      return;
    }

    if (this.defaultService) {
      this.selectedServices = [...this.selectedServices, this.defaultService];
    }

    const request: AbonentConnectionToolData = {
      phone: !this.noPhoneChecked
        ? PhonesUtils.toNumber(this.form.get('phone').value)
        : undefined,
      entranceId: this.form.get('entranceId').value,
      services: this.selectedServices,
      servicesForDelete: this.servicesForDelete,
      hasPhysicalTube: this.hasPhysicalTubeDefault,
      withoutPhoneNumber: this.noPhoneChecked,
      loadAbonentAndAccounts:
        this.selectedServices?.length > 0 ||
        !!this.defaultService ||
        this.abonentAndAccountLoadingForced
    };

    this.submitConnection.emit(request);
  }

  onChangeNoPhoneChecked(event: MatSlideToggleChange) {
    this.noPhoneChecked = event.checked;
    event.checked
      ? this.form.get('phone').disable()
      : this.form.get('phone').enable();
  }

  onChangeHasPhysicalTube(event: MatSlideToggleChange) {
    this.changePhysicalTubeAvailable.emit({ hasPhysicalTube: event.checked });
  }

  onInputUpdate(value: Event): void {
    this.flatsFilterInputValue = (value.target as HTMLInputElement).value
  }

  getRangesFromEntrance(): { addressString: string; rangesString: string }[] {
    const entranceId: number = this.form.get('entranceId').value;
    const ranges: { addressString: string; rangesString: string }[] = [];
    const address = this.addresses.find((a) => a.entrance.id === entranceId);
    const entrancesRanges: string[] = [];

    entrancesRanges.push(
      `${address.entrance.flatStart} - ${address.entrance.flatEnd}`
    );

    address.entrance.additionalFlatRanges.forEach((flatRange) =>
      entrancesRanges.push(`${flatRange.flatStart} - ${flatRange.flatEnd}`)
    );

    ranges.push({
      addressString: this.addressFormatter.formatAddress(address),
      rangesString: entrancesRanges.join(', ')
    });

    return ranges;
  }

  private initForm() {
    return new UntypedFormGroup({
      entranceId: new UntypedFormControl('', Validators.required),
      phone: new UntypedFormGroup({
        number: new UntypedFormControl('', Validators.required),
        prefix: new UntypedFormControl('', Validators.required)
      }),
      flatNumber: new UntypedFormControl('', Validators.required)
    });
  }

  private checkFormForContinue() {
    if (!this.form.get('entranceId').value) {
      this.snackbar.showMessage(
        this.translate.instant('shared.connections.tool.content.message.specify_address'),
        'info'
      );
      return false;
    }

    if (!this.form.get('flatNumber').value) {
      this.snackbar.showMessage(
        this.translate.instant('shared.connections.tool.content.message.specify_flat_number'),
        'info'
      );
      return false;
    }

    const flatNumber: number = this.form.get('flatNumber').value;
    const entranceId: number = this.form.get('entranceId').value;

    if (this.virtualFlat && this.outOfRange(entranceId, flatNumber)) {
      this.snackbar.showMessage(
        this.translate.instant('shared.connections.tool.content.message.specify_flat_number_range'),
        'info'
      );
      return false;
    }

    if (this.isVirtualFlatBusy(flatNumber) && this.virtualFlat) {
      this.snackbar.showMessage(
        this.translate.instant('shared.connections.tool.content.message.virtual_apartment_number_is_busy'),
        'info'
      );
      return false;
    }

    return true;
  }

  private outOfRange(entranceId: number, flatNumber: number) {
    const foundAddress = this.addresses.find(
      (address) => address.entrance.id === entranceId
    );

    if (
      flatNumber >= foundAddress.entrance.flatStart &&
      flatNumber <= foundAddress.entrance.flatEnd
    ) {
      return true;
    }

    if (foundAddress.entrance.additionalFlatRanges) {
      for (const flatRange of foundAddress.entrance.additionalFlatRanges) {
        if (
          flatNumber >= flatRange.flatStart &&
          flatNumber <= flatRange.flatEnd
        ) {
          return true;
        }
      }
    }

    return false;
  }

  private checkFormForConnect(): boolean {
    if (!this.form.get('entranceId').value) {
      this.snackbar.showMessage(
        this.translate.instant('shared.connections.tool.content.message.specify_address'),
        'info'
      );
      return false;
    }

    if (!this.form.get('flatNumber').value) {
      this.snackbar.showMessage(
        this.translate.instant('connections.tool.content.message.specify_flat_number'),
        'info'
      );
      return false;
    }

    const flatNumber: number = this.form.get('flatNumber').value;
    const entranceId: number = this.form.get('entranceId').value;

    if (this.virtualFlat && this.outOfRange(entranceId, flatNumber)) {
      this.snackbar.showMessage(
        this.translate.instant('shared.connections.tool.content.message.specify_flat_number_range'),
        'info'
      );
      return false;
    }

    if (
      !this.noPhoneChecked &&
      (!this.phone || !(String(this.phone).length === 11))
    ) {
      if (!this.form.get('phone').valid) {
        this.snackbar.showMessage(
          this.translate.instant('shared.connections.tool.content.message.specify_phone'),
          'info'
        );
        return false;
      }
    }

    if (this.noPhoneChecked && this.selectedServices?.length) {
      if (!this.form.get('phone').valid) {
        this.snackbar.showMessage(
          this.translate.instant('shared.connections.tool.content.message.specify_phone'),
          'info'
        );
        return false;
      }
    }

    if (
      !this.selectedServices?.length &&
      !this.defaultService &&
      this.servicesSelectionRequired
    ) {
      this.snackbar.showMessage(
        this.translate.instant('shared.connections.tool.content.message.choose_least_one_service_connecting'),
        'info'
      );
      return false;
    }

    return true;
  }

  private prepareFormState(abonentDataDisabled: boolean) {
    if (abonentDataDisabled) {
      this.form.get('flatNumber').disable();
      this.form.get('entranceId').disable();
      return;
    }

    this.form.enable();

    if (this.phone && this.phonePipe.transform(String(this.phone))) {
      this.form.get('phone').disable();
    }

    if (this.flatNumber) {
      this.form.get('flatNumber').disable();
    }

    if (this.addresses?.length === 1) {
      this.form.get('entranceId').disable();
    }
  }

  getFlatRangeArray(flatStart: number, flatEnd: number): number[] {
    return Array.from(
      { length: flatEnd - flatStart + 1 },
      (v, k) => k + flatStart
    );
  }

  public ifFlatBusy(flatNumber: number): boolean {
    return !!this.flatList[this.selectedAddress.entrance.id].flats[flatNumber]
      ?.account;
  }

  public get selectedAddress(): Address {
    return this.addresses.find(
      (address) => address?.entrance.id === this.form.get('entranceId').value
    );
  }

  public get flatRange(): number[] {
    const additionalRanges = [];
    if (this.selectedAddress) {
      this.selectedAddress.entrance.additionalFlatRanges.forEach((range) =>
        additionalRanges.push(
          ...this.getFlatRangeArray(range.flatStart, range.flatEnd)
        )
      );
      let selectedAddressRanges = this.getFlatRangeArray(
        this.selectedAddress.entrance.flatStart,
        this.selectedAddress.entrance.flatEnd
      );
      if (this.flatsFilterInputValue) {
        selectedAddressRanges = selectedAddressRanges.filter((flatNumber) =>
          flatNumber.toString().includes(this.flatsFilterInputValue)
        );
      }

      return [...selectedAddressRanges, ...additionalRanges];
    }
  }

  public onHardwareIntercomeChecked(checked: boolean): void {
    this.hasPhysicalTubeDefault = checked;
  }

  private isVirtualFlatBusy(flatNumber: number): boolean {
    return !!this.flatList[this.selectedAddress.entrance.id].virtualFlats[flatNumber];
  }
}
