import { Inject, Injectable } from "@angular/core";
import { PeerConnectionService } from "../peer-connection/peer-connection.service";
import { IVideoConfig, IVideoConfigHls, IVideoConfigWebRTC } from "../models/video.models";
import { SignalService } from "../signal-serivice/signal.service";
import { HttpApiService } from "../message-service/http-api.service";
import { HlsConnectionService } from "../hls-connection.service/hls-connection.service";
import { MediaService } from "../media.service/media.service";
import { RdeaHls } from "@app/shared/components/video-player";
import { PlayerService } from "./player.service";
import { Option } from "../components/select-control/select-control";
import { TimelineData } from "../models/timeline.model";

@Injectable({ providedIn: 'root' })
export class RdVideoService {
  mediaStreamList$ = this.mediaService.mediaInfoList$;

  constructor(
    private signalService: SignalService,
    private httpApiService: HttpApiService,
    private mediaService: MediaService,
    private peerConnectionService: PeerConnectionService,
    private hlsConnectionService: HlsConnectionService,
    private playerService: PlayerService
  ) { }

  addReceiverWebRTC(config: IVideoConfigWebRTC): void {
    this.signalService.onSignal();
    this.peerConnectionService.createPeerConnection(config.id)
  }

  addReceiverHls(config: IVideoConfigHls): RdeaHls {
    return this.hlsConnectionService.createHlsConnection(config)
  }

  loadSourceHls(id: number, url: string): void {
    this.hlsConnectionService.loadSource(id, url)
  }

  updateSelectedPlayerTime(time: number): void {
    this.playerService.updateSelectedPlayerTime(time);
  }

  updateSelectPlaylistPlayer(item: Option): void {
    this.playerService.updateSelectedPlaylistPlayer(item);
  }

  updatePlayerTimelineData(timelineData: TimelineData): void {
    this.playerService.updateTimelineDataSubject(timelineData);
  }

  destroyWebRTCStream(id: number): void {
    this.signalService.destroy();
    this.peerConnectionService.destroyPeerConnection(id);
  }

  destroyHlsStream(id: number): void {
    this.hlsConnectionService.destroyHls(id);
  }

  destroy(): void {
    this.signalService.destroy();
    this.peerConnectionService.destroy();
    this.hlsConnectionService.destroy();
    this.mediaService.destroy();
  }
}
