import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Address, ServicesTypes } from '@app/shared/models';

import { ServiceFlat } from '@app/views/services/components';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-flat-service',
  templateUrl: './flat-service.component.html',
  styleUrls: ['./flat-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlatServiceComponent {
  @Input() flat: ServiceFlat;
  @Input() serviceType: string;
  @Input() address: Address;
  @Input() mdWUpBreakpoint: boolean;
  @Input() xlgWDownBreakpoint: boolean;
  @Input() abonentsBillingEnabled: boolean;
  @Output() addService: EventEmitter<{
    address: Address;
    flat: Partial<ServiceFlat>;
  }> = new EventEmitter();
  @Output() blockService: EventEmitter<{ flat?: ServiceFlat }> = new EventEmitter();

  readonly servicesType = ServicesTypes;

  constructor(
    private translate: TranslateService
  ) {}

  onAddService(address: Address, flat: ServiceFlat) {
    this.addService.emit({ address, flat });
  }

  onBlockService(event: MatSlideToggleChange, flat: ServiceFlat) {
    const blocked: boolean = flat.services
      ? flat.services[this.serviceType]?.blocked
      : false;

    event.source.checked = !blocked;
    this.blockService.emit({ flat });
  }

  public get toggleStatus(): boolean {
    const hasAccount = !!this.flat?.account;
    if (hasAccount) {
      return (
        !this.flat.services[this.serviceType]?.account?.blocked &&
        !this.flat.services[this.serviceType]?.blocked
      );
    } else {
      return !this.flat.services[this.serviceType]?.blocked;
    }
  }

  public get isAccountBlocked(): boolean {
    return this.flat?.services && this.flat.services[this.serviceType]?.account?.blocked;
  }

  public get isServiceActive(): boolean {
    return !this.flat?.services[this.serviceType].blocked;
  }

  public get isHWIntercom(): boolean {
    return this.serviceType === ServicesTypes.HARDWARE_INTERCOM;
  }

  public get isSWIntercom(): boolean {
    return this.serviceType === ServicesTypes.SOFTWARE_INTERCOM;
  }

  public get title(): string {
    switch (this.serviceType) {
      case ServicesTypes.GATE:
        return this.translate.instant('shared.flat.service.enum.type.gate');
      case ServicesTypes.HARDWARE_INTERCOM:
        return this.translate.instant('shared.flat.service.enum.type.hardware_intercom');
      case ServicesTypes.VIDEO_SURVEILLANCE:
        return this.translate.instant('shared.flat.service.enum.type.video_surveillance');
      default:
        return this.translate.instant('shared.flat.service.enum.type.default');
    }
  }
}
