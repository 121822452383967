import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { RdVideoService } from "../../service/video.service";
import { PlayerService } from "../../service/player.service";
import { DecorateUntilDestroy, takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { PlayerState, PlayerStateEnum, StateType } from "../../models/state.model";
import { filter } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";

enum PlayerIconUrlEnum {
  play = 'assets/icons/pause.svg',
  pause = 'assets/icons/play.svg'
} 

@DecorateUntilDestroy()
@Component({
  selector: 'rd-video-play-control',
  templateUrl: './play-control.html'
})
export class RdVideoPlayControlComponent implements OnInit {
  @Input() streamId: number;

  @Output() clickEvent = new EventEmitter();

  private iconUlrSubject = new BehaviorSubject<PlayerIconUrlEnum>(PlayerIconUrlEnum.pause);
  iconUlr$ = this.iconUlrSubject.asObservable();


  public playerState: PlayerStateEnum;

  constructor(private rdVideoService: RdVideoService, private playerService: PlayerService) { }

  ngOnInit(): void {
    this.playerService.stateSubject$
      .pipe(
        filter((state) => state.type === StateType.PlayerState,),
        takeUntilDestroyed(this)
      )
      .subscribe(state => {
        this.playerState = state.value;
        this.iconUlrSubject.next(PlayerIconUrlEnum[state.value]);
      })
  }

  ngOnDestroy(): void {
    this.playerService.resetState();
  }

  onControlClick(): void {
    const state = this.playerState === PlayerStateEnum.PLAY ? PlayerStateEnum.PAUSE : PlayerStateEnum.PLAY;
    this.iconUlrSubject.next(PlayerIconUrlEnum[state]);

    this.playerService.updateState({
      type: StateType.UpdatePlayerState,
      value: state
    })

    this.clickEvent.emit();
  }
}
