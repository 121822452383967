import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "@app/shared/api";
import { environment } from 'environments/environment';
import { HumanErrorTranslationService } from "@app/shared/services/human-error-translation.service";
import { Observable, Subject } from "rxjs";
import { MessageSignalPayload } from "../models/message-service.model";
import { tap } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class HttpApiService extends ApiService {
  private responseSubject = new Subject<MessageSignalPayload>();
  response$ = this.responseSubject.asObservable();

  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.camerasApiUrl, ['v1', 'v2'])
  }

  postSessionDescription(message: MessageSignalPayload): Observable<MessageSignalPayload> {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.post<MessageSignalPayload>('/webrtc', 1, JSON.stringify(message), { headers })
      .pipe(
        tap(res => this.responseSubject.next(res))
      )
  }
}
