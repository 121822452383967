export enum LocalStorageCompanyKey {
    NAME = 'rdea:CompanyName',
    SHORT_NAME = 'rdea:CompanyShortName',
    IS_BLOCKED = 'rdea:CompanyIsBlocked',
    MANAGER_EMAIL = 'rdea:CompanyManagerEmail',
    MANAGER_NAME = 'rdea:CompanyManagerName',
    SUPPORT_PHONE = 'rdea:CompanySupportPhone',
    IS_ZAMMAD_ORGANIZATION_EXISTING = 'rdea:IsZammadOrganizationExisting',
    ZAMMAD_ORGANIZATION_ID = 'rdea:ZammadOrganizationId',
    INN = 'rdea:CompanyInn',
    ID = 'rdea:CompanyId',
    TICKET_STATES = 'rdea:TicketStates',
    COMPANY_ID_WITH_EXPERIMENT_WEBRTC = 'rdea:CompanyIdWithExperimentWebRTC'
}
