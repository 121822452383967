<div class="popup video-player-videoshot-container">
  <ng-container [ngSwitch]="state">
    <ng-container *ngSwitchCase="'create'">
      <div class="video-player-videoshot-container-header">
        <h3>
          {{ 'shared.video_player.video_shot.popup.title' | translate }}
        </h3>
        <p>
          {{ 'shared.video_player.video_shot.popup.message.depth_the_video_archive_camera' | translate }}:&nbsp;
          {{ depthInHours }} {{ 'shared.video_player.video_shot.popup.message.depth_the_video_archive_camera_hour' | translate }}
        </p>
        <p>
          {{ 'shared.video_player.video_shot.popup.message.maximum_fragment_length' 
          | translate: {minutes: maxMinutes, seconds: maxSeconds} }}
        </p>
        <p *ngIf="minDate && maxDate">
          {{ 'shared.video_player.video_shot.popup.message.available_time_from_to' | translate:{
            from: (minDate | date: 'dd.MM.yyyy HH:mm:ss'),
            to: (maxDate | date: 'dd.MM.yyyy HH:mm:ss')
          } }}
        </p>
      </div>

      <form [formGroup]="form" (submit)="onCreateVideoshotTask()" autocomplete="off">
        <div class="popup-content video-player-videoshot-container-content">
          <mat-form-field class="form__date-time-picker" appearance="outline">
            <mat-label>
              {{ 'shared.video_player.video_shot.popup.field.date' | translate }}
            </mat-label>
            <input
              matInput
              [min]="minDateMomentFormat"
              [max]="maxDateMomentFormat"
              [ngxMatDatetimePicker]="picker1"
              placeholder="{{ 'shared.video_player.video_shot.popup.placeholder.date' | translate }}"
              formControlName="date"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              required
            >
            <mat-datepicker-toggle matSuffix [for]="picker1" [disabled]="loading">
              <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
            </mat-datepicker-toggle>

            <ngx-mat-datetime-picker
              #picker1
              [showSpinners]="false"
              [showSeconds]="true"
              [touchUi]="mdWDownBreakpoint$ | async"
              [disabled]="loading"
            ></ngx-mat-datetime-picker>

            <mat-error *ngIf="dateControl?.hasError('required')">
              {{'shared.video_player.video_shot.popup.message.field_required' | translate }}
            </mat-error>
          </mat-form-field>

            <app-duration-input 
              [durationControl]="durationControl" 
              [minDuration]="minTimeDuration"
              [maxDuration]="maxTimeDuration"
              [maxDate]="maxDate"
            >
            </app-duration-input>
        </div>

        <div class="popup-actions video-player-videoshot-container-actions">
          <button
            mat-raised-button
            type="submit"
            color="primary"
            class="popup-actions__button"
            [disabled]="!form.valid || loading"
          >
            <app-component-loader
              [whiteCircle]="true"
              *ngIf="loading; else btnText"
            ></app-component-loader>
            <ng-template #btnText>
              {{ 'shared.video_player.video_shot.popup.button.create' | translate }}
            </ng-template>
          </button>
        </div>
      </form>
    </ng-container>

    <ng-container *ngSwitchCase="'loading'">
      <div class="spinner-container">
        <p-progressSpinner ariaLabel="loading" />
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="popup-content video-player-videoshot-container-content">
        <p>
          {{ 'shared.video_player.video_shot.popup.message.download_created_fragment_it_can_take_some_time' | translate }}
        </p>
      </div>

      <div class="popup-actions video-player-videoshot-container-actions--double">
        <button
          mat-raised-button
          color="primary"
          class="popup-actions__button"
          [disabled]="loading"
          (click)="onDownloadVideoshot()"
        >
          <app-component-loader
            [whiteCircle]="true"
            *ngIf="loading; else btnText"
          ></app-component-loader>
          <ng-template #btnText>
            {{ 'shared.video_player.video_shot.popup.button.download' | translate }}
          </ng-template>
        </button>

        <button
          mat-raised-button
          color="primary-inverse"
          class="popup-actions__button"
          [disabled]="loading"
          (click)="onInitVideoshotBuilding()"
        >
          {{ 'shared.video_player.video_shot.popup.button.new' | translate }}
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>
