<app-paginated-page-wrapper
  #paginatedPageWrapper
  filterInputPlaceholder="{{ 'entrances.page.placeholder.filter' | translate }}"
  [paginatorData]="pageData$ | async"
  (filterInputChanged)="onFilterInputChanged($event)"
  (pageChanged)="onPageChanged()"
>
  <ng-container page-template-selection>
    <ngx-skeleton-loader
      *ngIf="countsLoading$ | async; else navbarBlock"
      count="1"
      [theme]="{
        'border-radius': '8px',
        'margin-bottom': '0px',
        'margin-top': '10px',
        'height': '40px'
    }"></ngx-skeleton-loader>

    <ng-template #navbarBlock>
      <app-navbar
        class="entrances-page-counts"
        [links]="counts$ | async"
        [active]="currentState"
        (select)="onChangeTab($event.idx)"
      ></app-navbar>
    </ng-template>
  </ng-container>

  <ng-container page-template-content>
    <mat-table
        [dataSource]="entrances$ | async"
        *ngIf="(pageData$ | async).totalElements !== 0; else emptyData"
      >
        <ng-container matColumnDef="address">
          <mat-header-cell *matHeaderCellDef>
            {{ 'entrances.page.field.address' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="address"
            *matCellDef="let element"
          >
            {{ element.address }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="rdaState">
          <mat-header-cell data-label="rdaState" *matHeaderCellDef>
            {{ 'entrances.page.field.rda_state' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="rdaState"
            *matCellDef="let element"
          >
            <ng-container *ngIf="element.intercomStatus !== null; else rdaNotInstalled">
              <mat-chip
                [disabled]="true"
                [selectable]="false"
                color="{{ element.intercomStatus ? 'success' : 'error' }}"
              >
                {{
                  element.intercomStatus
                    ? ('entrances.page.enum.state.online' | translate)
                    : ('entrances.page.enum.state.offline' | translate)
                }}
              </mat-chip>
            </ng-container>
            <ng-template #rdaNotInstalled>
              <mat-chip
                [disabled]="true"
                [selectable]="false"
              >
                {{ 'entrances.page.message.not_installed' | translate }}
              </mat-chip>
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="camerasStates">
          <mat-header-cell *matHeaderCellDef>
            {{ 'entrances.page.field.cameras_states' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="camerasStates"
            *matCellDef="let element"
          >
            <mat-chip-list [class.mat-chip-list--place-content]="xlWDownBreakpoint$ | async">
              <ng-container *ngIf="element.camerasStatuses === null; else camerasStates">
                <mat-chip
                  [disabled]="true"
                  [selectable]="false"
                >
                  {{ 'entrances.page.message.no_cameras' | translate }}
                </mat-chip>
              </ng-container>
              <ng-template #camerasStates>
                <mat-chip
                  color="success"
                  [disabled]="true"
                  [selectable]="false"
                  *ngIf="element.camerasStatuses.online > 0"
                >
                  {{ 'entrances.page.enum.state.online' | translate }}: {{ element.camerasStatuses.online }}
                </mat-chip>

                <mat-chip
                  color="error"
                  [disabled]="true"
                  [selectable]="false"
                  *ngIf="element.camerasStatuses.offline > 0"
                >
                  {{ 'entrances.page.enum.state.offline' | translate }}: {{ element.camerasStatuses.offline }}
                </mat-chip>
              </ng-template>
            </mat-chip-list>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell data-label="actions" *matHeaderCellDef></mat-header-cell>
          <mat-cell data-label="actions" *matCellDef="let element">
            <a
              mat-icon-button
              [routerLink]="['/entrances', element.id]"
              [queryParams]="{
                status: currentState,
                query: paginatedPageWrapper.filterInput.value,
                page: paginatedPageWrapper.paginator.pageIndex,
                address: element.address,
                type: 'entrance'
            }">
              <mat-icon>navigate_next</mat-icon>
            </a>
          </mat-cell>
        </ng-container>

        <mat-header-row
          [style.display]="(xlWDownBreakpoint$ | async) ? 'none' : 'flex'"
          *matHeaderRowDef="displayedColumns"
        >
          {{ 'entrances.page.field.actions' | translate }}
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <ng-template #emptyData>
      <app-empty-data></app-empty-data>
    </ng-template>

    <ngx-skeleton-loader
      *ngIf="entrancesLoading$ | async"
      count="10"
      [theme]="{
        'border-radius': '8px',
        'margin-top': (xlWDownBreakpoint$ | async) ? '0px' : '10px',
        height: (xlWDownBreakpoint$ | async) ? '225px' : '81px'
      }"
    ></ngx-skeleton-loader>
  </ng-container>
</app-paginated-page-wrapper>
