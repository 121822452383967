import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ServicesModuleState, ServicesRootState } from './services.state';

const servicesFeatureSelector = createFeatureSelector<ServicesRootState, ServicesModuleState>('services');

const selectServiceId = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.general.service.id
);

const selectDependantServicesExtended = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.general.dependantServicesExtended
);

const selectDependantServices = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.general.dependantServices
);

const selectServiceName = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.general.service.name
);

const selectServiceTariff = createSelector(
  servicesFeatureSelector,
  (state: ServicesModuleState) => {
    return state.general.service.tariff;
  }
);

const selectServiceCustomName = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.general.service.customName
);

const selectServiceType = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.general.service.type
);

const selectEntrances = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.settings.entrances
);

const selectRdas = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.settings.rdas
);

const selectCameras = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.settings.cameras
);

const selectKeys = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.settings.keys
);

const selectFlats = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.abonents.flats
);

const selectFilteredFlats = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.abonents.filteredFlats
);

const selectAbonentsLoading = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.abonents.loading
);

const selectFlatsFilters = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.abonents.filters
);

const selectSelectedLogsSource = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.activity.logs.source
);

const selectLogsData = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.activity.logs.data
);

const selectLogsMode = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.activity.logs.extendedMode
);

const selectTimeRange = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.activity.logs.timeRange
);

const selectActiveHistoryCameras = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.activity.activeHistoryCameras
);

const selectActiveHistoryIntercoms = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.activity.activeHistoryIntercoms
);

const selectPbxOnRda = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.settings.pbxOnRda
);

const selectSources = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.activity.logs.sources
);

const selectTotalLogsCount = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.activity.logs.totalCount
);

const selectCurrentLogsCount = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.activity.logs.currentCount
);

const selectKeysLocations = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.general.keysLocations
);

const selectCamerasLocations = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.general.camerasLocations
);

const selectTranslationTunings = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.settings.translationTunings
);

const selectIntercomTypes = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.general.intercomTypes
);

const selectAbonentsBillingEnabled = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.general.company?.abonentsBillingEnabled
);

const selectComponentsVersions = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.general.componentsVersions
);

const selectCompany = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.general.company
);

const selectSupportChatEnabled= createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.general.company?.supportChatEnabled
);

const selectLogsComponentType = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.activity.logs.componentType
);

const selectBlocksCount = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.activity.logs.blocksCount
);

const selectLogsLoading = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.activity.logs.loading
);

const selectPageMode = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.general.pageMode
);

const selectFillEmptyFlats = createSelector(
    servicesFeatureSelector,
    (state: ServicesModuleState) => state.abonents.fillEmptyFlats
);

const selectActiveOnvif = createSelector(
  servicesFeatureSelector,
  (state: ServicesModuleState) => state.onvif.isActive
);

export const servicesSelectors = {
    selectEntrances,
    selectServiceId,
    selectDependantServices,
    selectDependantServicesExtended,
    selectServiceName,
    selectServiceTariff,
    selectServiceCustomName,
    selectServiceType,
    selectRdas,
    selectCameras,
    selectKeys,
    selectFlats,
    selectFilteredFlats,
    selectFlatsFilters,
    selectAbonentsLoading,
    selectAbonentsBillingEnabled,
    selectSupportChatEnabled,
    selectSelectedLogsSource,
    selectLogsData,
    selectLogsMode,
    selectTimeRange,
    selectActiveHistoryCameras,
    selectActiveHistoryIntercoms,
    selectPbxOnRda,
    selectSources,
    selectTotalLogsCount,
    selectCurrentLogsCount,
    selectKeysLocations,
    selectCamerasLocations,
    selectTranslationTunings,
    selectIntercomTypes,
    selectComponentsVersions,
    selectCompany,
    selectLogsComponentType,
    selectBlocksCount,
    selectLogsLoading,
    selectPageMode,
    selectFillEmptyFlats,
    selectActiveOnvif
};
