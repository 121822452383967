<div class="filters-block" [ngClass]="{'reset-filters-block': deviceEventService.appSizeState === AppSizeStates.PHONE}">
  <form
    [formGroup]="form"
    class="filters-block__form"
  >
    <ng-container [ngSwitch]="filtersBlockType">
      <ng-container *ngSwitchCase="FiltersBlockType.SUPPORT">
        <ng-container *ngIf="deviceEventService.appSizeState === AppSizeStates.PHONE then mobile else other"></ng-container>
        <ng-template #mobile>
          <div class="filters-block__form-mobile">
            <div>
              <p-calendar
                [showIcon]="true"
                placeholder="Дата создания"
                icon="pi pi-calendar"
                [numberOfMonths]="1"
                formControlName="dateOfCreating"
                selectionMode="range"
              ></p-calendar>
            </div>
            <div class="filters-block__form-users">
              <p-multiSelect
                placeholder="Пользователь"
                [options]="employees"
                [optionValue]="'id'"
                [optionLabel]="'login'"
                formControlName="user"
              ></p-multiSelect>
            </div>
            <div class="filters-block__form__checkbox">
              <p-checkbox
                [binary]="true"
                id="isShowOnlyOwnerTasksMobile"
                formControlName="isShowOnlyOwnerTasks"
              ></p-checkbox>
              <label for="isShowOnlyOwnerTasksMobile" (click)="onShowOnlyOwnerTasksClick()">
                Мои заявки
              </label>
            </div>
            <div class="filters-block__form-mobile-reset">
              <app-filter-button
                (click)="onClear()"
                [text]="'Очистить'"
                [icon]="''"
              ></app-filter-button>
            </div>
          </div>
        </ng-template>
        <ng-template #other>
          <div>
            <p-calendar
              [showIcon]="true"
              placeholder="Дата создания"
              icon="pi pi-calendar"
              [numberOfMonths]="1"
              formControlName="dateOfCreating"
              selectionMode="range"
            >
          </p-calendar>
          </div>
          <div class="filters-block__form-users">
            <p-multiSelect
              placeholder="Пользователь"
              [options]="employees"
              [optionValue]="'id'"
              [optionLabel]="'login'"
              formControlName="user"
            ></p-multiSelect>
          </div>
          <div class="filters-block__form__checkbox">
            <p-checkbox
              [binary]="true"
              id="isShowOnlyOwnerTasks"
              formControlName="isShowOnlyOwnerTasks"
            ></p-checkbox>
            <label for="isShowOnlyOwnerTasks" (click)="onShowOnlyOwnerTasksClick()">
              Мои заявки
            </label>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </form>

  <app-filter-button
    *ngIf="deviceEventService.appSizeState !== AppSizeStates.PHONE"
    (click)="onClear()"
    [text]="'Очистить'"
    [icon]="''"
  ></app-filter-button>
</div>
