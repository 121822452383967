import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared';
import { CreateServicePopupModule } from '@app/shared/components';
import { PaginatedPageWrapperModule } from '@app/shared/ui';
import { ServiceActivityModule, ServiceCamerasModule, ServiceCamerasWarningModule, ServiceConfigModule, ServiceEntrancePopupModule, ServiceEntrancesModule, ServiceFlatsModule, ServiceNavbarModule } from '@app/views/services/components';
import { ServicesHelper } from '@app/views/services/services';
import { ServiceFacade } from '@app/views/services/store/services.facade';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { VideoSurveillanceFlatsModule, VideoSurveillanceListPageComponent, VideoSurveillancePageComponent } from './components';
import { VideoSurveillancePageService } from './services';
import { VideoSurveillanceListFacade, VideoSurveillancePageEffects, videoSurveillancePageReducers } from './store';
import {NgxTranslateModule} from '@app/translate/translate.module';
import {DelegationComponent} from '@app/views/services/submodules/shared/delegation/delegation.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        SharedPipesModule,
        ServiceCamerasModule,
        ServiceConfigModule,
        ServiceEntrancesModule,
        ServiceActivityModule,
        VideoSurveillanceFlatsModule,
        ServiceNavbarModule,
        CreateServicePopupModule,
        ServiceCamerasWarningModule,
        PaginatedPageWrapperModule,
        ServiceEntrancePopupModule,
        NgxSkeletonLoaderModule,
        StoreModule.forFeature('video-surveillance', videoSurveillancePageReducers),
        EffectsModule.forFeature([
            VideoSurveillancePageEffects
        ]),
        NgxTranslateModule,
        DelegationComponent,
        SharedModule
    ],
  declarations: [
    VideoSurveillancePageComponent,
    VideoSurveillanceListPageComponent
  ],
  providers: [
    ServiceFacade,
    ServicesHelper,
    VideoSurveillancePageService,
    VideoSurveillanceListFacade
  ]
})
export class VideoModule { }
