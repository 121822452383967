<div class="table-page-container">
  <mat-card>
    <mat-card-content>
      <mat-form-field appearance="outline" class="none-padding">
        <input
          matInput
          placeholder="{{ 'intercom.pages.shield.page.placeholder.search' | translate }}"
          [formControl]="filterInput"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          class="none-padding"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>

      <hr>

      <mat-table [dataSource]="(shields$ | async)?.dataSource"
        *ngIf="(shields$ | async)?.totalCount > 0; else emptyData">
        <ng-container matColumnDef="createdAt">
          <mat-header-cell data-label="createdAt" *matHeaderCellDef>
            {{ 'intercom.pages.shield.page.field.created_at' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="createdAt"
            *matCellDef="let element"
          >
            <p>{{ element.createdAt | date: 'dd.MM.yyyy HH:mm:ss' }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell data-label="number" *matHeaderCellDef>
            {{ 'intercom.pages.shield.page.field.number' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="number"
            *matCellDef="let element"
          >
            {{ element.number }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="shieldUid">
          <mat-header-cell data-label="shieldUid" *matHeaderCellDef>
            {{ 'intercom.pages.shield.page.field.shield_uid' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="shieldUid"
            *matCellDef="let element"
          >
            {{element.uid}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="versionInfoShield">
          <mat-header-cell data-label="versionInfoShield" *matHeaderCellDef>
            {{ 'intercom.pages.shield.page.field.version_info_shield' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="versionInfoShield"
            *matCellDef="let element"
          >
            {{ element?.versionInfoShield }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="versionInfoStm">
          <mat-header-cell data-label="versionInfoStm" *matHeaderCellDef>
            {{ 'intercom.pages.shield.page.field.version_info_stm' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="versionInfoStm"
            *matCellDef="let element"
          >
            {{ element?.versionInfoStm }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="rdaUid">
          <mat-header-cell data-label="rdaUid" *matHeaderCellDef>
            {{ 'intercom.pages.shield.page.field.rda_uid' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="rdaUid"
            *matCellDef="let element"
          >
            {{ element?.rdaUid }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="errorsCount">
          <mat-header-cell data-label="errorsCount" *matHeaderCellDef>
            {{ 'intercom.pages.shield.page.field.errors_count' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="errorsCount"
            *matCellDef="let element"
          >
            {{ element?.errorsCount }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell data-label="actions" *matHeaderCellDef></mat-header-cell>
          <mat-cell data-label="actions" *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
              <mat-icon svgIcon="submenu"></mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <div class="triggered-menu-content">
                <a class="triggered-menu-content__button" mat-button color="primary"
                  [routerLink]="['../shield/' + element.id]"
                  [queryParams]="{ query: filterInput.value, page: paginator.pageIndex, number: element.number }"
                >
                  <mat-icon>extension</mat-icon>
                  {{ 'intercom.pages.shield.page.link.see_tests' | translate }}
                </a>
              </div>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row [style.display]="resolution.isTablet ? 'none' : 'flex'" *matHeaderRowDef="displayedColumns">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <ng-template #emptyData>
        <app-empty-data></app-empty-data>
      </ng-template>

      <mat-paginator [length]="(shields$ | async)?.totalCount" [pageSize]="pageSize" [hidePageSize]="true"
        [showFirstLastButtons]="true">
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
