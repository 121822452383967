import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CameraEditContentService, SnackbarService } from '@app/shared/components';
import { Camera, intercomKeyRecords, IntercomKeyType, KeysResponse, RdaResponse } from '@app/shared/entities/rd';
import { Address, LocationResponse, SelectSearch } from '@app/shared/models';
import { CameraLease } from '@app/shared/entities/rd/camera/services/camera-lease.model';
import { UserRoleType } from '@app/core/models';
import { Constants } from '@app/shared/helpers';
import { DialogWrapperData } from '@app/shared/ui';
import { MatDialog } from '@angular/material/dialog';
import {
  ServiceCameraAlreadyExistsPopupComponent
} from '@app/shared/components/camera/camera-already-exists/service-camera-already-exists-popup/service-camera-already-exists-popup.component';
import { MatDialogRef } from '@angular/material/dialog/dialog-ref';
import { BehaviorSubject, Subject } from 'rxjs';
import { VideoType } from '@app/shared/containers/camera/camera-thumbnail/models/videoType';
import {TranslateService} from '@ngx-translate/core';
import {environment} from 'environments/environment';

@Component({
  selector: 'app-camera-edit-content',
  templateUrl: './camera-edit-content.component.html',
  styleUrls: ['./camera-edit-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CameraEditContentComponent implements OnInit, OnDestroy {
  public readonly environment = environment;
  @Input() camera: Camera | CameraLease;
  @Input() addresses: Address[];
  @Input() locations: LocationResponse[];
  @Input() keys: KeysResponse[] = [];
  @Input() rdas: RdaResponse[];
  @Input() onvif: boolean;
  @Input() locationRequired: boolean;
  @Input() enableOnvif: boolean;
  @Input() set loading(loading: boolean) {
    this._loading = loading;
    this.prepareLoading();
  }
  @Input() set cameraAlreadyExists(camera: Camera | null) {
    if (camera) {
      this.showAlreadyExistsCamera(camera);
    }
  }
  form: UntypedFormGroup = this.initForm();
  rdaSelectSearch: SelectSearch;
  intercomSearchDisabled: boolean;
  videoMode: VideoType;
  @Output() private submitCamera: EventEmitter<{ camera: Camera }> = new EventEmitter();
  @Output() private submitOnvif: EventEmitter<{ camera: Camera }> = new EventEmitter();
  @Output() private close: EventEmitter<void> = new EventEmitter();
  private _loading: boolean;
  private unSubscribe$: Subject<boolean> = new Subject<boolean>();
  private locationsMapped = new BehaviorSubject<SelectSearch[]>([]);
  locationsMapped$ = this.locationsMapped.asObservable();
  selectedLocation: SelectSearch;
  readonly userRoleType: typeof UserRoleType = UserRoleType;
  readonly intercomKeyRecords = intercomKeyRecords;

  constructor(
    private snackbar: SnackbarService,
    private cameraEditPopupService: CameraEditContentService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {
    this.videoMode = VideoType.basic;
  }

  ngOnInit() {
    this.prepareForm();
    this.initSearchSelect();
    this.setLocationsMapped();
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();
  }

  get loading(): boolean {
    return this._loading;
  }

  get isVideoService(): boolean {
    return location.href.includes('video-surveillance');
  }

  isCamera(camera: Camera | CameraLease): camera is Camera {
    return (camera as Camera).audio !== undefined;
  }

  isCameraActive(): boolean {
    return (this.camera as Camera)?.active ?? false;
  }

  setLocationsMapped(): void {
    this.locationsMapped.next(this.locations
      .map(location => {
        return { text: location.name, value: location.id };
      })
    );
  }

  onSubmitOnvif() {
    const {
      user,
      password,
      uri,
      locationId,
      entrance,
      isPrivate,
      onvifPort,
      audio,
      selectedKey
    } = this.form.getRawValue();

    if (isPrivate && !this.rdaSelectSearch) {
      this.snackbar.showMessage(this.translate.instant('shared.camera.edit.content.message.indicate_adapter_for_private_camera'), 'info');
      return;
    }

    if (!this.form.valid) {
      this.snackbar.showMessage(this.translate.instant('shared.camera.edit.content.message.indicate_all_data_about_camera'), 'info');
      return;
    }
    const rdaUid = isPrivate ? this.rdaSelectSearch?.value as string : null;

    this.submitOnvif.emit({
      camera: this.prepareCameraResponse(
        user,
        password,
        uri,
        locationId,
        entrance,
        isPrivate,
        rdaUid,
        onvifPort,
        this.camera?.model,
        this.camera?.macAddress,
        (this.camera as Camera)?.connectedToRda,
        audio,
        selectedKey
      )
    });

    return false;
  }

  onSubmit() {
    const {
      user,
      password,
      uri,
      locationId,
      entrance,
      isPrivate,
      onvifPort,
      audio,
      selectedKey
    } = this.form.getRawValue();

    if (isPrivate && !this.rdaSelectSearch) {
      this.snackbar.showMessage(this.translate.instant('shared.camera.edit.content.message.indicate_adapter_for_private_camera'), 'info');
      return;
    }

    if (!this.form.valid) {
      this.snackbar.showMessage(this.translate.instant('shared.camera.edit.content.message.indicate_all_data_about_camera'), 'info');
      return;
    }

    let rdaUid: string = null;

    if (isPrivate) {
      rdaUid = this.rdaSelectSearch?.value as string;
    }

    this.submitCamera.emit({
      camera: this.prepareCameraResponse(
        user,
        password,
        uri,
        locationId,
        entrance,
        isPrivate,
        rdaUid,
        onvifPort,
        this.camera?.model,
        this.camera?.macAddress,
        (this.camera as Camera)?.connectedToRda,
        audio,
        selectedKey
      )
    });
  }

  onSelectAddress(entranceId: number) {
    this.form.get('entrance').setValue(entranceId);
  }

  onLocationSelected(selectItem: SelectSearch) {
    this.form.get('locationId')?.setValue(selectItem.value);
  }

  onChangePrivateState() {
    this.intercomSearchDisabled = !this.form.get('isPrivate').value;

    if (this.rdaSelectSearch) {
      return;
    }

    this.rdaSelectSearch = null;
  }

  onRdaSelected(rdaSelectSearch: SelectSearch) {
    this.rdaSelectSearch = rdaSelectSearch;
  }

  private initForm(): UntypedFormGroup {
    return new UntypedFormGroup(
      {
        entrance: new UntypedFormControl(null, Validators.required),
        uri: new UntypedFormControl(null, Validators.required),
        user: new UntypedFormControl(null),
        password: new UntypedFormControl(null),
        locationId: new UntypedFormControl(null),
        isPrivate: new UntypedFormControl(null),
        onvifPort: new UntypedFormControl(null),
        audio: new UntypedFormControl(false),
        selectedKey: new UntypedFormControl(null)
      },
      {
        validators: this.cameraEditPopupService.uriValid.bind(this.cameraEditPopupService)
      }
    );
  }

  private prepareForm(): void {
    if (this.camera) {
      if ((this.camera as Camera).address) {
        this.form.get('entrance').setValue((this.camera as Camera).address.entrance.id);
        this.form.get('entrance').disable();
      } else {
        this.form.get('entrance').setValue(this.addresses[0].entrance.id);
        this.form.get('entrance').enable();
      }
      this.form.get('uri').setValue(this.camera.uri);
      this.form.get('user').setValue(this.camera.user);
      this.form.get('password').setValue(this.camera.password);
      this.form.get('locationId').setValue((this.camera as Camera).location?.id);
      this.form.get('onvifPort').setValue(this.camera.onvifPort);
      this.enableOnvif = false;

      this.selectedLocation = {
        text: (this.camera as Camera).location?.name,
        value: (this.camera as Camera).location?.id
      };

      if (this.cameraEditPopupService.privateCamera(this.camera as Camera)) {
        this.form.get('isPrivate').setValue(true);
      }
      if ((this.camera as Camera).configuration) {
        this.form.get('audio').setValue((this.camera as Camera)?.audio);
      }
      if ((this.camera as Camera).deviceId) {
        this.form.get('selectedKey').setValue(this.keys.find((key) => key.id === (this.camera as Camera).deviceId));
      }
      this.enableOnvif = true;
    } else {
      this.enableOnvif = false;
      this.form.get('entrance').setValue(this.addresses[0].entrance.id);
      this.form.get('uri').setValue('rtsp://');
    }

    if (this.locationRequired) {
      this.form.get('locationId').setValidators([Validators.required]);
    } else {
      this.form.get('locationId').clearValidators();
    }
  }

  private initSearchSelect(): void {
    let rdaUid: string;

    if (this.cameraEditPopupService.privateCamera(this.camera as Camera)) {
      rdaUid = (this.camera as Camera).rdaUid;
    } else {
      this.intercomSearchDisabled = true;

      if (!this.rdas) {
        return;
      }

      for (const rda of this.rdas) {
        if (this.cameraEditPopupService.notIpType(rda)) {
          rdaUid = rda?.uid;
          break;
        }
      }
    }

    if (rdaUid) {
      this.rdaSelectSearch = { text: rdaUid, value: rdaUid };
    }
  }

  private prepareCameraResponse(
    user: string,
    password: string,
    uri: string,
    locationId: number,
    entrance: number,
    isPrivate: boolean,
    rdaUid: string,
    onvifPort: number,
    model: any,
    macAddress: string,
    connectedToRda: boolean,
    audio: boolean,
    selectedKey: KeysResponse
  ): Camera {
    let addressForUpdate: Address = null;
    const selectedAddress: Address = this.addresses.find(address => address.entrance.id === entrance);

    if (selectedAddress) {
      addressForUpdate = JSON.parse(JSON.stringify(selectedAddress));
      delete addressForUpdate.entrance.additionalFlatRanges;
    }

    const camera: Camera = this.camera ? JSON.parse(JSON.stringify(this.camera)) : new Camera();
    camera.address = addressForUpdate;
    camera.user = user;
    camera.password = password;
    camera.uri = uri;
    camera.rdaUid = rdaUid;
    camera.private = isPrivate;
    camera.onvifPort = onvifPort;
    camera.audio = audio;
    camera.deviceId = selectedKey?.id;

    if (locationId !== undefined) {
      camera.location = locationId ? {
        id: locationId,
        name: this.locations.find(location => location.id === locationId)?.name
      } : null;
    }

    return camera;
  }

  private prepareLoading() {
    if (this.loading) {
      this.form.disable();
    } else {
      this.form.enable();

      if (this.camera) {
        this.form.get('entrance').disable();
        this.enableOnvif = true;
      } else {
        this.enableOnvif = false;
      }
    }
  }

  private showAlreadyExistsCamera(camera: Camera) {
    let dialogRef: MatDialogRef<
      ServiceCameraAlreadyExistsPopupComponent,
      DialogWrapperData<Camera, void>
      > = null;

    const dialogWizard: DialogWrapperData<Camera, Camera> = {
      title: this.translate.instant('shared.camera.edit.content.show_already_exists.title'),
      componentName: 'ServiceCameraAlreadyExistsPopupComponent',
      body: camera,
      submit: (e: Camera) => {
        dialogRef.close();
        this.close.emit();
      },
    };

    dialogRef = this.dialog.open(ServiceCameraAlreadyExistsPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data: dialogWizard
    });
  }
}
