import { LocalStorageCompanyKey, LocalStorageHelper } from "@app/shared/entities";

export class WebRTCExperimentHelpers {
  static isCompanyInExperiment(): boolean {
    const currentCompanyId = LocalStorageHelper.getItem(LocalStorageCompanyKey.ID);
    const circleCompanyWithExperiment = ['3', '63', '7'];
    const companyIdWithExperimentInStorage = LocalStorageHelper.getItem(LocalStorageCompanyKey.COMPANY_ID_WITH_EXPERIMENT_WEBRTC);

    if(companyIdWithExperimentInStorage){
      return circleCompanyWithExperiment.includes(currentCompanyId) || companyIdWithExperimentInStorage === currentCompanyId
    }

    return circleCompanyWithExperiment.includes(LocalStorageHelper.getItem(LocalStorageCompanyKey.ID));
  }
}