import { NgModule } from "@angular/core";
import { RdVideoControlComponent } from "./control/control";
import { RdVideoComponent } from "./video/video.component";
import { CommonModule } from '@angular/common';
import { NgxTranslateModule } from '@app/translate/translate.module';
import { RdVideoPlayControlComponent } from "./play-control/play-control";
import { RdVideoTimelineComponent } from "./timeline/timeline";
import { FormsModule } from "@angular/forms";
import { RdVideoTimelineSliderComponent } from "./timeline-slider/timeline-slider";
import { RdVideoTimelineTooltipComponent } from "./timeline-tooltip/timeline-tooltip";
import { RdVideoSelectControlComponent } from "./select-control/select-control";
import { PaginatorModule } from "primeng/paginator";
import { RdVideoTimelineCanvasComponent } from "./timeline-canvas/timeline-canvas";
import { RdVideoEventControlComponent } from "./timeline-event-control/timeline-event-control";

@NgModule({
  imports: [CommonModule, NgxTranslateModule, FormsModule, PaginatorModule ],
  declarations: [
    RdVideoEventControlComponent,
    RdVideoTimelineCanvasComponent,
    RdVideoTimelineTooltipComponent,
    RdVideoTimelineSliderComponent,
    RdVideoSelectControlComponent,
    RdVideoTimelineComponent,
    RdVideoPlayControlComponent,
    RdVideoComponent,
    RdVideoControlComponent
  ],
  exports: [
    RdVideoEventControlComponent,
    RdVideoSelectControlComponent,
    RdVideoTimelineComponent,
    RdVideoPlayControlComponent,
    RdVideoComponent,
    RdVideoControlComponent
  ]
})
export class RdVideoModule { }
