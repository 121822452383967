import { AfterContentInit, Component, ContentChild, ElementRef, Input } from "@angular/core";
import { RdVideoService } from "../../service/video.service";
import { DecorateUntilDestroy, takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { PlayerService } from "../../service/player.service";
import { filter } from "rxjs/operators";
import { PlayerStateEnum, StateType } from "../../models/state.model";

@DecorateUntilDestroy()
@Component({
  selector: 'rd-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class RdVideoComponent implements AfterContentInit {
  @Input() streamId: number;
  @ContentChild('video') videoElementRef: ElementRef<HTMLVideoElement>;

  constructor(private rdVideoService: RdVideoService, private playerService: PlayerService) { }

  ngAfterContentInit(): void {
    this.videoElementRef.nativeElement.playsInline = true;
    this.videoElementRef.nativeElement.autoplay = true;

    this.rdVideoService.mediaStreamList$
      .pipe(takeUntilDestroyed(this))
      .subscribe(list => {
        list.forEach(streamInfo => {
          if (this.streamId === streamInfo.id) {
            streamInfo.connectMedia(this.videoElementRef.nativeElement);
          }
        })
      })

    this.playerService.selectedPlayerTime$
      .pipe(
        filter(time => time !== null),
        takeUntilDestroyed(this)
      )
      .subscribe(time => {
        this.videoElementRef.nativeElement.currentTime = Math.ceil(time);
      })

    this.playerService.stateSubject$
      .pipe(
        filter((state) => state.type === 'UpdatePlayerState'),
        takeUntilDestroyed(this)
      )
      .subscribe(state => {
        switch (state.value) {
          case PlayerStateEnum.PLAY:
            this.videoElementRef.nativeElement.play();
            break;
          case PlayerStateEnum.PAUSE:
            this.videoElementRef.nativeElement.pause();
            break;
        }
      })
    
    this.videoElementRef.nativeElement.addEventListener('timeupdate', this.onTimeUpdate.bind(this));
    this.videoElementRef.nativeElement.addEventListener('play', this.onPlayUpdate.bind(this));
    this.videoElementRef.nativeElement.addEventListener('pause', this.onPauseUpdate.bind(this));
  }

  onTimeUpdate() {
    const currentTime = this.videoElementRef.nativeElement.currentTime;
    this.playerService.updateCurrentTimePlayer(currentTime);
  }

  onPlayUpdate() {
    this.playerService.updateState({
      type: StateType.PlayerState,
      value: PlayerStateEnum.PLAY
    })
  }

  onPauseUpdate() {
    this.playerService.updateState({
      type: StateType.PlayerState,
      value: PlayerStateEnum.PAUSE
    })
  }
}
