<mat-card class="service-entrances card-container">
  <mat-card-header class="header">
    <div class="title-block">
      <mat-card-title>{{ 'service.entrances.title' | translate }}</mat-card-title>
    </div>

    <button
      (click)="onAdd()"
      mat-raised-button
      color="primary-inverse"
      matTooltip="{{ 'service.entrances.tooltip.address.connect' | translate }}"
      [matTooltipPosition]="'before'"
      [disabled]="entrances?.length >= maxCount"
      *ngIf="entrances?.length > 0; else firstAction"
    >
      <mat-icon>add</mat-icon>
    </button>

    <ng-template #firstAction>
      <button
        (click)="onAdd()"
        mat-raised-button
        color="primary-inverse"
        matTooltip="{{ 'service.entrances.tooltip.address.connect' | translate }}"
        [matTooltipPosition]="'before'"
        [disabled]="entrances?.length >= maxCount"
      >
        <mat-icon>add</mat-icon>
      </button>
    </ng-template>
  </mat-card-header>

  <hr>

  <mat-card-content [ngClass]="{ 'mat-card-empty-content': !entrances?.length }">
    <mat-table
      [dataSource]="entrances"
      *ngIf="entrances?.length > 0; else emptyEntrance"
    >
      <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef data-label="address">
          {{ 'service.entrances.field.address' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="address"
          *matCellDef="let element"
        >
          <span>{{ element | address }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="prefix">
        <mat-header-cell *matHeaderCellDef data-label="prefix">
          {{ 'service.entrances.field.prefix' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="prefix"
          *matCellDef="let element"
        >
          <span>{{ element.entrance.prefix }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="range">
        <mat-header-cell *matHeaderCellDef data-label="range">
          {{ 'service.entrances.field.range' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="range"
          *matCellDef="let element"
        >
          <span>{{ element.entrance.flatStart }} - {{ element.entrance.flatEnd }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="additionalRanges">
        <mat-header-cell *matHeaderCellDef data-label="additionalRanges">
          {{ 'service.entrances.field.additional_range' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="additionalRanges"
          *matCellDef="let element"
        >
          <span>{{ element.entrance.additionalFlatRanges | arrayMap: '{0} - {1}' }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef data-label="actions"></mat-header-cell>
        <mat-cell data-label="actions" *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="{{ 'service.entrances.tooltip.address.edit' | translate }}"
            [matTooltipPosition]="'before'"
            (click)="onEdit(element)"
          >
            <mat-icon aria-label="Edit">edit</mat-icon>
          </button>

          <button
            *ngIf="entrances?.length > 1"
            mat-icon-button
            color="error"
            matTooltip="{{ 'service.entrances.tooltip.address.delete' | translate }}"
            [matTooltipPosition]="'before'"
            (click)="onDelete(element.entrance.id)"
          >
            <mat-icon aria-label="Delete">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns"
        [style.display]="(xlWDownBreakpoint$ | async)  ? 'none' : 'flex'"
      >
      </mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <ng-template #emptyEntrance>
      <app-info-block>
        <ng-container info-block-text>
          {{ 'service.entrances.message.your_service_will_be_available_here' | translate }}
        </ng-container>
      </app-info-block>
    </ng-template>
  </mat-card-content>
</mat-card>
