import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Converter } from '../../helpers/converter';
import { PlayerService } from '../../service/player.service';

@Component({
  selector: 'rd-video-timeline-tooltip',
  templateUrl: './timeline-tooltip.html',
  styleUrls: ['./timeline-tooltip.scss']
})
export class RdVideoTimelineTooltipComponent {
  @Input() timelineElementRef: HTMLDivElement;

  value$ = this.playerService.tooltipValue$;
  isHidden$ = this.playerService.tooltipIsHidden$;
  position$ = this.playerService.tooltipPosition$
    .pipe(
      filter(()=> !!this.tooltipElementRef),
      map(value => {
        const tooltipRect = this.tooltipElementRef.nativeElement.getBoundingClientRect();
        const width = tooltipRect.width
        const totalWidth = this.timelineElementRef.getBoundingClientRect().width;
        const percentage = Converter.pixelToPercentage(width, totalWidth);

        return value - (percentage / 2);
      })
    )

  @ViewChild('tooltip') tooltipElementRef: ElementRef<HTMLDivElement>;

  constructor(private playerService: PlayerService) { }
}