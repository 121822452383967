import {Injectable} from '@angular/core';
import {cloneDeep, isEmpty, sortBy} from 'lodash';
import {Camera, CameraApiService} from '@app/shared/entities/rd';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {VideoManagerN} from '@app/views/services/submodules/video-manager/models/view-manager';
import {SnackbarService} from '@app/shared/components';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class VideoManagerPageDataService {
  public loaded = new BehaviorSubject<boolean>(false);
  public loadCamerasVar = false;
  public destroy = new Subject<void>();

  private screens: VideoManagerN.Screen[] = [];
  private cameras: Camera[] = [];
  constructor(
    private cameraApiService: CameraApiService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
  ) {
    // this.loadCameras(); // TODO: it's really slow. Need to optimize
    this.loadScreens();
    this.loaded.next(true);
  }

  public getCameras(ids: number[] = [], invert?: boolean): Camera[] {
    if (invert) {
      if (!isEmpty(ids)) {
        return this.cameras.filter((camera) => !ids.includes(camera?.id));
      }
      return [];
    }
    if (!isEmpty(ids)) {
      return this.cameras.filter((camera) => ids.includes(camera?.id));
    }
    return this.cameras;
  }

  public getScreens(): VideoManagerN.Screen[] {
    this.loadScreens();
    return cloneDeep(this.screens);
  }

  public getScreenById(id: number): VideoManagerN.Screen {
    this.loadScreens();
    return this.screens.find((item) => item?.id === id);
  }

  public createScreen(screen: VideoManagerN.Screen): void {
    screen.cameras = screen.cameras
      .map(value => {
        if(!value.camera){
          return value
        }
        value.camera = {
          id: value.camera.id,
          configuration: value.camera.configuration,
          rdva: value.camera.rdva
        }
        return value;
      })

    this.loadScreens();
    this.screens.push(screen);
    this.saveScreens();
  }

  public editScreen(screen: VideoManagerN.Screen): void {
    this.loadScreens();
    const editedScreen = this.screens.find((item) => item?.id === screen?.id);
    if (editedScreen) {
      this.screens = this.screens.filter((item) => item?.id !== screen?.id);
      
      screen.cameras = screen.cameras.map(value => {
        if(!value.camera){
          return value
        }

        value.camera = {
          id: value.camera.id,
          configuration: value.camera.configuration,
          rdva: value.camera.rdva
        }
        return value;
      })

      this.screens.push(screen);
    }
    this.saveScreens();
  }

  public removeScreen(id: number): void {
    this.loadScreens();
    this.screens = this.screens.filter((item) => item?.id !== id);
    this.saveScreens();
  }

  private loadCameras(): void {
    this.loadCamerasVar = true;
    this.cameraApiService
      .getCamerasList(null, null, null, null, null)
      .pipe(
        takeUntil(this.destroy),
        catchError((error) => {
          this.snackbarService.showMessage(this.translate.instant('video.manager.common.could_not_load_cameras', 'error'));
          return of([]);
        })
      )
      .subscribe((response) => {
        this.loadCamerasVar = true;
        this.cameras = response;
        this.loadScreens();
        this.loaded.next(true);
      });
  }

  private loadScreens(): void {
    const data = localStorage.getItem(VideoManagerN.lsScreensKey);
    if (data) {
      const parsed: VideoManagerN.Screen[] = JSON.parse(data);
      parsed.forEach((item) => {
        if (!isEmpty(item?.cameras)) {
          item.cameras.forEach((camera) => {
            if (camera?.cameraId && (!camera?.camera || camera?.cameraId !== camera?.camera?.id)) {
              camera.camera = this.cameras.find((c) => c?.id === camera?.cameraId);
            }
          });
          item.cameras = sortBy(item.cameras, 'index');
        }
      });
      this.screens = !isEmpty(parsed) ? cloneDeep(parsed) : [];
      return;
    }
    this.screens = [];
  }

  private saveScreens(): void {
    localStorage.setItem(VideoManagerN.lsScreensKey, JSON.stringify(this.screens));
  }
}
