import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared';
import {
  CompanyReportPopupModule,
  CreateServicePopupModule,
  LicenseeReportPopupModule,
  PenetrationReportPopupModule,
  SignUpsReportPopupModule
} from '@app/shared/components';
import { SharedDirectivesModule } from '@app/shared/directives';
import {
  ComponentLoaderModule,
  CountsCardModule,
  SquaredButtonModule
} from '@app/shared/ui';
import { ReducerName } from '@app/store';
import {
  DashboardBlockedUserComponent,
  DashboardPageComponent,
  DashboardRdosImagesComponent,
  DashboardReportsComponent
} from '@app/views/dashboard/components';
import { DashboardHelperService } from '@app/views/dashboard/services';
import {
  DashboardEffects,
  DashboardFacade,
  dashboardReducers
} from '@app/views/dashboard/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {NgxTranslateModule} from '@app/translate/translate.module';
import { SharedModule } from '@app/shared/shared.module';

const circleProgressModuleConfig = {
  unitsFontSize: '20',
  unitsFontWeight: '500',
  titleFontSize: '20',
  titleFontWeight: '500',
  subtitleFontSize: '15',
  subtitleFontWeight: '200',
  subtitleColor: '#7d7d7d',
  animation: true,
  innerStrokeColor: '#edf2f5',
  outerStrokeColor: '#33c51b',
  animationDuration: 300,
  outerStrokeWidth: 10,
  innerStrokeWidth: 5,
  maxPercent: 100,
  renderOnClick: false,
  radius: 60
};

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        SharedPipesModule,
        SharedDirectivesModule,
        ComponentLoaderModule,
        CompanyReportPopupModule,
        LicenseeReportPopupModule,
        PenetrationReportPopupModule,
        CountsCardModule,
        CreateServicePopupModule,
        SignUpsReportPopupModule,
        SquaredButtonModule,
        StoreModule.forFeature(ReducerName.DASHBOARD, dashboardReducers),
        EffectsModule.forFeature([DashboardEffects]),
        NgCircleProgressModule.forRoot(circleProgressModuleConfig),
        SharedModule,
        NgxTranslateModule
    ],
  declarations: [
    DashboardPageComponent,
    DashboardReportsComponent,
    DashboardRdosImagesComponent,
    DashboardBlockedUserComponent
  ],
  providers: [DashboardHelperService, DashboardFacade]
})
export class DashboardModule {
  constructor(@Optional() @SkipSelf() parentModule: DashboardModule) {
    if (parentModule) {
      throw new Error(
        `${parentModule} has already been loaded. Import CoreModule module in the AppModule only.`
      );
    }
  }
}
