<div class="plyr__captions">
  <button 
    class="plyr__controls-events-button plyr__controls-events-button--left"
    (mousedown)="clickPrev()"
  >
    <img class="plyr__controls-events-button__img plyr__controls-events-button__img--left" src="assets/icons/ic-video-event-previous.svg">
    {{ 'shared.video_player.plyr.template.button.prev' | translate }}
    <br/>
    {{ 'shared.video_player.plyr.template.button.event' | translate }}
  </button>
  <button 
    class="plyr__controls-events-button plyr__controls-events-button--right"
    (mousedown)="clickNext()"
  >
    {{ 'shared.video_player.plyr.template.button.next' | translate }}
    <br/>
    {{ 'shared.video_player.plyr.template.button.event' | translate }}
    <img class="plyr__controls-events-button__img plyr__controls-events-button__img--right" src="assets/icons/ic-video-event-previous.svg">
  </button>
</div>