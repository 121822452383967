import { NgModule } from '@angular/core';
import { SharedPipesModule } from '@app/shared';
import { PhoneInputModule } from '@app/shared/components/phone-input';
import { SharedDirectivesModule } from '@app/shared/directives';
import { SharedModule } from '@app/shared/shared.module';
import { CompanyRoutingModule } from './company-routing.module';
import { CompanyRegisterFormComponent, CompanyRegisterPageComponent, CompanySignUpRequestFormComponent, CompanySignUpRequestPageComponent } from './pages';
import { CompanyRolesModule } from '@app/views/company/pages/company-roles-page/company-roles.module';
import { NgxTranslateModule } from '@app/translate/translate.module';
import { ComponentLoaderModule } from '@app/shared/ui';


const components = [
    CompanySignUpRequestPageComponent,
    CompanySignUpRequestFormComponent,
    CompanyRegisterFormComponent,
    CompanyRegisterPageComponent,
];

@NgModule({
    imports: [
      SharedModule,
      SharedPipesModule,
      SharedDirectivesModule,
      PhoneInputModule,
      CompanyRoutingModule,
      CompanyRolesModule,
      ComponentLoaderModule,
      NgxTranslateModule
    ],
    declarations: components
})
export class CompanyModule { }
