import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { TZ_OFFSET_IN_MILLISECONDS } from '@app/shared/components/video-player';
import { DecorateUntilDestroy, takeUntilDestroyed } from '@app/shared/rxjs/operator/take-until-destroyed';
import { filter } from 'rxjs/operators';
import { Converter } from '../../helpers/converter';
import { MediaPeriodService } from '../../service/media-period.service';
import { PlayerService } from '../../service/player.service';

interface TimelineClickData {
  date: Date;
  mouseX: number;
}
@DecorateUntilDestroy()
@Component({
  selector: 'rd-video-timeline',
  templateUrl: './timeline.html',
  styleUrls: ['./timeline.scss']
})
export class RdVideoTimelineComponent implements AfterViewInit {
  @ViewChild('tooltip') tooltipElementRef: ElementRef<HTMLDivElement>;
  @ViewChild('timeline') timelineElementRef: ElementRef<HTMLDivElement>;

  selectedDateTime$ = this.playerService.selectedDateTime$;

  isTimelineEnter: boolean = false;

  constructor(
    private playerService: PlayerService, 
    private mediaPeriodService: MediaPeriodService, 
    private datePipe: DatePipe
  ) { }

  ngAfterViewInit(): void {
    this.playerService.timelineData$
      .pipe(
        filter(value=> !!value),
        takeUntilDestroyed(this)
      )
      .subscribe(timelineData => {
        this.init();
      })
  }

  init(): void {
    this.playerService.sliderPosition$
      .pipe(takeUntilDestroyed(this))
      .subscribe((position) => {
        const timelineData = this.playerService.getTimelineData();

        const { startTime, endTime } = this.mediaPeriodService.getRangeDateTimeline(timelineData.date, timelineData.mediaPeriodItems[0].startTimestamp)
        const timestamp = Converter.percentageToTimestamp(position, startTime.getTime(), endTime.getTime())
        this.playerService.updateSelectedDateTime(timestamp);

        if (this.playerService.getSliderIsDragging()) {
          this.playerService.updateTooltipPosition(position);
          this.playerService.updateTooltipValue(this.formatDate(timestamp, 'HH:mm:ss'));
        }
      })

    this.playerService.sliderIsDragging$
      .pipe(
        filter(value => {
          return !this.isTimelineEnter && !value
        }),
        takeUntilDestroyed(this)
      )
      .subscribe(() => {
        this.playerService.updateTooltipIsHidden(true);
      })

    this.playerService.currentTimePlayer$
      .subscribe(value => {
        const timelineData = this.playerService.getTimelineData();
        
        const { startTime, endTime } = this.mediaPeriodService.getRangeDateTimeline(timelineData.date, timelineData.mediaPeriodItems[0].startTimestamp)
        const timestamp = this.mediaPeriodService.convertTimeToTimestamp(value, timelineData.mediaPeriodItems);
        const positionPercentage = Converter.timestampToPercentage(timestamp - TZ_OFFSET_IN_MILLISECONDS, startTime.getTime(), endTime.getTime());

        if (!this.playerService.getSliderIsDragging()) {
          this.playerService.updateSliderPosition(positionPercentage);
        }
      })
  }

  formatDate(timestamp: number, format: string = 'dd MMMM'): string {
    return this.datePipe.transform(timestamp, format);
  }

  onTimelineMove(event: MouseEvent): void {
    const timelineData = this.playerService.getTimelineData();
    if(!timelineData){
      return;
    }

    const offsetX = this.getMouseOffsetTimeline(event);
    const timelineRect = this.timelineElementRef.nativeElement.getBoundingClientRect();

    const percentage = Converter.pixelToPercentage(offsetX, timelineRect.width)

    this.playerService.updateTimelineMovePosition(offsetX)
    this.playerService.updateTooltipPosition(percentage)
    this.playerService.updateTooltipIsHidden(false);

    const { startTime, endTime } = this.mediaPeriodService.getRangeDateTimeline(timelineData.date, timelineData.mediaPeriodItems[0].startTimestamp);
    const timestamp = Converter.percentageToTimestamp(percentage, startTime.getTime(), endTime.getTime())
    this.playerService.updateTooltipValue(this.formatDate(timestamp, 'HH:mm:ss'));
  }

  onTimelineLeave(): void {
    this.isTimelineEnter = false;
    if (!this.playerService.getSliderIsDragging()) {
      this.playerService.updateTooltipIsHidden(true);
    }
  }

  onTimelineUp(event: MouseEvent): void {
    const offsetX = this.getMouseOffsetTimeline(event);
    const timelineRect = this.timelineElementRef.nativeElement.getBoundingClientRect();
    const percentage = Converter.pixelToPercentage(offsetX, timelineRect.width);

    this.updateSliderPositionAndSelectedPlayerTime(percentage);
  }

  onTimelineEnter(): void {
    this.isTimelineEnter = true;
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    const sliderPosition = this.playerService.getSliderPosition()
    switch (event.key) {
      case 'ArrowRight':
        this.updateSliderPositionAndSelectedPlayerTime(sliderPosition + 0.01)
        break;
      case 'ArrowLeft':
        this.updateSliderPositionAndSelectedPlayerTime(sliderPosition - 0.01)
        break;
    }
  }

  private getMouseOffsetTimeline(event: MouseEvent): number {
    const timelineRect = this.timelineElementRef.nativeElement.getBoundingClientRect();
    return event.clientX - timelineRect.left;
  }

  updateSliderPositionAndSelectedPlayerTime(percentage: number): void {
    const timelineData = this.playerService.getTimelineData();
    
    const { startTime, endTime } = this.mediaPeriodService.getRangeDateTimeline(timelineData.date, timelineData.mediaPeriodItems[0].startTimestamp)
    const timestamp = Converter.percentageToTimestamp(percentage, startTime.getTime(), endTime.getTime());
    const second = this.mediaPeriodService.calculateSecondForPlayer(timestamp, timelineData.mediaPeriodItems);

    if(this.mediaPeriodService.isTimestampInMediaPeriods(timestamp, timelineData.mediaPeriodItems)){
      this.playerService.updateSliderPosition(percentage);
    }

    this.playerService.updateSelectedPlayerTime(second);
  }
}
