<mat-card class="card-container service-config">
  <mat-card-header class="header">
    <div class="title-block">
      <mat-card-title>{{ 'service.config.title' | translate }}</mat-card-title>
    </div>
  </mat-card-header>

  <hr>

  <mat-card-content>
    <h3 *ngIf="mdWDownBreakpoint$ | async">
      {{ 'service.config.subtitle' | translate:{ name: customName ?? name } }}
    </h3>

    <app-info-table *ngIf="company" maxWidth="initial">
      <app-info-table-record *ngIf="company.abonentsBillingEnabled">
        <div title class="header-auto-connection">
          <span>Абонент подключает сам</span>
          <span class="header-auto-connection__text">
            Через приложение подключаются только доп. услуги, после одобрения и подключения основной
          </span>
        </div>
        <div value>
          <p-skeleton *ngIf="autoconnectionLoading" width="5rem"></p-skeleton>
          <div class="header-auto-connection">
            <p-inputSwitch *ngIf="!autoconnectionLoading" [formControl]="autoConnection"></p-inputSwitch>
          </div>
        </div>
      </app-info-table-record>

      <app-info-table-record>
        <p title>{{ 'service.config.field.company' | translate }}</p>
        <div value>
          <p>{{ company.name }}</p>
        </div>
      </app-info-table-record>

      <app-info-table-record>
        <p title>{{ 'service.config.field.phone' | translate }}</p>
        <div value>
          <a type="phone" href="tel:{{company.supportPhone}}" class="value">{{ company.supportPhone }}</a>
        </div>
      </app-info-table-record>

      <ng-container *ngIf="company.abonentsBillingEnabled">
        <app-info-table-record >
          <ng-container [title]>
            <ng-container [ngSwitch]="serviceType">
              <ng-container *ngSwitchCase="ServicesTypes.SOFTWARE_INTERCOM">
                <p>{{ 'service.config.field.tariff_for_software_intercom' | translate }}</p>
              </ng-container>
              <ng-container *ngSwitchCase="ServicesTypes.GATE">
                <p>{{ 'service.config.field.tariff_for_gate' | translate }}</p>
              </ng-container>
              <ng-container *ngSwitchCase="ServicesTypes.VIDEO_SURVEILLANCE">
                <p>{{ 'service.config.field.tariff_for_video_surveillance' | translate }}</p>
              </ng-container>
            </ng-container>
          </ng-container>
          <div value>
            <ng-container *ngIf="tariff$ | async as tariff; else notSet">
              <p>
                {{ tariff + ' ' + ('service.config.currency' | translate) }}
              </p>
              <button
                mat-icon-button
                matTooltip="{{ 'service.config.tooltip.edit_tariff' | translate }}"
                [matTooltipPosition]="'after'"
                (click)="onTariffEdit(serviceId, serviceType, tariff)"
              >
                <mat-icon aria-label="Edit">edit</mat-icon>
              </button>
            </ng-container>
            
          </div>
        </app-info-table-record>
  
        <app-info-table-record *ngIf="dependantServiceExtended$ | async as dependantServiceExtended ">
          <p title>{{ getTitleTariff(dependantServiceExtended.type) }}</p>
          <div value>
            <p *ngIf="dependantServiceExtended.tariff; else notSet">
              {{ dependantServiceExtended.tariff + ' ' + ('service.config.currency' | translate) }}
            </p>
            <button
              mat-icon-button
              matTooltip="{{ 'service.config.tooltip.edit_tariff' | translate }}"
              [matTooltipPosition]="'after'"
              (click)="onTariffEdit(dependantServiceExtended.id, dependantServiceExtended.type, dependantServiceExtended.tariff)"
            >
              <mat-icon aria-label="Edit">edit</mat-icon>
            </button>
          </div>
        </app-info-table-record>
      </ng-container>
      
      <ng-template #notSet>
        <p>{{ 'service.config.not_set' | translate }}</p>
      </ng-template>
    </app-info-table>

    <hr *ngIf="showDivider()">

    <div class="service-config-actions">
      <ng-container *appSomeRoleContains="[userRoleType.ROLE_DEMO]">
        <button
          mat-raised-button
          color="error"
          *ngIf="!emergencyHidden"
          [disabled]="emergencyDisabled"
          (click)="onSendEmergencyAlert()"
        >
          {{ 'service.config.button.emergency' | translate }}
        </button>
      </ng-container>

      <button
        mat-raised-button
        color="primary"
        (click)="onOpenServiceWizard()"
        *ngIf="!connectHidden"
        [disabled]="connectDisabled"
      >
        {{ 'service.config.button.connect' | translate }}
      </button>

      <button
        mat-raised-button
        color="primary-inverse"
        (click)="onRename()"
        *ngIf="!renameHidden"
        [disabled]="renameDisabled"
      >
        {{ 'service.config.button.rename' | translate }}
      </button>

      <ng-template *ngIf="!environment.vivotec">
        <button
          mat-raised-button
          color="primary-inverse"
          (click)="onSupport()"
          *ngIf="!supportHidden"
          [disabled]="supportDisabled"
        >
         {{ 'service.config.button.support' | translate }}
        </button>
      </ng-template>

      <button
        mat-raised-button
        color="primary-inverse"
        *appSomeRoleContains="[userRoleType.ROLE_DEMO_DHCP]"
        (click)="onFindCameras()"
        [disabled]="renameDisabled"
      >
       {{ 'service.config.button.find' | translate }}
      </button>
    </div>
  </mat-card-content>
</mat-card>
