<mat-card class="card-container">
  <mat-card-header class="header">
    <div class="title-block">
      <mat-card-title>
        {{ 'entrance.page.flats_card.title' | translate }}
      </mat-card-title>
    </div>
  </mat-card-header>

  <hr>

  <mat-card-content>
    <mat-table *ngIf="flats?.length > 0" [dataSource]="flats">
      <ng-container matColumnDef="number">
        <mat-header-cell data-label="number" *matHeaderCellDef>
          {{ 'entrance.page.flats_card.field.number' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="number"
          *matCellDef="let element"
        >
          {{ element.address.flat }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="softwareIntercomOwner">
        <mat-header-cell *matHeaderCellDef>
          {{ 'entrance.page.flats_card.field.software_intercom_owner' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="softwareIntercomOwner"
          *matCellDef="let element"
        >
          <a
            class="link"
            *ngIf="element.softwareIntercomOwner"
            [routerLink]="['/abonents/subscriber/', element.softwareIntercomOwner.id]"
            [queryParams]="{ phone: element.softwareIntercomOwner.phone.toString() }"
          >
            {{ element.softwareIntercomOwner.phone.toString() | phone }}
          </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="hardwareIntercomOwner">
        <mat-header-cell *matHeaderCellDef>
          {{ 'entrance.page.flats_card.field.hardware_intercom_owner' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="hardwareIntercomOwner"
          *matCellDef="let element"
        >
          <a
            class="link"
            *ngIf="element.hardwareIntercomOwner"
            [routerLink]="['/abonents/subscriber/', element.hardwareIntercomOwner.id]"
            [queryParams]="{ phone: element.hardwareIntercomOwner.phone.toString() }">
            {{ element.hardwareIntercomOwner.phone.toString() }}
          </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtual">
        <mat-header-cell data-label="virtual" *matHeaderCellDef>
          {{ 'entrance.page.flats_card.field.virtual' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="virtual"
          *matCellDef="let element"
        >
          {{ !element.isVirtual | bool }}
        </mat-cell>
      </ng-container>

      <mat-header-row
        [style.display]="(xlWDownBreakpoint$ | async) ? 'none' : 'flex'"
        *matHeaderRowDef="displayedColumns"
      >
      </mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <ngx-skeleton-loader
      *ngIf="loading"
      count="3"
      [theme]="{
        'border-radius': '8px',
        'margin-top': '0px',
        'height': (xlWDownBreakpoint$ | async) ? '169px': '57px'
      }"
    ></ngx-skeleton-loader>

    <ng-container *ngIf="!loading && flats?.length === 0">
      <app-info-block *ngIf="rdaExists; else emptyRda">
        <ng-container info-block-text>
          {{ 'entrance.page.flats_card.message.connect_test_subscriber' | translate }}
        </ng-container>
      </app-info-block>

      <ng-template #emptyRda>
        <app-info-block>
          <ng-container info-block-text>
            {{ 'entrance.page.flats_card.message.subscribers_not_connected' | translate }}
          </ng-container>
        </app-info-block>
      </ng-template>
    </ng-container>

  </mat-card-content>
</mat-card>
